import axios from "axios";
import {useMutation, useQueryClient} from "react-query";

const updateArticle = async (formData: FormData) => {
    const token = localStorage.getItem('token');
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/articles`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

export const useUpdateArticle = () => {
    const queryClient = useQueryClient();
    return useMutation(updateArticle, {
        onSuccess: () => {
            queryClient.invalidateQueries('articles');
        }
    });
};