import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useFetchInfo } from 'hooks/Info/useFetchInfo';
import { useSubmitUserFieldValues } from "hooks/userFields/useSubmitUserFieldValues";
import { useGetAllUserFields } from "hooks/userFields/useGetAllUserFields";
import useAlert from "hooks/useAlert";
import styles from './Register.module.css';
import { useUpdateUser } from "hooks/user/useUpdateUser";
import { RegisterRequest } from "types/RegisterRequest";
import InputMask from 'react-input-mask';
import { useCheckPhone } from "hooks/user/useCheckPhone";
import { validatePhone } from "hooks/user/validatePhone";
import { Puff, Watch } from "react-loader-spinner";
import useFetchUser from "hooks/user/useFetchUserEmail";
import { useAlertContent } from "context/AlertContext";


type Errors = {
  [key in keyof RegisterRequest]?: string;
};



const BuyerRegistration: React.FC = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const roleFromUrl = urlParams.get('role');
  let initialRoleNumber = roleFromUrl !== null ? parseInt(roleFromUrl, 10) : null;
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  // Проверяем, что initialRoleNumber не равно null и не является NaN, иначе устанавливаем значение по умолчанию
  if (initialRoleNumber === null || isNaN(initialRoleNumber)) {
    initialRoleNumber = 1; // Установите подходящее дефолтное значение
  }

  const [urlRoleNumber, setUrlRoleNumber] = useState<number | null>(null);
  const [currentRole, setCurrentRole] = useState<number>(initialRoleNumber); // Используем initialRoleNumber как начальное значение


  // Инициализация urlRoleNumber из URL при монтировании компонента
  useEffect(() => {
    setUrlRoleNumber(initialRoleNumber); // Используем initialRoleNumber для установки urlRoleNumber
  }, []);

  const userId = localStorage.getItem('userId') || '';
  const userEmail = localStorage.getItem("email") ?? '';
  const token = localStorage.getItem("token") ?? '';
  const numericUserId = +userId;
  // console.log("userEmail");
  // console.log(userEmail);

  const { data: user, isLoading, error, refetch: refetchUser } = useFetchUser(userEmail, token);

  console.dir(user?.userFieldValues);

  useEffect(() => {
    // Вызовите refetchUser, если userEmail изменяется
    if (userEmail) {
      refetchUser();
    }
  }, [userEmail, refetchUser]);



  if (isLoading) {
    // Отображение индикатора загрузки
  }

  if (error) {
    // Обработка ошибки
  }

  useEffect(() => {
    if (user) {
      setFormData(formData => ({
        ...formData,
        email: user.email,
        id: user.id,
        role_id: currentRole
      }));
    }
  }, [user, currentRole]);



  const { data: userFields, isLoading: isUserFieldsLoading, isError: isUserFieldsError, error: userFieldsError } = useGetAllUserFields();
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({});

  // console.dir("userFields 5454");
  // console.dir(userFields);


  // const { data: infoData, isLoading: isInfoLoading, isError: isInfoError, error: infoError, refetch } = useFetchInfo();
  // const { alertData, showAlert, triggerAlert, resetAlert } = useAlert();
  // const { mutate: createUserField } = useSubmitUserFieldValues(triggerAlert);

  // const { mutate: updateUser, isLoading: isLoadingUser, isSuccess } = useUpdateUser();

  // Используем хуки
  const updateUserMutation = useUpdateUser();
  // const createUserFieldMutation = useSubmitUserFieldValues(triggerAlert);
  const createUserFieldMutation = useSubmitUserFieldValues();


  // Тип для определения ключей объекта errors
  type ErrorKeys = keyof typeof errors;
  type RegisterRequestData = Omit<RegisterRequest, 'confirmPassword'>;

  // Определите возможные значения, которые может принимать type в UserField
  type FieldType = 'Строка' | 'Число' | 'Дата' | 'email' | 'text';

  const mapFieldTypeToInputType = (fieldType: string): string => {
    const typesMap: Record<string, string> = {
      'Строка': 'text',
      'Число': 'number',
      'Дата': 'date',
      'email': 'email', // Добавляем сюда, если 'email' является одним из возможных значений
      // другие возможные сопоставления
    };

    // Проверяем, есть ли такой ключ в typesMap, иначе возвращаем 'text'
    return typesMap[fieldType] || 'text';
  };


  const [formData, setFormData] = useState<RegisterRequest>({
    id: user?.id ?? 0,
    firstname: '',
    lastname: '',
    patronymic: '',
    email: user?.email || '',
    phone: '',
    password: '',
    confirmPassword: '',
    role_id: currentRole,
    is_active: true,
    is_registered: true
  });

  const [isPhoneFieldBlurred, setIsPhoneFieldBlurred] = useState(false);
  const { data: isPhoneUnique, refetch: checkPhone } = useCheckPhone(formData.phone, isPhoneFieldBlurred);

  // console.dir("useFormData");
  // console.dir(useFormData);

  const [errors, setErrors] = useState<Errors>({});

  // Use useMutation for the registration API call
  const mutation = useMutation((newUser: RegisterRequestData) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, newUser);
  });

  const [phoneValidation, setPhoneValidation] = useState<boolean | null>(null);
  const [phoneValidationT, setPhoneValidationT] = useState<boolean | null>(null);
  const [cnt, setCnt] = useState(0);
  const { triggerAlert } = useAlertContent();


  useEffect(() => {
    // console.log("useEffect validate");
    if (formData.phone !== '') {
      const validate = async () => {
        const isUnique = await validatePhone(formData.phone);
        setPhoneValidation(isUnique);
        setPhoneValidationT(isUnique);
        // console.log(phoneValidation);
      };

      validate();
    }
  }, [formData.phone, cnt, validatePhone]);


  useEffect(() => {
    // console.log("useEffect setErrors");
    setErrors(errors => ({
      ...errors,
      phone: phoneValidation === false ? 'Номер телефона уже используется' : ''
    }));
  }, [phoneValidation]);


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

    const name = e.target.name as keyof RegisterRequest;
    const { value } = e.target;

    setFormData({ ...formData, [name]: value });



    if (name === 'phone') {
      // console.log("value");
      // console.log(value);
      // Сбросить состояние валидации номера телефона при каждом изменении номера
      setCnt(prevCnt => prevCnt + 1);
    }


    // Логирование обновленного состояния
    // console.log("Updated useFormData:", { ...useFormData, [name]: value });
    if (name !== 'phone') {
      if (errors[name]) {
        setErrors({ ...errors, [name]: '' });
        // console.log("Updated errors:", { ...errors, [name]: '' });
      }
    }


    // Вызов validateField для немедленной валидации (если это необходимо)
    // validateField(name, value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.name as ErrorKeys;
    const { value } = event.target;
    let updatedErrors = { ...errors };

    // console.log("Сработало событие handleBlur");
    if (name === 'phone') {
      setPhoneValidation(null);
    }

    // Ваша существующая логика валидации
    if (name === 'email' && !value.includes('@')) {
      updatedErrors[name] = 'Введите действительный email';
    } else {
      updatedErrors[name] = '';
    }
    if (name !== 'phone') {
      setErrors(updatedErrors);
    }

  }



  const handleInputChangeuserFields = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const name = e.target.name as keyof RegisterRequest; // Явное приведение типа
    const { value } = e.target;

    // Обновление useFormData для статических полей
    setFormData({ ...formData, [name]: value });

    // Обновление fieldValues для динамических полей
    // Добавляем проверку на наличие userFields
    if (userFields && userFields.some(field => field.name === name)) {
      setFieldValues(prevValues => ({ ...prevValues, [name]: value }));
    }
  };


  const cleanErrorMessage = (message: string) => {
    // Удаляем все последовательности из двух и более точек вместе с пробелами
    message = message.replace(/(\s*\.\s*)+/g, '. ');
    message = message.replace(/^\s*|\s*$/g, ''); // Удаляем пробелы в начале и конце строки
    return message;
  };


  const createErrorMessage = (errors: { [s: string]: unknown; } | ArrayLike<unknown>) => {
    let errorMessages = Object.values(errors)
      .filter(errorMessage => !!errorMessage && String(errorMessage).trim() !== ''); // Фильтруем непустые и непустые после trim строки
    let errorMessage = errorMessages.join('. ');
    return cleanErrorMessage(errorMessage); // Применяем cleanErrorMessage для очистки сообщения от двойных точек
  };



  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setCnt(prevCnt => prevCnt + 1);

    let newErrors = { ...errors }; // Create a shallow copy of errors to accumulate new errors
    let formIsValid = true; // Flag to track form validation status


    // Validate all fields before submitting
    Object.keys(formData).forEach(key => {
      const value = formData[key as keyof RegisterRequest];
      const error = validateField(key, value); // Call validateField to check for errors
      if (error) {
        formIsValid = false; // If there's an error, set the flag to false
        newErrors = { ...newErrors, [key]: error }; // Add the error to the newErrors object
      }
    });

    setErrors(newErrors); // Update the state with all errors at once

    if (!formIsValid || phoneValidation === false) {
      console.error('Validation failed:', newErrors);
      const errorMessage = createErrorMessage(newErrors);
      triggerAlert(errorMessage, 'danger');
      return;
    }

    if (formIsValid) {
      setIsLoader(true);
      const userToUpdate: RegisterRequest = {
        id: formData.id,
        firstname: formData.firstname,
        lastname: formData.lastname,
        patronymic: formData.patronymic,
        email: formData.email,
        phone: formData.phone,
        password: formData.password,
        confirmPassword: '', // Значение, соответствующее логике вашего приложения
        role_id: formData.role_id,
        is_active: formData.is_active,
        is_registered: formData.is_registered
        // Дополнительные поля, если они есть в RegisterRequest
      };

      // console.log("userToUpdate:", userToUpdate);

      const userId = formData.id;

      // Убедитесь, что userFields определен
      if (userFields) {
        // const fieldData = Object.keys(fieldValues).map(fieldName => {
        //   const field = userFields.find(f => f.name === fieldName);

        //   // Убедитесь, что field_id не равен null
        //   const fieldId = field ? field.id : 0; // Используйте 0 или другое "заглушечное" значение

        //   return {
        //     userId: Number(userId), // Убедитесь, что userId - это число
        //     value: fieldValues[fieldName],
        //     isActive: true,
        //     fieldId: fieldId
        //   };
        // });

        const fieldData = userFields.map(field => {
          // Получаем значение поля, если оно есть, иначе используем пустую строку
          const fieldValue = fieldValues[field.name] ?? "";

          return {
            userId: Number(userId), // Убедитесь, что userId - это число
            value: fieldValue,
            isActive: true,
            fieldId: field.id // Здесь уже не нужна проверка, так как мы используем userFields
          };
        });

        // createUserField(fieldData);

        console.log("fieldData 333333:");
        console.dir(fieldData);
        // Объединяем запросы
        Promise.all([
          updateUserMutation.mutateAsync(userToUpdate),
          createUserFieldMutation.mutateAsync(fieldData)
        ]).then(() => {
          // Оба запроса успешно выполнены
          triggerAlert("Пользователь успешно создан!", "success");
          navigate('/index');

        }).catch((error) => {
          // Обработка ошибки
          console.error('Произошла ошибка:', error);
          // Можно также вызвать triggerAlert для показа ошибки пользователю
        }).finally(() => {
          setIsLoader(false);
        });

      } else {
        // console.log("userFields is undefined");
      }
      // console.dir(fieldValues, { depth: null });
    }


  };


  const validateField = (name: string, value: any): string => {
    // Преобразование любого типа значения в строку для валидации
    let stringValue = String(value);
    let errorMessage = '';

    switch (name) {
      case 'email':
        if (!stringValue) {
          errorMessage = 'Email обязателен для заполнения.';
        } else if (!/\S+@\S+\.\S+/.test(stringValue)) {
          errorMessage = 'Неверный формат email.';
        }
        break;
      case 'password':
        if (!stringValue) {
          errorMessage = 'Требуется пароль.';
        }
        break;
      case 'confirmPassword':
        if (stringValue !== formData.password) {
          errorMessage = 'Пароли не совпадают.';
        }
        break;
      case 'phone': // Добавляем валидацию для поля "phone"
        if (!stringValue) {
          errorMessage = 'Телефон обязателен для заполнения.';
        }
        break;
      // Добавьте здесь другие условия валидации для разных полей
      default:
        break;
    }

    return errorMessage;
  };


  const [showRoleBlock, setShowRoleBlock] = useState(false);

  const changeRoleVBlock = () => {
    setShowRoleBlock(!showRoleBlock);
  };

  const changeRole = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    // console.log("currentRole changeRole 1");
    // console.log(currentRole);
    setUrlRoleNumber(prevRole => (prevRole === 1 ? 2 : 1));
    setCurrentRole(currentRole === 1 ? 2 : 1);
    setShowRoleBlock(!showRoleBlock);
    // console.log("currentRole changeRole 2");
    // console.log(currentRole);

  };



  if (isUserFieldsLoading) {
    return <div>Загрузка...</div>;
  }

  if (isUserFieldsError) {
    return <div>Ошибка: {userFieldsError.message}</div>;
  }


  return (
    <main className="d-flex w-100">
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className={styles['registration-container']}>
                <div className="registration-section">
                  <h1 className="h2">Регистрация {currentRole === 1 ? "покупателя" : "продавца"}</h1>
                  <p className="lead">
                    {currentRole === 1 ? "Начните путь к выгодным покупкам!" : "Расширьте свой бизнес с нами!"}
                  </p>
                </div>
                <div className="button-section" style={{ position: 'relative' }}>
                  <button type="button" className="btn btn-outline-primary btn-sm" onClick={changeRoleVBlock}>
                    <i className="fas fa-exchange-alt me-2"></i>Сменить роль
                  </button>
                  {showRoleBlock && (
                    <div className={`${styles['role-block']}`}>
                      <a href="#" onClick={changeRole} className="link-offset-3">
                        {currentRole === 1 ? "Продавец" : "Покупатель"}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="m-sm-3">
                    <form onSubmit={handleSubmit}>
                      <input type="hidden" name="roleId" value="1" />
                      <div className="mb-3">
                        <label className="form-label">Имя</label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="firstname"
                          placeholder="Введите свое имя"
                          value={formData.firstname}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Фамилия</label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="lastname"
                          placeholder="Введите свою фамилию"
                          value={formData.lastname}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Отчество</label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="patronymic"
                          placeholder="Введите своё отчество"
                          value={formData.patronymic}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Телефон {formData.email}</label>
                        <InputMask
                          mask="+7 (999) 999-99-99"
                          className={`form-control form-control-lg ${errors.phone ? 'is-invalid' : ''}`}
                          type="tel"
                          name="phone"
                          placeholder="Введите свой телефон"
                          value={formData.phone || ''}
                          onChange={handleInputChange}
                        // onBlur={handleBlur}
                        />

                        {errors.phone && <div className="invalid-feedback d-block">{errors.phone}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                          className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                          type="email"
                          name="email"
                          placeholder="Введите свой адрес электронной почты"
                          value={formData.email}
                          onBlur={handleBlur}
                          onChange={handleInputChange}
                        />
                        <div className={`invalid-feedback ${errors.email ? 'd-block' : 'd-none'}`}>
                          {errors.email}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Пароль</label>
                        <input
                          className={`form-control form-control-lg ${errors.password ? 'is-invalid' : ''}`}
                          type="password"
                          name="password"
                          placeholder="Введите пароль"
                          value={formData.password}
                          onChange={handleInputChange}
                        />
                        {errors.password && <div className="invalid-feedback d-block">{errors.password}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Подтвердите пароль</label>
                        <input
                          className={`form-control form-control-lg ${errors.confirmPassword ? 'is-invalid' : ''}`}
                          type="password"
                          name="confirmPassword"
                          placeholder="Подтвердите пароль"
                          value={formData.confirmPassword}
                          onChange={handleInputChange}
                        />
                        {errors.confirmPassword && <div className="invalid-feedback d-block">{errors.confirmPassword}</div>}
                      </div>
                      {/* {(userFields || []).filter(field => field.roleId === urlRoleNumber).map(field => (
                        <div key={field.id} className="mb-3">
                          <label className="form-label">{field.name}</label>
                          {field.name === "ИНН" ? (
                            <InputMask
                              mask="999999999999" // Маска для ИНН
                              className="form-control form-control-lg"
                              type="text"
                              name={field.name}
                              placeholder="Введите ИНН"
                              onChange={handleInputChangeuserFields}
                              onBlur={handleBlur}
                            />
                          ) : (
                            <input
                              className="form-control form-control-lg"
                              type={mapFieldTypeToInputType(field.type)}
                              name={field.name}
                              placeholder={field.name}
                              onChange={handleInputChangeuserFields}
                            />
                          )}
                        </div>
                      ))} */}

                      {(userFields || []).filter(field => field.roleId === urlRoleNumber || field.roleId === 4).map(field => (
                        <div key={field.id} className="mb-3">
                          <label className="form-label">{field.name}</label>
                          {field.id === 2 ? (
                            <select
                              className="form-control form-control-lg"
                              name={field.name}
                              onChange={handleInputChangeuserFields}
                            >
                              <option value="">{`Выберите`}</option>
                              {field.typeValue.split(', ').map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                              ))}
                            </select>
                          ) : field.id === 1 ? (
                            <InputMask
                              mask="999999999999"
                              className="form-control form-control-lg"
                              type="text"
                              name={field.name}
                              placeholder="Введите ИНН"
                              onChange={handleInputChangeuserFields}
                              onBlur={handleBlur}
                            />
                          ) : (
                            <input
                              className="form-control form-control-lg"
                              type={mapFieldTypeToInputType(field.type)}
                              name={field.name}
                              placeholder={field.name}
                              onChange={handleInputChangeuserFields}
                            />
                          )}
                        </div>
                      ))}



                      <div className="d-grid gap-2 mt-3">
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                        >
                          Зарегистрироваться
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>


              <div className="text-center mb-3">
                У вас уже есть учетная запись? <Link to="/login">Авторизоваться</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* Остальная часть вашего компонента */}
        {/* {showAlert && (
          <div className={`custom-alert alert alert-${alertData.type}`}>
            {alertData.message}
          </div>
        )} */}
      </div>
      {isLoader && (
        <div className="loader-spinner">
          <Watch
            visible={true}
            height="80"
            width="80"
            radius="48"
            color="#4fa94d"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
    </main>
  );
};

export default BuyerRegistration;