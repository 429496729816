import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {Filling} from "../../types/Filling";

const updateFilling = async (id: number, filling: Filling, imageFile?: File): Promise<Filling> => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("filling", new Blob([JSON.stringify(filling)], { type: "application/json" }));
    if (imageFile) {
        formData.append("imageFile", imageFile);
    }

    const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/fillings/${id}`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    });
    return data;
};

export const useUpdateFilling = () => {
    const queryClient = useQueryClient();
    return useMutation((params: { id: number; filling: Filling; imageFile?: File }) => updateFilling(params.id, params.filling, params.imageFile), {
        onSuccess: () => {
            queryClient.invalidateQueries("fillings");
        },
    });
};