import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useAlertContent } from 'context/AlertContext';

// Удаляем UseUpdateCategoryProps и параметр triggerAlert
export const useUpdateCategory = () => {
    const token = localStorage.getItem("token");
    const queryClient = useQueryClient();
    const { triggerAlert } = useAlertContent(); // Используем useAlert непосредственно внутри хука
  
    return useMutation(
      ({ formData, categoryId }: { formData: FormData; categoryId: number }) =>
        axios.put(
          `${process.env.REACT_APP_API_URL}/categories/update/${categoryId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        ),
      {
        onSuccess: () => {
          console.log("Категория успешно обновлена");
          queryClient.invalidateQueries("categories");
          
          
          triggerAlert("Категория успешно обновлена!", "success");
        },
        onError: (error) => {
          console.error("Ошибка при обновлении категории", error);
          triggerAlert("Ошибка при обновлении категории", "danger");
        },
      }
    );
  };
  