import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';

// Тип данных для поста
type Post = {
  id: number;
  title: string;
  content: string;
  createdAt: string;
};

const ViewPost: React.FC = () => {
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        
        // Получаем токен из localStorage
        // const token = localStorage.getItem('token');
        const token = "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhZG1taW4yQHlhLnJ1IiwiaWF0IjoxNzAyMjE5NTEwLCJleHAiOjE3MDIyMjA5NTB9.48B-ioYaIIgXuo13O1XPjPqQRD426NF26helMIGed30";
        console.log("token: " + token);
        if (token) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts/1`, {
            headers: {
              Authorization: `Bearer ${token}` // Добавляем токен в заголовок
            }
          });
          setPost(response.data);
          console.log(response.data)
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Теперь TypeScript знает, что error имеет тип AxiosError
          const axiosError = error as AxiosError;
          if (axiosError.response) {
            console.error('Error response:', axiosError.response);
            // ... и т.д.
          } else if (axiosError.request) {
            console.error('Error request:', axiosError.request);
          } else {
            console.error('Error message:', axiosError.message);
          }
        } else if (error instanceof Error) {
          console.error('Error message:', error.message);
        } else {
          console.error('Unexpected error:', error);
        }
      }
      
      
    };

    fetchPost();
  }, []);

  if (!post) {
    return <div>Загрузка...</div>;
  }

  return (
    <div>
      <h1>{post.title}</h1>
      <p>{post.content}</p>
      <small>Опубликовано: {post.createdAt}</small>
    </div>
  );
};

export default ViewPost;