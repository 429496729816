import { useQuery } from 'react-query';
import axios from 'axios';
import { UserField } from 'types/UserFieldTypes';

export const useFetchUserFieldsForUser = () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem('userId') || '';
  
    return useQuery<UserField[], Error>(['userFields', userId], async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/userfields/unfilled/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      return data;
    }, {
      enabled: !!userId  // Запускает запрос только если userId существует
    });
  }