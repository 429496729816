import {useQuery} from "react-query";
import axios from "axios";


const fetchArticles = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/articles`);
    return data;
};

export const useArticles = () => {
    return useQuery('articles', fetchArticles);
};