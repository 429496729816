import React from "react";
import {useFetchFillings} from "../../hooks/fillings/fetchFillings";
import {Filling} from "../../types/Filling";
import Layout from "../Layout/Layout";
import {Link} from "react-router-dom";
import {Watch} from "react-loader-spinner";


const FillingsList: React.FC = () => {
    const {data: fillings, isLoading, error} = useFetchFillings();

    if (isLoading) {
        return <div className="loader-spinner">
            <Watch
                visible={true}
                height="80"
                width="80"
                radius="48"
                color="#4fa94d"
                ariaLabel="watch-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <Layout>
            <div>
                <h2>Список начинок</h2>
                <Link to="/create-filling">Создать новую начинку</Link>
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Img</th>
                        <th>ID</th>
                        <th>Название</th>

                        <th scope="col"></th> {/* Новый столбец для действий */}
                    </tr>
                    </thead>
                    <tbody>
                    {fillings?.map((filling: Filling) => (
                        <tr key={filling.id}>
                            <td><img className="allFillingImg" src={filling.imageUrl} alt={filling.name} /></td>
                            <td>{filling.id}</td>
                            <td>{filling.name}</td>
                            <td><Link to={`/update-filling/${filling.id}`}>Редактировать</Link></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </Layout>
    );
};

export default FillingsList;
