import React, {useEffect, useState, useRef} from 'react';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import AlertMessage from 'components/AlertMessage';
import GlowingButton from "../fragment/GlowingButton/GlowingButton";
import {userStore} from "../../stores/UserStore";
import {storeStore} from "../../stores/StoreStore";
import {useLocation} from "react-router-dom";
import {Observer} from "mobx-react";

type LayoutProps = {
    children: React.ReactNode;
    alertMessage?: string;
    alertType?: 'success' | 'danger' | 'warning' | 'info';
};

const Layout: React.FC<LayoutProps> = ({children, alertMessage, alertType}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const sidebarRef = useRef<HTMLDivElement>(null);

    const currentYear = new Date().getFullYear();

    // const hasStore = storeStore.stores.length > 0;
    const location = useLocation();
    const isCreateStorePage = location.pathname === '/create-store';
    const hasStore = storeStore.hasStore;

    // useEffect(() => {
    //     userStore.fetchUserByEmail();
    // }, []);
    //
    // useEffect(() => {
    //     storeStore.fetchUserHasStore(1);
    // }, []);

    // useEffect(() => {
    //     const fetchUserData = async () => {
    //         // await userStore.fetchUserByEmail();
    //         // const userId = userStore.user?.id; // Получение id пользователя из объекта пользователя
    //         const userId = localStorage.getItem("userId");
    //         console.log("fetchUserData")
    //
    //         if (userId) {
    //             const userIdAsNumber = parseInt(userId, 10);
    //             storeStore.fetchUserHasStore(userIdAsNumber);
    //         }
    //     };
    //
    //     fetchUserData();
    // }, []);

    useEffect(() => {


        const fetchUserData = async () => {
            const userId = localStorage.getItem("userId");

            if (!userId) return;

            const userIdAsNumber = parseInt(userId, 10);
            console.log("fetchUserData");
            storeStore.fetchUserHasStore(userIdAsNumber);
            // storeStore.fetchUserHasStore();
        };

        fetchUserData();
    }, []);


    useEffect(() => {
        if (sidebarRef.current) {
            if (isCollapsed) {
                sidebarRef.current.classList.add('collapsed');
            } else {
                sidebarRef.current.classList.remove('collapsed');
            }
        }
    }, [isCollapsed]);

    const handleSidebarToggle = () => {
        console.log("handleSidebarToggle");

        setIsCollapsed(!isCollapsed);
    };

    return (

        <div>

            {/* {alertMessage && alertType && <AlertMessage message={alertMessage} type={alertType} showAlert={false} onClose={() => { }} />} */}

            {/*<p>Email пользователя: {userStore.user ? userStore.user.email : 'Пользователь не загружен'}</p>*/}

            <div className="wrapper">
                <Sidebar ref={sidebarRef}/>

                <div className="main">

                    <Navbar onToggleSidebar={handleSidebarToggle}/>
                    <main className="content custom">
                        <div className="container-fluid p-0">

                            {children}

                        </div>
                    </main>

                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row text-muted">
                                <div className="col-6 text-start">
                                    <p className="mb-0">
                                        &copy;  tortam.ru 2021 - {currentYear}
                                    </p>
                                </div>
                                <div className="col-6 text-end">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <a className="text-muted" href="#" target="_blank">Поддержка</a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-muted" href="#" target="_blank">Справочный
                                                центр</a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-muted" href="#"
                                               target="_blank">Конфиденциальность</a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-muted" href="#" target="_blank">Условия</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
            <Observer>
                {() => (
                    <div>
                        {storeStore.hasStore === false && !isCreateStorePage && (
                            <div className="create-store-button">
                                <GlowingButton text="Создать магазин"/>
                            </div>
                        )}
                    </div>
                )}
            </Observer>
        </div>

    );
};

export default Layout;