import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { UserFieldValue } from 'types/UserFieldValue';

export const useSaveUserFieldValues = () => {
    const queryClient = useQueryClient();
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem('userId') || '';

    return useMutation(
        (fieldValues: UserFieldValue[]) => axios.post( // Указываем, что fieldValues имеет тип UserFieldValue[]
            `${process.env.REACT_APP_API_URL}/userfieldvalues/save?userId=${userId}`,
            fieldValues, {
                headers: { 'Authorization': `Bearer ${token}` }
            }
        ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('userFields');
            }
        }
    );
};
