// src/components/OAuthRedirect.tsx
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchOAuthToken } from './yandexOAuth';

const OAuthRedirect: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  console.log("cold");
  console.log(code);

  useEffect(() => {
    if (code) {
      fetchOAuthToken(code, navigate)
        .catch((error) => {
          console.error('Error fetching token:', error);
        });
    }
  }, [code, navigate]);

  return <div>Redirecting...</div>;
};

export default OAuthRedirect;