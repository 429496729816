// src/hooks/useFetchUser.ts
import { useQuery } from "react-query";
import axios from "axios";
import { User } from "types/User";
import { useDispatch } from 'react-redux';
import { setUserId } from "store/actions/userActions"; 



const useFetchUser = (email: string, token: string) => {
  const dispatch = useDispatch();

  const fetchUser = async (): Promise<User> => {
    console.log("Token:", token, "Email:", email);
    const response = await axios.get<User>(
      `${process.env.REACT_APP_API_URL}/users/email/${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Обновляем userId в Redux и localStorage
    dispatch(setUserId(response.data.id.toString()));
    localStorage.setItem('userId', response.data.id.toString());

    return response.data;
  };

  return useQuery(["user", email], fetchUser, {
    enabled: !!token && !!email,
  });
};

export default useFetchUser;
