import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';

import {StoreData} from "../../types/StoreData";
import {storeStore} from "../../stores/StoreStore";
import Layout from "../Layout/Layout";
import {FormRow} from "../fragment/FormRow"; // Предположим, что этот компонент уже определён

const UpdateStore: React.FC = observer(() => {
    const { id } = useParams<{ id?: string }>();
    const [initialData, setInitialData] = useState<StoreData | null>(null);

    useEffect(() => {
        if (id) {
            const fetchStore = async () => {
                await storeStore.fetchStoreById(Number(id));
                console.log('storeStore.currentStore')
                console.log(storeStore.currentStore)
                setInitialData(storeStore.currentStore); // Предположим, что `currentStore` определен в `StoreStore`
            };

            fetchStore();
        }
    }, [id]);

    return (
        <Layout>
            <div>
                <h1>Обновить магазин</h1>
                {initialData ? (
                    <Formik
                        initialValues={initialData}
                        enableReinitialize  // Важно для обновления формы при изменении initialData
                        onSubmit={async (values, { setSubmitting }) => {
                            // console.log(values)
                            // await storeStore.updateStore(initialData.id!, values);
                            // setSubmitting(false);

                            console.log("Original values:", values);

                            // Создаём новый объект без свойства products
                            const { products, ...valuesWithoutProducts } = values;

                            console.log("Modified values:", valuesWithoutProducts);

                            await storeStore.updateStore(initialData.id!, valuesWithoutProducts);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <table className="table">
                                    <tbody>
                                    <FormRow label="Активный" type="checkbox" name="active" placeholder="Активный" className="form-check-input" />
                                    <FormRow label="Название магазина" type="text" name="storeName" placeholder="Введите название магазина" />
                                    <FormRow label="Slug" type="text" name="slug" placeholder="Slug" />
                                    <FormRow label="Описание магазина" type="text" name="description" placeholder="Введите описание магазина" as="textarea" />
                                    </tbody>
                                </table>
                                <button type="submit" disabled={isSubmitting}>
                                    Обновить магазин
                                </button>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <p>Загрузка данных магазина...</p>
                )}
            </div>
        </Layout>
    );
});

export default UpdateStore;