import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

import { Role } from 'types/User';


export const useGetAllRoles = (): UseQueryResult<Role[], Error> => {
  return useQuery<Role[], Error>('userFields', async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get<Role[]>(`${process.env.REACT_APP_API_URL}/roles`, config);
    return data;
  }, {
    // Здесь можно добавить дополнительные настройки, например, staleTime, cacheTime и т.д.
  });
};
