import React, { useState, useEffect } from 'react';

type ExerciseTimerProps = {
    tensionTime: number; // время напряжения
    relaxTime: number; // время расслабления
    totalTime: number; // общее время упражнения
    onFinish: () => void;
    timerStarted: boolean;
};

export const ExerciseTimer: React.FC<ExerciseTimerProps> = ({
    tensionTime,
    relaxTime,
    totalTime,
    onFinish,
    timerStarted
}) => {
    const [currentPhase, setCurrentPhase] = useState<'prepare' | 'tension' | 'relax' | 'finished'>('tension'); // Начинаем сразу с фазы напряжения
    const [timeLeft, setTimeLeft] = useState(tensionTime);
    const [overallTimeLeft, setOverallTimeLeft] = useState(totalTime);

    useEffect(() => {
        // Удаление логики подготовки, начинаем сразу с напряжения
        if (overallTimeLeft <= 0) {
            setCurrentPhase('finished');
            return;
        }

        const timer = setInterval(() => {
            setOverallTimeLeft((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [overallTimeLeft]);

    useEffect(() => {
        if (currentPhase !== 'tension' && currentPhase !== 'relax') return;

        if (timeLeft <= 0) {
            setCurrentPhase(currentPhase === 'tension' ? 'relax' : 'tension');
            setTimeLeft(currentPhase === 'tension' ? relaxTime : tensionTime);
            return;
        }

        const timer = setTimeout(() => {
            setTimeLeft((prev) => prev - 1);
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft, currentPhase, tensionTime, relaxTime]);

    useEffect(() => {
        if (overallTimeLeft <= 0) {
            setCurrentPhase('finished');
            onFinish(); // Вызов функции обратного вызова при завершении
        }
    }, [overallTimeLeft, onFinish]);

    return (
        <div>
            {timerStarted === true && (
                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    {currentPhase === 'tension' && (
                        <div style={{ color: '#D32F2F' }}> {/* Красный цвет для фазы напряжения */}
                            <h2>Напрягите</h2>
                            <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{timeLeft}</p>
                            {/* Можно добавить иконку или изображение для наглядности */}
                        </div>
                    )}
                    {currentPhase === 'relax' && (
                        <div style={{ color: '#303F9F' }}> {/* Синий цвет для фазы расслабления */}
                            <h2>Расслабьте</h2>
                            <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{timeLeft}</p>
                            {/* Можно добавить иконку или изображение для наглядности */}
                        </div>
                    )}
                </div>
            )}
            {/* {currentPhase === 'finished' && <p>Упражнение завершено</p>} */}

        </div>
    );
};