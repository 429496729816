// import { useQuery } from 'react-query';
// import axios from 'axios';
// import { Product } from 'types/ProductTypes';

// export const useFetchAllProducts = () => {
//   const fetchAllProducts = async (): Promise<Product[]> => {
//     const token = localStorage.getItem("token"); // Получение токена аутентификации
//     if (!token) throw new Error("No token found");
//
//     const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return response.data;
//   };
//   return useQuery<Product[], Error>('products', fetchAllProducts);
// };

// export const useFetchAllProducts = (page: number, size: number) => {
//   const fetchAllProducts = async (): Promise<{ content: Product[], totalPages: number }> => {
//     const token = localStorage.getItem("token");
//     if (!token) throw new Error("No token found");
//
//     const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       params: { page, size }
//     });
//     return response.data;
//   };
//   return useQuery<{ content: Product[], totalPages: number }, Error>(
//       ['products', page, size],
//       () => fetchAllProducts()
//   );
// };


import { useQuery } from 'react-query';
import axios from 'axios';
import {Product} from "../../types/ProductTypes";

export const useFetchAllProducts = (page: number, size: number, searchQuery: string) => {
  const fetchAllProducts = async (): Promise<{ content: Product[], totalPages: number }> => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("No token found");

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { page, size, search: searchQuery }
    });
    return response.data;
  };
  return useQuery<{ content: Product[], totalPages: number }, Error>(
      ['products', page, size, searchQuery],
      () => fetchAllProducts()
  );
};
