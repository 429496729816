// hooks/useCreateProduct.ts
import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { Product } from 'types/ProductTypes';


// Используя any (менее предпочтительно)
export const useCreateProduct = () => {
  const token = localStorage.getItem("token");

  // return useMutation((product: any) => {
  //   return axios.post(`${process.env.REACT_APP_API_URL}/products`, product, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': product instanceof FormData ? 'multipart/form-data' : 'application/json',
  //     },
  //   });
  // });
  return useMutation((product: any) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/products`, product, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': product instanceof FormData ? 'multipart/form-data' : 'application/json',
      },
    }).catch(error => {
      console.error(error.response); // Логирование ответа сервера при ошибке
      throw error; // Перебрасываем ошибку дальше, чтобы она была обработана в onError
    });
  });
  
};

