import { useCallback } from 'react';

interface FormDataOptions {
    exclude?: string[];
}

export const useFormData = () => {
    const createFormData = useCallback((values: any, options?: FormDataOptions): FormData => {
        const formData = new FormData();
        const exclude = options?.exclude || [];

        // Создаем объект данных, исключая указанные поля
        const data: { [key: string]: any } = {};
        Object.keys(values).forEach(key => {
            if (!exclude.includes(key) && key !== 'imageUrl') {
                data[key] = values[key];
            }
        });

        formData.append('data', JSON.stringify(data));

        if (values.imageUrl) {
            formData.append('image', values.imageUrl);
        }

        return formData;
    }, []);

    return { createFormData };
};