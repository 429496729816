import React, { useState } from 'react';
import { useCreateUserField } from 'hooks/userFields/useCreateUserFields';
import { useFetchRoles } from 'hooks/useFetchRoles';
import { UserField } from 'types/UserFieldTypes';
import useAlert from 'hooks/useAlert';
import Layout from 'components/Layout/Layout';
import AlertMessage from 'components/AlertMessage';
import { Link } from 'react-router-dom';

const CreateUserFieldComponent: React.FC = () => {
    const [userField, setUserField] = useState<UserField>({
        id: 0,
        roleId: 0,
        name: '',
        type: '',
        typeValue: '',
        isActive: true,
        isRequired: false,
        sortOrder: 0,
    });
    const fieldTypes = ['Строка', 'Число', 'Дата', 'Список'];
    // Состояние для отображения блока
    const [showSelectInputs, setShowSelectInputs] = useState(false);
    // Состояние для хранения дополнительных полей
    const [selectInputs, setSelectInputs] = useState(['']);

    const { mutate: createUserField, isLoading, isError } = useCreateUserField();
    const { data: roles, isLoading: isLoadingRoles, error: rolesError } = useFetchRoles();

    const { alertData, showAlert, triggerAlert, resetAlert } = useAlert();

    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setUserField({ ...userField, roleId: parseInt(e.target.value, 10) });
    };

    // const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     setUserField({ ...userField, type: e.target.value });
    // };

    const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedType = e.target.value;
        setUserField({ ...userField, type: selectedType });

        setShowSelectInputs(selectedType === 'Список');
    };

    const handleAddInput = () => {
        setSelectInputs([...selectInputs, '']);
    };

    // Обработчик для изменения sortOrder
    const handleSortOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserField({ ...userField, sortOrder: parseInt(e.target.value, 10) || 0 });
    };

    // Обработчик изменений для is_active
    const handleIsActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserField({ ...userField, isActive: e.target.checked });
    };

    // Обработчик изменений для is_required
    const handleIsRequiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserField({ ...userField, isRequired: e.target.checked });
    };


    const handleInputChangeAddSelect = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSelectInputs = [...selectInputs];
        newSelectInputs[index] = e.target.value;
        setSelectInputs(newSelectInputs);
    };

    // const handleSubmit = () => {
    //     console.log("handleSubmit");
    //     console.dir(userField);
    //     console.log("Значения списков: ", selectInputs);
    //     // createUserField(userField);
    //     triggerAlert('Успешно создан!', 'success');
    // };

    const handleSubmit = () => {
        let updatedUserField = { ...userField };

        if (selectInputs.length > 0 && selectInputs.some(input => input.trim() !== '')) {
            updatedUserField = {
                ...updatedUserField,
                typeValue: selectInputs.filter(input => input.trim() !== '').join(', ')
            };
        }

        console.log("handleSubmit");
        console.dir(userField);
        console.log("updatedUserField");
        console.dir(updatedUserField);
        console.log("Значения списков: ", selectInputs);
        createUserField(updatedUserField);
        triggerAlert('Успешно создан!', 'success');
    };




    if (isLoading || isLoadingRoles) return <div>Загрузка...</div>;
    if (isError) return <div>Произошла ошибка</div>;
    if (rolesError) return <div>Ошибка загрузки ролей</div>;

    return (
        <Layout>
            <div>
                <div className="all-field-user">
                    <Link to="/show-field-user">Список всех полей пользователей</Link>
                </div>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Название поля</th>
                            <th scope="col">Роль</th>
                            <th scope="col">Тип поля</th>
                            <th scope="col">Порядок сортировки</th>
                            <th scope="col">Активно</th>
                            <th scope="col">Обязательно</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="mb-3">
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        value={userField.name}
                                        onChange={(e) => setUserField({ ...userField, name: e.target.value })}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className="mb-3">
                                    <select
                                        className="form-select"
                                        value={userField.roleId}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Выберите роль</option>
                                        {roles?.map(role => (
                                            <option key={role.id} value={role.id}>{role.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="mb-3 position-relative">
                                    <select
                                        className="form-select"
                                        value={userField.type}
                                        onChange={handleTypeChange}
                                    >
                                        <option value="">Выберите тип</option>
                                        {fieldTypes.map((type, index) => (
                                            <option key={index} value={type}>{type}</option>
                                        ))}
                                    </select>
                                    {showSelectInputs && (
                                        <div className='input-select position-absolute'>
                                            {selectInputs.map((input, index) => (
                                                <input
                                                    key={index}
                                                    className="form-control form-control-lg select"
                                                    type="text"
                                                    placeholder={`Значение списка ${index + 1}`} // Измененный плейсхолдер
                                                    value={input}
                                                    onChange={handleInputChangeAddSelect(index)}
                                                />
                                            ))}
                                            <svg onClick={handleAddInput} width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 12H20M12 4V20" stroke="#545e69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className="mb-3">
                                    <input
                                        className="form-control form-control-lg"
                                        type="number" // Поле ввода для чисел
                                        value={userField.sortOrder}
                                        onChange={handleSortOrderChange}
                                    />
                                </div>
                            </td>

                            <td>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="isActive"
                                        checked={userField.isActive}
                                        onChange={handleIsActiveChange}
                                    />
                                    <label className="form-check-label" htmlFor="isActive">Активно</label>
                                </div>
                            </td>
                            <td>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="isRequired"
                                        checked={userField.isRequired}
                                        onChange={handleIsRequiredChange}
                                    />
                                    <label className="form-check-label" htmlFor="isRequired">Обязательно</label>
                                </div>
                            </td>

                            <td>
                                <div className="mb-3">
                                    <button className="btn btn-success" onClick={handleSubmit}>Создать поле</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            {/* <AlertMessage
                message={alertData.message}
                type={alertData.type}
                showAlert={showAlert}
                onClose={resetAlert}
            /> */}
        </Layout>
    );
};

export default CreateUserFieldComponent;
