import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {Filling} from "../../types/Filling";

const createFilling = async (filling: Filling, imageFile?: File): Promise<Filling> => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("filling", new Blob([JSON.stringify(filling)], { type: "application/json" }));
    if (imageFile) {
        formData.append("imageFile", imageFile);
    }

    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/fillings`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    });
    return data;
};

export const useCreateFilling = () => {
    const queryClient = useQueryClient();
    return useMutation((params: { filling: Filling; imageFile?: File }) => createFilling(params.filling, params.imageFile), {
        onSuccess: () => {
            queryClient.invalidateQueries("fillings");
        },
    });
};
