
import React, { forwardRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useUser from 'hooks/useUser';
import CategoriesTree from './categories/CategoriesTree';
import { useCategories } from 'hooks/categories/useCategories';
import { ReactComponent as ProductIcon } from 'img/svg/add-product.svg';
import { ReactComponent as Attribute } from 'img/svg/attribute.svg';

const Sidebar = forwardRef<HTMLDivElement, {}>((props, ref) => {
    const { data: categories, isLoading: isCategoriesLoading } = useCategories();
    const navigate = useNavigate();
    const location = useLocation();

    const userId = localStorage.getItem('userId') || '';
    const { user } = useUser(userId);


    const handleLogout = () => {
        // Удаляем токен из localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('userId');

        // Перенаправляем пользователя на страницу выхода (или другую, по вашему выбору)
        navigate('/login'); // Замените '/logout' на путь к вашей странице выхода
    };
    // console.dir(user, { depth: null });
    //https://vk.com/id844011480
    //#545e69

    return (
        <nav id="sidebar" className="sidebar js-sidebar" ref={ref}>
            <div className="sidebar-content js-simplebar" data-simplebar="init">
                <div className="simplebar-wrapper m-0" style={{ margin: 0 }}>
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                        <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
                            <div className="simplebar-content-wrapper" role="region" aria-label="scrollable content" style={{ height: '100%', overflow: 'hidden scroll' }}>
                                <div className="simplebar-content" style={{ padding: 0 }}>
                                    <Link className="sidebar-brand" to="/index">
                                        <span className="align-middle">TorTam</span>
                                    </Link>
                                    <ul className="sidebar-nav">
                                        {user && user.role_id === 3 && (
                                            <>
                                                <li className="sidebar-header">
                                                    Admin
                                                </li>

                                                <li className={`sidebar-item ${location.pathname === '/sitemap' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/sitemap">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#545e69" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="6" r="4" stroke="#545e69" strokeWidth="1.5" />
                                                            <path d="M15 20.6151C14.0907 20.8619 13.0736 21 12 21C8.13401 21 5 19.2091 5 17C5 14.7909 8.13401 13 12 13C15.866 13 19 14.7909 19 17C19 17.3453 18.9234 17.6804 18.7795 18" stroke="#545e69" strokeWidth="1.5" strokeLinecap="round" />
                                                        </svg>
                                                        Генерация карты сайта
                                                    </Link>
                                                </li>

                                                <li className={`sidebar-item ${location.pathname === '/backup' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/backup">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#545e69" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="6" r="4" stroke="#545e69" strokeWidth="1.5" />
                                                            <path d="M15 20.6151C14.0907 20.8619 13.0736 21 12 21C8.13401 21 5 19.2091 5 17C5 14.7909 8.13401 13 12 13C15.866 13 19 14.7909 19 17C19 17.3453 18.9234 17.6804 18.7795 18" stroke="#545e69" strokeWidth="1.5" strokeLinecap="round" />
                                                        </svg>
                                                        Backup
                                                    </Link>
                                                </li>

                                                <li className={`sidebar-item ${location.pathname === '/create-filling' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/all-filling">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#545e69" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="6" r="4" stroke="#545e69" strokeWidth="1.5" />
                                                            <path d="M15 20.6151C14.0907 20.8619 13.0736 21 12 21C8.13401 21 5 19.2091 5 17C5 14.7909 8.13401 13 12 13C15.866 13 19 14.7909 19 17C19 17.3453 18.9234 17.6804 18.7795 18" stroke="#545e69" strokeWidth="1.5" strokeLinecap="round" />
                                                        </svg>
                                                        Начинки
                                                    </Link>
                                                </li>

                                                <li className={`sidebar-item ${location.pathname === '/all-articles' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/all-articles">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#545e69" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="6" r="4" stroke="#545e69" strokeWidth="1.5" />
                                                            <path d="M15 20.6151C14.0907 20.8619 13.0736 21 12 21C8.13401 21 5 19.2091 5 17C5 14.7909 8.13401 13 12 13C15.866 13 19 14.7909 19 17C19 17.3453 18.9234 17.6804 18.7795 18" stroke="#545e69" strokeWidth="1.5" strokeLinecap="round" />
                                                        </svg>
                                                        Статьи
                                                    </Link>
                                                </li>

                                                <li className="sidebar-item">
                                                    <Link className="sidebar-link" to="/register">
                                                        <svg fill="#545e69" width="24px" height="24px" viewBox="0 0 52 52" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M38.3,27.2A11.4,11.4,0,1,0,49.7,38.6,11.46,11.46,0,0,0,38.3,27.2Zm2,12.4a2.39,2.39,0,0,1-.9-.2l-4.3,4.3a1.39,1.39,0,0,1-.9.4,1,1,0,0,1-.9-.4,1.39,1.39,0,0,1,0-1.9l4.3-4.3a2.92,2.92,0,0,1-.2-.9,3.47,3.47,0,0,1,3.4-3.8,2.39,2.39,0,0,1,.9.2c.2,0,.2.2.1.3l-2,1.9a.28.28,0,0,0,0,.5L41.1,37a.38.38,0,0,0,.6,0l1.9-1.9c.1-.1.4-.1.4.1a3.71,3.71,0,0,1,.2.9A3.57,3.57,0,0,1,40.3,39.6Z" />
                                                            <circle cx="21.7" cy="14.9" r="12.9" />
                                                            <path d="M25.2,49.8c2.2,0,1-1.5,1-1.5h0a15.44,15.44,0,0,1-3.4-9.7,15,15,0,0,1,1.4-6.4.77.77,0,0,1,.2-.3c.7-1.4-.7-1.5-.7-1.5h0a12.1,12.1,0,0,0-1.9-.1A19.69,19.69,0,0,0,2.4,47.1c0,1,.3,2.8,3.4,2.8H24.9C25.1,49.8,25.1,49.8,25.2,49.8Z" />
                                                        </svg>
                                                        Роли
                                                    </Link>
                                                </li>
                                                <li className={`sidebar-item ${location.pathname === '/all-store' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/all-store">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#545e69" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="6" r="4" stroke="#545e69" strokeWidth="1.5" />
                                                            <path d="M15 20.6151C14.0907 20.8619 13.0736 21 12 21C8.13401 21 5 19.2091 5 17C5 14.7909 8.13401 13 12 13C15.866 13 19 14.7909 19 17C19 17.3453 18.9234 17.6804 18.7795 18" stroke="#545e69" strokeWidth="1.5" strokeLinecap="round" />
                                                        </svg>
                                                        Магазины
                                                    </Link>
                                                </li>
                                                <li className={`sidebar-item ${location.pathname === '/users' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/users">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#545e69" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="6" r="4" stroke="#545e69" strokeWidth="1.5" />
                                                            <path d="M15 20.6151C14.0907 20.8619 13.0736 21 12 21C8.13401 21 5 19.2091 5 17C5 14.7909 8.13401 13 12 13C15.866 13 19 14.7909 19 17C19 17.3453 18.9234 17.6804 18.7795 18" stroke="#545e69" strokeWidth="1.5" strokeLinecap="round" />
                                                        </svg>
                                                        Пользователи
                                                    </Link>
                                                </li>

                                                <li className="sidebar-item">
                                                    <Link className="sidebar-link" to="/show-info">
                                                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g id="Edit / Add_Row">
                                                                <path id="Vector" d="M3 14V15C3 16.1046 3.89543 17 5 17L19 17C20.1046 17 21 16.1046 21 15L21 13C21 11.8954 20.1046 11 19 11H13M10 8H7M7 8H4M7 8V5M7 8V11" stroke="#545e69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </g>
                                                        </svg> <span className="align-middle">Info</span>
                                                    </Link >
                                                </li>
                                                <li className={`sidebar-item ${location.pathname === '/show-field-user' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/show-field-user">
                                                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20 14V7C20 5.34315 18.6569 4 17 4H12M20 14L13.5 20M20 14H15.5C14.3954 14 13.5 14.8954 13.5 16V20M13.5 20H7C5.34315 20 4 18.6569 4 17V12" stroke="#545e69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M7 4V7M7 10V7M7 7H4M7 7H10" stroke="#545e69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg><span className="align-middle">Дополнительные поля</span>
                                                    </Link >
                                                </li>
                                                <li className={`sidebar-item ${location.pathname === '/all-attribute' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/all-attribute">
                                                    <Attribute className="attribute" />
                                                    <span className="align-middle">Атрибуты</span>
                                                    </Link >
                                                </li>
                                                <li className={`sidebar-item ${location.pathname === '/all-product' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/all-product">
                                                    <ProductIcon className="product-svg" />
                                                    <span className="align-middle">Продукты</span>
                                                    </Link >
                                                </li>
                                                <li className={`sidebar-item ${location.pathname === '/show-category' ? 'active' : ''}`}>
                                                    <Link className="sidebar-link" to="/show-category">
                                                        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20 14V7C20 5.34315 18.6569 4 17 4H12M20 14L13.5 20M20 14H15.5C14.3954 14 13.5 14.8954 13.5 16V20M13.5 20H7C5.34315 20 4 18.6569 4 17V12" stroke="#545e69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M7 4V7M7 10V7M7 7H4M7 7H10" stroke="#545e69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg><span className="align-middle">Категории</span>
                                                    </Link >
                                                    <ul className="menu-category">
                                                        <CategoriesTree categories={categories ?? []} expandAll={true} />
                                                    </ul>
                                                </li>
                                            </>
                                        )}
                                        <li className="sidebar-header">
                                            Личная информация
                                        </li>

                                        <li className={`sidebar-item ${location.pathname === '/index' ? 'active' : ''}`}>
                                            <Link className="sidebar-link" to="/index">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user align-middle">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg> <span className="align-middle">Главная</span>
                                            </Link>
                                        </li>

                                        <li className="sidebar-item">
                                            <a className="sidebar-link" onClick={handleLogout} href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in align-middle">
                                                    <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                                                    <polyline points="10 17 15 12 10 7"></polyline>
                                                    <line x1="15" y1="12" x2="3" y2="12"></line>
                                                </svg> <span className="align-middle">Выйти</span>
                                            </a>
                                        </li>

                                        <li className="sidebar-header ">
                                            Заказы
                                        </li>

                                        <li className="sidebar-item">
                                            <a className="sidebar-link" href="ui-buttons.html">
                                                <svg viewBox="0 0 512 512" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                                    <title />
                                                    <g data-name="1" fill="rgba(233,236,239,.5)" id="_1">
                                                        <path d="M397.78,316H192.65A15,15,0,0,1,178,304.33L143.46,153.85a15,15,0,0,1,14.62-18.36H432.35A15,15,0,0,1,447,153.85L412.4,304.33A15,15,0,0,1,397.78,316ZM204.59,286H385.84l27.67-120.48H176.91Z" />
                                                        <path d="M222,450a57.48,57.48,0,1,1,57.48-57.48A57.54,57.54,0,0,1,222,450Zm0-84.95a27.48,27.48,0,1,0,27.48,27.47A27.5,27.5,0,0,0,222,365.05Z" />
                                                        <path d="M368.42,450a57.48,57.48,0,1,1,57.48-57.48A57.54,57.54,0,0,1,368.42,450Zm0-84.95a27.48,27.48,0,1,0,27.48,27.47A27.5,27.5,0,0,0,368.42,365.05Z" />
                                                        <path d="M158.08,165.49a15,15,0,0,1-14.23-10.26L118.14,78H70.7a15,15,0,1,1,0-30H129a15,15,0,0,1,14.23,10.26l29.13,87.49a15,15,0,0,1-14.23,19.74Z" />
                                                    </g>
                                                </svg>
                                                <span className="align-middle">Моя корзина</span>
                                            </a>
                                        </li>

                                        <li className="sidebar-item">
                                            <a className="sidebar-link" href="ui-forms.html">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="rgba(233,236,239,.5)" d="M7.50626 15.2647C7.61657 15.6639 8.02965 15.8982 8.4289 15.7879C8.82816 15.6776 9.06241 15.2645 8.9521 14.8652L7.50626 15.2647ZM6.07692 7.27442L6.79984 7.0747V7.0747L6.07692 7.27442ZM4.7037 5.91995L4.50319 6.64265L4.7037 5.91995ZM3.20051 4.72457C2.80138 4.61383 2.38804 4.84762 2.2773 5.24675C2.16656 5.64589 2.40035 6.05923 2.79949 6.16997L3.20051 4.72457ZM20.1886 15.7254C20.5895 15.6213 20.8301 15.2118 20.7259 14.8109C20.6217 14.41 20.2123 14.1695 19.8114 14.2737L20.1886 15.7254ZM10.1978 17.5588C10.5074 18.6795 9.82778 19.8618 8.62389 20.1747L9.00118 21.6265C10.9782 21.1127 12.1863 19.1239 11.6436 17.1594L10.1978 17.5588ZM8.62389 20.1747C7.41216 20.4896 6.19622 19.7863 5.88401 18.6562L4.43817 19.0556C4.97829 21.0107 7.03196 22.1383 9.00118 21.6265L8.62389 20.1747ZM5.88401 18.6562C5.57441 17.5355 6.254 16.3532 7.4579 16.0403L7.08061 14.5885C5.10356 15.1023 3.89544 17.0911 4.43817 19.0556L5.88401 18.6562ZM7.4579 16.0403C8.66962 15.7254 9.88556 16.4287 10.1978 17.5588L11.6436 17.1594C11.1035 15.2043 9.04982 14.0768 7.08061 14.5885L7.4579 16.0403ZM8.9521 14.8652L6.79984 7.0747L5.354 7.47414L7.50626 15.2647L8.9521 14.8652ZM4.90421 5.19725L3.20051 4.72457L2.79949 6.16997L4.50319 6.64265L4.90421 5.19725ZM6.79984 7.0747C6.54671 6.15847 5.8211 5.45164 4.90421 5.19725L4.50319 6.64265C4.92878 6.76073 5.24573 7.08223 5.354 7.47414L6.79984 7.0747ZM11.1093 18.085L20.1886 15.7254L19.8114 14.2737L10.732 16.6332L11.1093 18.085Z" />
                                                    <path stroke="rgba(233,236,239,.5)" d="M19.1647 6.2358C18.6797 4.48023 18.4372 3.60244 17.7242 3.20319C17.0113 2.80394 16.1062 3.03915 14.2962 3.50955L12.3763 4.00849C10.5662 4.47889 9.66119 4.71409 9.24954 5.40562C8.8379 6.09714 9.0804 6.97492 9.56541 8.73049L10.0798 10.5926C10.5648 12.3481 10.8073 13.2259 11.5203 13.6252C12.2333 14.0244 13.1384 13.7892 14.9484 13.3188L16.8683 12.8199C18.6784 12.3495 19.5834 12.1143 19.995 11.4227C20.2212 11.0429 20.2499 10.6069 20.1495 10" strokeWidth="1.5" strokeLinecap="round" />
                                                </svg>
                                                <span className="align-middle">Мои заказы</span>
                                            </a>
                                        </li>

                                        <li className="sidebar-item">
                                            <a className="sidebar-link" href="ui-cards.html">
                                                <svg width="20px" height="20px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="rgba(233,236,239,.5)" d="M19.828 18.256l-.002.015c249.642 36.995 371.904 169.983 397.32 278.01-2.094 5.977-4.496 11.044-7.068 14.968-17.29 26.383-62.522 40.075-101.654 28.596 5.984-19.75 10.132-39.834 12.07-59.12-95.46 8.177-212.544 8.42-301.207-22.642 41.727 95.317 99.325 164.465 164.983 230.08 18.296-2.164 35.807-11.35 51.837-25.37 85.218 34.667 188.066-2.555 226.748-60.68 46.922-70.5 74.07-317.52-167.462-383.856H232.81c160.326 54.874 195.73 167.74 191.573 239.03-37.15-93.627-137.68-191.855-312.38-239.03H19.83z" /></svg>
                                                <span className="align-middle">Мои возвраты</span>
                                            </a>
                                        </li>

                                        <li className="sidebar-item">
                                            <a className="sidebar-link" href="ui-typography.html">
                                                <svg height="21px" width="21px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    viewBox="0 0 512 512" xmlSpace="preserve">
                                                    <g>
                                                        <path className="st0" d="M219.596,446.903c-5.673-3.839-12.572-6.085-19.906-6.085c-4.885,0-9.596,0.995-13.855,2.804
                                                                                            c-6.398,2.704-11.808,7.211-15.647,12.884c-3.84,5.657-6.094,12.564-6.085,19.898c0,4.884,0.995,9.596,2.804,13.863
                                                                                            c2.705,6.397,7.203,11.815,12.876,15.647c5.673,3.832,12.572,6.092,19.907,6.085c4.883,0.008,9.595-0.995,13.854-2.805
                                                                                            c6.396-2.713,11.807-7.202,15.655-12.876c3.832-5.673,6.085-12.58,6.076-19.914c0.008-4.884-0.994-9.588-2.804-13.846
                                                                                            C229.768,456.153,225.27,450.743,219.596,446.903z M212.912,481.996c-1.078,2.557-2.919,4.769-5.205,6.315
                                                                                            c-2.302,1.553-5.016,2.442-8.016,2.45c-2.015,0-3.873-0.403-5.584-1.126c-2.565-1.077-4.769-2.919-6.315-5.213
                                                                                            c-1.554-2.303-2.45-5.008-2.45-8.017c0-2.006,0.402-3.865,1.118-5.575c1.086-2.566,2.919-4.777,5.213-6.323
                                                                                            c2.303-1.554,5.016-2.442,8.018-2.45c2.014,0.008,3.872,0.403,5.582,1.126c2.558,1.085,4.77,2.911,6.315,5.213
                                                                                            c1.546,2.303,2.442,5,2.451,8.009C214.038,478.42,213.635,480.278,212.912,481.996z"
                                                        />
                                                        <path className="st0" d="M414.78,446.903c-5.674-3.839-12.581-6.085-19.915-6.085c-4.884,0-9.587,0.995-13.846,2.804
                                                            c-6.413,2.704-11.824,7.211-15.656,12.884c-3.832,5.657-6.084,12.564-6.084,19.898c0,4.884,1.003,9.596,2.804,13.863
                                                            c2.705,6.397,7.211,11.815,12.884,15.647c5.657,3.832,12.564,6.092,19.898,6.085c4.884,0.008,9.596-0.995,13.863-2.805
                                                            c6.398-2.713,11.808-7.202,15.647-12.876c3.832-5.673,6.085-12.58,6.077-19.914c0.008-4.884-0.987-9.588-2.796-13.846
                                                            C424.942,456.153,420.453,450.743,414.78,446.903z M408.086,481.996c-1.076,2.557-2.91,4.769-5.204,6.315
                                                            c-2.303,1.553-5.008,2.442-8.017,2.45c-2.006,0-3.865-0.403-5.575-1.126c-2.566-1.077-4.769-2.919-6.331-5.213
                                                            c-1.546-2.303-2.434-5.008-2.434-8.017c0-2.006,0.395-3.865,1.118-5.575c1.085-2.566,2.911-4.777,5.214-6.323
                                                            c2.302-1.554,4.999-2.442,8.008-2.45c2.015,0.008,3.881,0.403,5.592,1.126c2.548,1.085,4.769,2.911,6.315,5.213
                                                            c1.546,2.303,2.442,5,2.45,8.009C409.213,478.42,408.81,480.278,408.086,481.996z"
                                                        />
                                                        <path className="st0" d="M484.193,108.848c-4.9-5.986-12.235-9.472-19.98-9.472H356.705V15.17V0h-15.171h-71.715H254.64v15.17v84.206
                                                            H127.826l-11.453-39.336v-0.024c-5.262-17.604-19.701-30.932-37.667-34.78l-41.12-8.815c-6.956-1.488-13.814,2.944-15.31,9.908
                                                            c-1.497,6.956,2.944,13.822,9.908,15.31l41.12,8.815c8.749,1.874,15.787,8.378,18.352,16.954l75.153,258.15
                                                            c-1.694,0.789-3.338,1.653-4.908,2.59c-8.099,4.827-14.595,11.602-18.961,19.512c-4.16,7.524-6.381,16.042-6.586,24.832h-0.033
                                                            v1.34h0.033c0.164,6.857,1.628,13.452,4.16,19.462c4.07,9.604,10.83,17.736,19.356,23.5c8.502,5.756,18.862,9.135,29.88,9.127
                                                            h241.705c7.12,0,12.893-5.772,12.893-12.892c0-7.12-5.772-12.901-12.893-12.901H189.749c-3.848,0-7.45-0.765-10.754-2.162
                                                            c-4.942-2.088-9.193-5.608-12.17-10.031c-2.861-4.243-4.555-9.258-4.686-14.751c0.14-6.553,2.228-12.235,5.895-16.782
                                                            c1.9-2.352,4.251-4.408,7.096-6.109c2.812-1.677,6.117-3.001,9.998-3.84L428.083,306.9c15.869-2.59,28.49-14.718,31.698-30.48
                                                            l29.716-146.096l0.008-0.017c0.345-1.693,0.51-3.42,0.51-5.13C490.014,119.282,487.992,113.501,484.193,108.848z M305.669,256.415
                                                        l-78.886-98.669h43.036V15.17h71.715v142.576h43.037L305.669,256.415z"
                                                        />
                                                    </g>
                                                </svg>
                                                <span className="align-middle">Купленные товары</span>
                                            </a>
                                        </li>
                                        <li className="sidebar-header ">
                                            Отзывы и вопросы о товарах
                                        </li>
                                        <li className="sidebar-item">
                                            <a className="sidebar-link" href="icons-feather.html">
                                                <svg fill="none" height="20" viewBox="0 0 27 26" width="21" xmlns="http://www.w3.org/2000/svg"><path d="M22 1H4.89999C2.79999 1 1.10001 2.70815 1.10001 4.81823V17.2778C1.10001 19.3878 2.79999 21.096 4.89999 21.096H14.8C15.2 21.096 15.5 21.1965 15.8 21.4979L19.1 24.8138C19.5 25.2157 20.1 24.9142 20.1 24.4118V22.5027C20.1 21.6989 20.7 21.096 21.5 21.096H22C24.1 21.096 25.8 19.3878 25.8 17.2778V4.81823C25.8 2.70815 24.1 1 22 1Z" stroke="rgba(233,236,239,.5)" strokeMiterlimit="10" strokeWidth="2" /><path d="M7.39999 13.56C8.39411 13.56 9.2 12.7503 9.2 11.7514C9.2 10.7525 8.39411 9.94275 7.39999 9.94275C6.40588 9.94275 5.60001 10.7525 5.60001 11.7514C5.60001 12.7503 6.40588 13.56 7.39999 13.56Z" fill="rgba(233,236,239,.5)" /><path d="M13.5 13.56C14.4941 13.56 15.3 12.7503 15.3 11.7514C15.3 10.7525 14.4941 9.94275 13.5 9.94275C12.5059 9.94275 11.7 10.7525 11.7 11.7514C11.7 12.7503 12.5059 13.56 13.5 13.56Z" fill="rgba(233,236,239,.5)" /><path d="M19.5 13.56C20.4941 13.56 21.3 12.7503 21.3 11.7514C21.3 10.7525 20.4941 9.94275 19.5 9.94275C18.5059 9.94275 17.7 10.7525 17.7 11.7514C17.7 12.7503 18.5059 13.56 19.5 13.56Z" fill="rgba(233,236,239,.5)" /></svg>
                                                <span className="align-middle">Мои отзывы</span>
                                            </a>
                                        </li>
                                        <li className="sidebar-item">
                                            <a className="sidebar-link" href="icons-feather.html">
                                                <svg version="1.1" fill="rgba(233,236,239,.5)" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                    width="21px" height="21px" viewBox="0 0 535.5 535.5"
                                                >
                                                    <g>
                                                        <g id="live-help">
                                                            <path d="M446.25,0h-357c-28.05,0-51,22.95-51,51v357c0,28.05,22.95,51,51,51h102l76.5,76.5l76.5-76.5h102c28.05,0,51-22.95,51-51
                                                            V51C497.25,22.95,474.3,0,446.25,0z M293.25,408h-51v-51h51V408z M346.8,211.65l-22.95,22.95c-20.399,17.85-30.6,33.15-30.6,71.4
                                                            h-51v-12.75c0-28.05,10.2-53.55,30.6-71.4l30.601-33.15c10.2-7.65,15.3-20.4,15.3-35.7c0-28.05-22.95-51-51-51s-51,22.95-51,51
                                                            h-51c0-56.1,45.9-102,102-102c56.1,0,102,45.9,102,102C369.75,175.95,359.55,196.35,346.8,211.65z"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                </svg>
                                                <span className="align-middle">Мои вопросы и ответы</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="simplebar-placeholder" style={{ width: 'auto', height: '957px' }}></div>
                </div>
                <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                    <div className="simplebar-scrollbar" style={{ width: 0, display: 'none' }}></div>
                </div>
                <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                    <div className="simplebar-scrollbar" style={{ height: '244px', display: 'block', transform: 'translate3d(0px, 0px, 0px)' }}></div>
                </div>
            </div>
        </nav>
    );
});

export default Sidebar;