import { makeAutoObservable } from "mobx";
import axios from "axios";
import {User} from "../types/User";

class UserStore {
    user: User | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    fetchUserByEmail = async () => {
        const email = localStorage.getItem('email');
        if (email) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/email/${email}`);
                this.user = response.data;
            } catch (error) {
                console.error('Ошибка при получении пользователя: ', error);
            }
        }
    };
}

export const userStore = new UserStore();
