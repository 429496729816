import React, { useEffect } from 'react';
import * as VKID from '@vkid/sdk';
import '../../configs/vkid.config';
import '../VkIdLogin/vkbutton.scss'
import { FaVk } from 'react-icons/fa';
import Vk from "../SVG/Vk/Vk";

const CustomVkIdButton: React.FC = () => {
    useEffect(() => {
        const handleClick = () => {
            VKID.Auth.login();
        };

        const button = document.getElementById('VKIDSDKAuthButton');
        if (button) {
            button.onclick = handleClick;
        }
    }, []);

    return (
        <a id="VKIDSDKAuthButton" className="VkIdWebSdk__button VkIdWebSdk__button_reset">
            <Vk />
        </a>
    );
};

export default CustomVkIdButton;
