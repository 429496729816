import { useQuery, UseQueryResult } from 'react-query';
import { IApiResponse } from 'types/Info';
import axios from 'axios';

const fetchInfo = async (): Promise<IApiResponse[]> => {
  const token = localStorage.getItem('token');
  const { data } = await axios.get<IApiResponse[]>(`${process.env.REACT_APP_API_URL}/info`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const useFetchInfo = (): UseQueryResult<IApiResponse[], Error> => {
  return useQuery<IApiResponse[], Error>('info', fetchInfo);
};
