import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import {User} from "../../types/User";
interface ExchangeResponse {
    user: User;
    token: string;
}
const RedirectHandlerMail: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');

        if (code) {

            axios.post(`${process.env.REACT_APP_API_URL}/auth/mail/token`, { code })
                .then(response => {
                    // Handle success response, for example, navigate to another page
                    console.log('Token obtained successfully', response.data);
                    if (response.data.isProvider) {
                        // Действие, если есть response.data.isProvider
                        console.log('isProvider:', response.data.isProvider);
                        // navigate('/login');
                        // navigate('/login', { state: { isProvider: response.data.isProvider } });
                        navigate('/login', { state: { isProvider: response.data.isProvider, details: response.data } });
                        // Ваше действие здесь
                    } else if (response.data.token) {
                        // Действие, если есть response.data.token
                        console.log('token:', response.data.token);
                        const responseData: ExchangeResponse = response.data;
                        localStorage.setItem('token', responseData.token);
                        localStorage.setItem('userId', responseData.user.id.toString());
                        navigate('/index');
                    } else {
                        // Действие, если ни одно из условий не выполнено
                        console.log('Neither isProvider nor token found in response.');
                        // Ваше действие здесь
                    }
                })
                .catch(error => {
                    // Handle error response
                    console.error('Error obtaining token', error);
                    navigate('/error');
                });
        }
    }, [location, navigate]);

    return <div>Redirecting...</div>;
};

export default RedirectHandlerMail;
