import React from 'react';
import Modal from 'react-modal';
import './providerModal.scss';

interface ProviderModalProps {
    isOpen: boolean;
    onClose: () => void;
    emailProvider: string | null;
    phoneProvider: string | null;
    providersMap: { [key: string]: JSX.Element };
}

const ProviderModal: React.FC<ProviderModalProps> = ({ isOpen, onClose, emailProvider, phoneProvider, providersMap }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Provider Information"
            ariaHideApp={false}
        >
            <div>
                <h2>Пользователь уже зарегистрирован через следующие провайдеры:</h2>
                <div className="auth-social-buttons">
                    {emailProvider && providersMap[emailProvider]}
                    {phoneProvider && providersMap[phoneProvider]}
                </div>
                <button onClick={onClose}>Закрыть</button>
            </div>
        </Modal>
    );
};

export default ProviderModal;
