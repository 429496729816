import { useQuery } from 'react-query';
import axios from 'axios';
import { Attribute } from 'types/ProductTypes';


// Хук для получения всех атрибутов
export const useGetAllAttributes = () => {
  const token = localStorage.getItem("token"); // Получение токена аутентификации
  // console.log(token);
  

  return useQuery<Attribute[], Error>('attributes', async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/attribute`, {
      headers: {
        Authorization: `Bearer ${token}`, // Добавление токена в заголовки запроса
      },
    });
    return data;
  });
};
