import axios from "axios";

export const validatePhone = async (phone: string): Promise<boolean> => {
    if (!phone.trim()) return false; // Возвращаем false, если строка пуста

    const token = localStorage.getItem("token");


    try {
        const { data } = await axios.get<boolean>(
            `${process.env.REACT_APP_API_URL}/users/check-phone`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { phone },
            }
        );
        return data; // Убедитесь, что API возвращает примитивный тип boolean
    } catch (error) {
        console.error('Ошибка при проверке телефона', error);
        // В случае ошибки, верните false или обработайте ошибку соответствующим образом
        return false;
    }
};
