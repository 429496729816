import Layout from 'components/Layout/Layout';
import { useFetchAllAttributes } from 'hooks/attributes/useFetchAllAttributes';
import React from 'react';
import { Link } from 'react-router-dom';
import { Attribute } from 'types/ProductTypes';

const AttributesComponent: React.FC = () => {
    const { data: attributes, isLoading, error } = useFetchAllAttributes();

    console.log(attributes);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error has occurred: {error.toString()}</div>;

    return (
        <Layout>
            <div>
                <h2>Атрибуты</h2>
                <Link to="/create-attribute">Создать новый атрибут</Link>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Название</th>
                            <th scope="col">Тип</th>
                            <th scope="col">Порядок сортировки</th>
                            <th scope="col">Активен</th>
                            <th scope="col">Редактировать</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attributes?.map((attribute: Attribute) => (
                            <tr key={attribute.id}>
                                <td>{attribute.id}</td>
                                <td>{attribute.attributeName}</td>
                                <td>{attribute.attributeType}</td>
                                <td>{attribute.sortOrder}</td>
                                <td>{attribute.isActive ? "Да" : "Нет"}</td>
                                <td><Link to={`/update-attribute/${attribute.id}`}>Редактировать</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Layout>
    );
};

export default AttributesComponent;
