import React, {useState} from 'react';
import {useFetchUsers} from 'hooks/useFetchUsers';
import Layout from 'components/Layout/Layout';
import {Link} from 'react-router-dom';

const UsersComponent: React.FC = () => {
    const {data: users, isLoading, error} = useFetchUsers();


    const [activeTab, setActiveTab] = useState('tabAll'); // Устанавливаем активный таб по умолчанию

    const handleTabClick = (tabName: React.SetStateAction<string>) => {
        setActiveTab(tabName);
    };


    if (isLoading) return <div>Loading...</div>;
    if (error instanceof Error) return <div>An error occurred: {error.message}</div>;
    if (!users || users.length === 0) return <div>No users found.</div>;

    // Фильтруем пользователей для каждой вкладки
    const tab1Users = users.filter(user => user.role_id === 1);
    const tab2Users = users.filter(user => user.role_id === 2);
    const tab3Users = users.filter(user => user.role_id === 3);

    return (
        <Layout>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'tabAll' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tabAll')}
                    >
                        Все пользователи
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tab1')}
                    >
                        Покупатели
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tab2')}
                    >
                        Продавцы
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tab3')}
                    >
                        Администраторы
                    </a>
                </li>
            </ul>


            <div className="tab-content">


                <div className={`tab-pane ${activeTab === 'tabAll' ? 'show active' : ''}`}>
                    <table className="table align-middle mb-0 bg-white">
                        <thead className="bg-light">
                        <tr>
                            <th>ID</th>
                            <th>Фамилия</th>
                            <th>Имя</th>
                            <th>Отчество</th>
                            <th>Email</th>
                            <th>Активный</th>
                            <th>Подтвержденный</th>
                            {/* Добавьте другие заголовки, если это необходимо */}
                        </tr>
                        </thead>
                        <tbody>

                        {users.map(user => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.lastname}</td>
                                <td>{user.firstname}</td>
                                <td>{user.patronymic}</td>

                                <td>{user.email}</td>
                                <td>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={user.is_active}
                                        onChange={() => {
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={user.is_registered}
                                        onChange={() => {
                                        }}
                                    />
                                </td>
                                {/* Выведите другие данные, если это необходимо */}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className={`tab-pane ${activeTab === 'tab1' ? 'show active' : ''}`}>
                    <table className="table align-middle mb-0 bg-white">
                        <thead className="bg-light">
                        <tr>
                            <th>ID</th>
                            <th>Фамилия</th>
                            <th>Имя</th>
                            <th>Отчество</th>
                            <th>Email</th>
                            <th>Активный</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {tab1Users.map(user => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.lastname}</td>
                                <td>{user.firstname}</td>
                                <td>{user.patronymic}</td>
                                <td>{user.email}</td>
                                <td>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={user.is_active}
                                        onChange={() => {
                                        }}
                                    />
                                </td>
                                <td>
                                    <Link to={`/update-user/${user.id}`}>Редактировать</Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className={`tab-pane ${activeTab === 'tab2' ? 'show active' : ''}`}>
                    <table className="table align-middle mb-0 bg-white">
                        <thead className="bg-light">
                        <tr>
                            <th>ID</th>
                            <th>Фамилия</th>
                            <th>Имя</th>
                            <th>Отчество</th>
                            <th>Email</th>
                            <th>Активный</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {tab2Users.map(user => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.lastname}</td>
                                <td>{user.firstname}</td>
                                <td>{user.patronymic}</td>
                                <td>{user.email}</td>
                                <td>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={user.is_active}
                                        onChange={() => {
                                        }}
                                    />
                                </td>
                                <td>
                                    <Link to={`/update-user/${user.id}`}>Редактировать</Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className={`tab-pane ${activeTab === 'tab3' ? 'show active' : ''}`}>
                    <table className="table align-middle mb-0 bg-white">
                        <thead className="bg-light">
                        <tr>
                            <th>ID</th>
                            <th>Фамилия</th>
                            <th>Имя</th>
                            <th>Отчество</th>
                            <th>Email</th>
                            <th>Активный</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {tab3Users.map(user => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.lastname}</td>
                                <td>{user.firstname}</td>
                                <td>{user.patronymic}</td>
                                <td>{user.email}</td>
                                <td>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={user.is_active}
                                        onChange={() => {
                                        }}
                                    />
                                </td>
                                <td>
                                    <Link to={`/update-user/${user.id}`}>Редактировать</Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    );
};

export default UsersComponent;