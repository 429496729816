import React from 'react';

const CLIENT_ID = '51801892'; // Замените на ваш Client ID
const REDIRECT_URI = 'https://tortam.ru/'; // Замените на ваш Redirect URI
const VK_AUTH_URL = `https://oauth.vk.com/authorize?client_id=${CLIENT_ID}&display=page&redirect_uri=${REDIRECT_URI}&scope=1026&response_type=token&v=5.131&state=123456`;

const VKAuth: React.FC = () => {
  const handleAuth = () => {
    window.open(VK_AUTH_URL, '_blank');
  };

  return (
    <button onClick={handleAuth}>Войти через ВКонтакте</button>
  );
};

export default VKAuth;
