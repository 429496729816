import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TokenHandlerYandexDirect = () => {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        const params = new URLSearchParams(hash.substring(1));
        const token = params.get('access_token');

        if (token) {
            localStorage.setItem('token', token);
            console.log('Token saved to localStorage:', token);
        }
    }, [location]);

    return (
        <div>
            <h1>Token Handler</h1>
            <p>Check the console to see if the token was saved.</p>
        </div>
    );
};

export default TokenHandlerYandexDirect;
