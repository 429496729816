import React from 'react';
import './CenteredCircle.scss';

interface DividerWithCircleProps {
    text: string;
}
const CenteredCircle: React.FC<DividerWithCircleProps> = ({text}) => {
    return (
        <div className="divider-with-circle">
            <div className="line"></div>
            <div className="circle">
                <span>{text}</span>
            </div>
            <div className="line"></div>
        </div>
    );
};

export default CenteredCircle;
