import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { UserFieldValue } from "types/UserFieldValue";
import { AlertType } from "hooks/useAlert";
import { useAlertContent } from "context/AlertContext";


// Предполагается, что импорты для axios, useMutation, useQueryClient, и других необходимых зависимостей уже выполнены
export const useSubmitUserFieldValues = () => {
  const token = localStorage.getItem("token") || "";
  const queryClient = useQueryClient();
  const { triggerAlert } = useAlertContent(); // Используем useAlert непосредственно внутри хука

  return useMutation(
    (data: UserFieldValue[]) =>
      axios.post(`${process.env.REACT_APP_API_URL}/userfieldvalues`, data, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    {
      onSuccess: (response) => {
        console.log("Success:", response.data);
        queryClient.invalidateQueries(['userFields']);
        queryClient.invalidateQueries(['user']);
        // Поскольку вы хотите использовать triggerAlert с общим сообщением об успехе, обновите следующую строку
        triggerAlert("Пользователь успешно обновлён", "success"); // Используйте общее сообщение о успехе
      },

      onError: (error) => {
        console.error("Error:", error);
        triggerAlert("Произошла ошибка!", "danger");
      },
    }
  );
};
