import MainLayout from "layouts/MainLayout/MainLayout";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Audio, Hourglass, Puff, Watch } from 'react-loader-spinner';






const Reg: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [emailExists, setEmailExists] = useState<boolean>(false);
    const [emailProviderUrl, setEmailProviderUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [blockForm, setBlockForm] = useState<boolean>(true);
    const [welcome, setWelcome] = useState<boolean>(true);
    const [showEmail, setShowEmail] = useState<boolean>(false)
    const [reToken, setReToken] = useState<boolean>(false);



    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const getEmailProviderUrl = (email: string) => {
        const domain = email.split('@')[1]; // Получаем домен из email

        switch (domain) {
            case 'gmail.com':
                return 'https://mail.google.com/';
            case 'mail.ru':
            case 'inbox.ru':
            case 'bk.ru':
            case 'list.ru':
            case 'internet.ru':
            case 'xmail.ru':
                return 'https://account.mail.ru/';
            case 'yahoo.com':
                return 'https://mail.yahoo.com/';
            case 'outlook.com':
                return 'https://login.live.com/';
            case 'yandex.ru':
                return 'https://passport.yandex.ru/';
            case 'ya.ru':
                return 'https://passport.yandex.ru/';
            case 'hotmail.com':
                return 'https://outlook.live.com/';
            // Добавьте другие почтовые сервисы по необходимости
            default:
                return `https://yandex.ru/search/?text=${encodeURIComponent('электронный ящик ' + domain)}`;
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setEmailExists(false);
        setIsLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reg`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email /* другие поля, если требуются */ })
            });

            const responseData = await response.json();

            if (responseData.message === 'email_exists') {
                setEmailExists(true); // Устанавливаем состояние, когда email уже существует
                console.log('Email already exists');
                setIsLoading(false);
                setWelcome(false);
                setReToken(true);
            } else if (!response.ok) {
                throw new Error('Network response was not ok');
            } else {
                console.log('Response data:', responseData);
                setEmailProviderUrl(getEmailProviderUrl(email));
                setIsLoading(false);
                setBlockForm(false);
                setWelcome(false);
                setShowEmail(true);
                // Другая логика в случае успешного ответа
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    const handleTokenRequest = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reg`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email, requestToken: true }) // Добавлен параметр requestToken
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message || 'Error requesting token');
            }

            console.log('Token requested successfully:', responseData);
            if (responseData.message === "token_resent") {
                setEmailProviderUrl(getEmailProviderUrl(email));
            }
            setIsLoading(false);
            setBlockForm(false);
            setShowEmail(true);
            setWelcome(false);
            setReToken(false);


        } catch (error) {
            console.error('Error requesting token:', error);
            // Логика для обработки ошибки
            setIsLoading(false);
        }
    };




    return (
        <MainLayout>
            <div className="">
                <main className="d-flex w-100">
                    <div className="container d-flex flex-column">
                        <div className="row vh-100">
                            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                                <div className="d-table-cell align-middle">
                                    {welcome && (
                                        <div className="text-center mt-4">
                                            <h1 className="h2">Добро пожаловать!</h1>
                                            <p className="lead">
                                                Пожалуйста, введите ваш электронный адрес, чтобы мы могли отправить вам регистрационный токен.
                                            </p>
                                        </div>
                                    )}

                                    {reToken && (
                                        <p className="lead">
                                            Электронный адрес уже зарегистрирован. Если вы забыли свой токен или хотите получить его повторно, пожалуйста, нажмите на кнопку 'Запросить токен повторно'.
                                        </p>
                                    )}

                                    {showEmail && (
                                        <div className="text-center mt-4">
                                            <p className="lead text-success ">
                                                Ваш токен был отправлен на <span className="fw-bold"><a href={emailProviderUrl} target="_blank" rel="noopener noreferrer">{email}</a></span>
                                            </p>
                                        </div>
                                    )}

                                    <div className="card">
                                        <div className="card-body">
                                            <div className="m-sm-3">
                                                <form onSubmit={handleSubmit}>
                                                    {blockForm && (
                                                        <div>
                                                            <div className="mb-3">
                                                                <label className="form-label" htmlFor="email">Email</label>
                                                                <input
                                                                    id="email"
                                                                    className="form-control form-control-lg"
                                                                    type="email"
                                                                    name="email"
                                                                    placeholder="Введите ваш email"
                                                                    value={email}
                                                                    onChange={handleEmailChange}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="text-center mt-3">
                                                                <button type="submit" className="btn btn-lg btn-primary mx-auto get_token w-100" style={{ display: emailExists ? 'none' : 'block' }}>
                                                                    Получить токен
                                                                </button>
                                                                <a href="#" className="btn btn-outline-primary token_again" onClick={handleTokenRequest} style={{ display: emailExists ? 'block' : 'none' }}>
                                                                    Запросить токен повторно
                                                                </a>

                                                            </div>
                                                        </div>
                                                    )}

                                                    {emailProviderUrl && (
                                                        <a href={emailProviderUrl} className="token_resent" target="_blank" rel="noopener noreferrer">
                                                            <div style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}>
                                                                <Puff
                                                                    visible={true}
                                                                    height="25"
                                                                    width="25"
                                                                    color="#4fa94d"
                                                                    ariaLabel="puff-loading"
                                                                />
                                                            </div>
                                                            <span>Перейти в почтовый ящик</span>
                                                        </a>
                                                    )}
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center mt-4">
                                        <p>Уже есть аккаунт? <Link to="/login">Авторизоваться</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div >
            {isLoading && (
                <div className="loader-spinner">
                    <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}

        </MainLayout >
    );
};

export default Reg;