import { useMutation, UseMutationResult } from 'react-query';
import axios from 'axios';
import { RegisterRequest } from "types/RegisterRequest";


// Функция для выполнения HTTP PUT запроса для обновления пользователя
const updateUser = async (user: RegisterRequest): Promise<RegisterRequest> => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/${user.id}`, user);
  return response.data;
};

// Хук для обновления пользователя
export const useUpdateUser = (): UseMutationResult<RegisterRequest, unknown, RegisterRequest, unknown> => {
  return useMutation(updateUser);
};
