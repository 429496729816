import React, { useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

import styles from './Register.module.css';

type RegisterRequest = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string; // Add this line to include confirmPassword in the type
  roleId: number;
};
type Errors = {
  [key in keyof RegisterRequest]?: string;
};



const RegisterForm: React.FC = () => {
  // Тип для определения ключей объекта errors
  type ErrorKeys = keyof typeof errors;


  const [formData, setFormData] = useState<RegisterRequest>({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    roleId: 1,
  });

  const [errors, setErrors] = useState<Errors>({});

  // const [errors, setErrors] = useState<{
  //   firstname?: string;
  //   lastname?: string;
  //   email?: string;
  //   password?: string;
  //   confirmPassword?: string;
  // }>({});

  // ... остальная часть компонента



  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.name as ErrorKeys;
    const { value } = event.target;
    let updatedErrors = { ...errors };

    console.log("Сработало событие handleBlur");

    // Ваша логика валидации
    if (name === 'email' && !value.includes('@')) {
      updatedErrors[name] = 'Введите действительный email';
    } else {
      updatedErrors[name] = '';
    }

    setErrors(updatedErrors);
  };

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...useFormData, [name]: value });
  //   validateField(name, value);
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const name = e.target.name as keyof RegisterRequest; // Явное приведение типа
    const { value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }

    // Вызов validateField для немедленной валидации (если это необходимо)
    // validateField(name, value);
  };



  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let newErrors = { ...errors }; // Create a shallow copy of errors to accumulate new errors
    let formIsValid = true; // Flag to track form validation status

    // Validate all fields before submitting
    Object.keys(formData).forEach(key => {
      const value = formData[key as keyof RegisterRequest];
      const error = validateField(key, value); // Call validateField to check for errors
      if (error) {
        formIsValid = false; // If there's an error, set the flag to false
        newErrors = { ...newErrors, [key]: error }; // Add the error to the newErrors object
      }
    });

    setErrors(newErrors); // Update the state with all errors at once

    if (!formIsValid) {
      console.error('Validation failed:', newErrors);
      return; // Stop form submission if not valid
    }

    // If form is valid, proceed with the API call
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        password: formData.password, // Do not send confirmPassword
        roleId: formData.roleId,
      });
      console.log('Registration successful:', response.data);
      // Redirect or show success message
    } catch (error) {
      console.error('Registration failed:', error);
      // Show error message
    }
  };


  const validateField = (name: string, value: string | number): string => {
    let stringValue = String(value);
    let errorMessage = '';

    if (name === 'email' && !stringValue) {
      errorMessage = 'Email обязателен для заполнения.';
    } else if (name === 'email' && !/\S+@\S+\.\S+/.test(stringValue)) {
      errorMessage = 'Email is invalid.';
    } else if (name === 'password' && !stringValue) {
      errorMessage = 'Требуется пароль.';
    } else if (name === 'confirmPassword' && stringValue !== formData.password) {
      errorMessage = 'Пароли не совпадают.';
    }

    // Return the error message (if any)
    return errorMessage;
  };

  const [showRoleBlock, setShowRoleBlock] = useState(false);

  const changeRole = () => {
    setShowRoleBlock(!showRoleBlock);
  };

  return (
    <main className="d-flex w-100">
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className={styles['registration-container']}>
                <div className="registration-section">
                  <h1 className="h2">Регистрация продавца</h1>
                  <p className="lead">
                    Расширьте свой бизнес с нами!
                  </p>
                </div>
                <div className="button-section" style={{ position: 'relative' }}>
                  <button type="button" className="btn btn-outline-primary btn-sm" onClick={changeRole}>
                    <i className="fas fa-exchange-alt me-2"></i>Сменить роль
                  </button>
                  {showRoleBlock && (
                    <div className={`${styles['role-block']}`}>
                      <Link to="/register/buyer" className="link-offset-3">Покупатель</Link>
                    </div>
                  )}
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="m-sm-3">
                    <form onSubmit={handleSubmit}>
                      <input type="hidden" name="roleId" value="2" />
                      <div className="mb-3">
                        <label className="form-label">Имя</label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="firstname"
                          placeholder="Введите свое имя"
                          value={formData.firstname}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Фамилия</label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="lastname"
                          placeholder="Введите свою фамилию"
                          value={formData.lastname}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                          className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                          type="email"
                          name="email"
                          placeholder="Введите свой адрес электронной почты"
                          value={formData.email}
                          onBlur={handleBlur}
                          onChange={handleInputChange}
                        />
                        <div className={`invalid-feedback ${errors.email ? 'd-block' : 'd-none'}`}>
                          {errors.email}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Пароль</label>
                        <input
                          className={`form-control form-control-lg ${errors.password ? 'is-invalid' : ''}`}
                          type="password"
                          name="password"
                          placeholder="Введите пароль"
                          value={formData.password}
                          onChange={handleInputChange}
                        />
                        {errors.password && <div className="invalid-feedback d-block">{errors.password}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Подтвердите пароль</label>
                        <input
                          className={`form-control form-control-lg ${errors.confirmPassword ? 'is-invalid' : ''}`}
                          type="password"
                          name="confirmPassword"
                          placeholder="Подтвердите пароль"
                          value={formData.confirmPassword}
                          onChange={handleInputChange}
                        />
                        {errors.confirmPassword && <div className="invalid-feedback d-block">{errors.confirmPassword}</div>}
                      </div>
                      <div className="d-grid gap-2 mt-3">
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                        >
                          Зарегистрироваться
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="text-center mb-3">
                У вас уже есть учетная запись? <Link to="/login">Авторизоваться</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RegisterForm;