import {useMutation, UseMutationResult} from "react-query";
import axios, {AxiosResponse} from "axios";
import {User} from "../../types/User";

interface ExchangePayload {
    uuid: string;
    token: string;
}

interface ExchangeResponse {
    user: User;
    token: string;
}

export const useExchangeSilentAuthToken = (): UseMutationResult<AxiosResponse<ExchangeResponse>, any, ExchangePayload> => {
    return useMutation((payload: ExchangePayload) => {
        return axios.post<ExchangeResponse>(
            `${process.env.REACT_APP_API_URL}/auth/exchangeSilentAuthToken`,
            payload
        );
    });
};