import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';

export const useDeleteInfo = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: number) => {
      const token = localStorage.getItem('token');
      return axios.delete(`${process.env.REACT_APP_API_URL}/info/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      onSuccess: () => {
        // После успешного удаления, вы можете инвалидировать и обновить связанные запросы
        queryClient.invalidateQueries('users');
      }
    }
  );
};
