import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Layout from 'components/Layout/Layout';
import { useCreateAttribute } from 'hooks/products/useCreateAttribute';
import { Attribute } from "types/ProductTypes";
import { AttributeValue } from "types/ProductTypes";
import { useCreateAttributeValues } from 'hooks/products/useCreateAttributeValues';
import { Watch } from 'react-loader-spinner';
import { useCategories } from 'hooks/categories/useCategories';
import { Category } from 'types/Category';
import { Link } from 'react-router-dom';


// Схема валидации
const AttributeSchema = Yup.object().shape({
    attributeName: Yup.string()
        .required('Обязательное поле')
        .min(4, 'Должно быть не менее 4 символов'), // Добавляем условие минимальной длины
    attributeType: Yup.string().required('Обязательное поле'),
    sortOrder: Yup.number()
        .required('Обязательное поле')
        .positive('Значение должно быть положительным')
        .integer('Значение должно быть целым числом'),
});


const CreateAttribute: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null); 

    const userId = parseInt(localStorage.getItem('userId') || '0', 10);

    const { mutateAsync: createAttribute, isLoading: isLoadingAttribute, isError: isErrorAttribute } = useCreateAttribute();

    const { mutate: createAttributeValues, isLoading: isLoadingCreateAttributeValues, isError } = useCreateAttributeValues();
    const { data: categories } = useCategories();

    const [selectedAttributeType, setSelectedAttributeType] = useState('');
    const [multipleField, setMultipleField] = useState(false);


    const [initialValues, setInitialValues] = useState<Attribute>({
        attributeName: '',
        attributeType: '',
        isCommon: true,
        userId: userId, // Предполагается, что user может быть не задан
        isActive: true,
        sortOrder: 100,
        creationDate: '',
        changeDate: '',
        categoryIds: [],
    });

    const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                <option value={category.id}>
                    {'-'.repeat(level)} {category.categoryName}
                </option>
                {category.children && renderCategoryOptions(category.children, level + 1)}
            </React.Fragment>
        ));
    };

    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>([{
        id: 0,
        value: '',
        attributeId: 0, // Это значение будет обновлено после создания атрибута
    }]);

    const handleAddField = () => {
        setAttributeValues([...attributeValues, { id: 0, value: '', attributeId: 0 }]);
    };

    const handleRemoveField = (index: number) => {
        setAttributeValues(attributeValues.filter((_, i) => i !== index));
    };


    const handleSubmit = async (values: Attribute, { resetForm }: FormikHelpers<Attribute>) => {
        try {
            console.log(values);

            const createdAttributeId = await createAttribute(values);
            // if (values.attributeType === 'Список') {
            if (multipleField) {
                const updatedAttributeValues = attributeValues.map(av => ({
                    ...av,
                    attributeId: createdAttributeId,
                }));

                console.log(updatedAttributeValues);
                // Отправьте updatedAttributeValues куда нужно, например:
                await createAttributeValues(updatedAttributeValues);

                // Условный сброс формы

                // resetForm();
            }
        } catch (error: any) {
            console.error(error);
            let errorMessage = "Ошибка при создании атрибута";
            if (error.response && error.response.status === 409) {
                errorMessage = "Ошибка при создании атрибута: атрибут с таким именем уже существует";
            }
            setErrorMessage(errorMessage); // Установка сообщения об ошибке в состояние
        }
    };



    return (
        <Layout>
            <Link to="/all-attribute">Все атрибуты</Link>
            <Formik
                initialValues={initialValues}
                validationSchema={AttributeSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Название</th>
                                    <th scope="col">Категория</th>
                                    <th scope="col">Тип поля</th>
                                    <th scope="col">Множественное</th>
                                    <th scope="col">Обычное</th>
                                    <th scope="col">Активно</th>
                                    <th scope="col">Порядок сортировки</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Field name="attributeName" className="form-control form-control-lg" placeholder="Название атрибута" />
                                        {errors.attributeName && touched.attributeName ? (<div className="text-danger">{errors.attributeName}</div>) : null}
                                    </td>

                                    <td>
                                        <Field as="select" name="categoryIds" multiple className="form-control form-control-lg">
                                            <option value="" disabled>Выберите категории</option>
                                            {categories && renderCategoryOptions(categories)}
                                        </Field>
                                    </td>

                                    <td>
                                        <Field
                                            name="attributeType"
                                            as="select"
                                            className="form-select"
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                const { value } = e.target;
                                                setFieldValue("attributeType", value);
                                                setSelectedAttributeType(value); // Обновляем состояние при изменении значения
                                            }}
                                        >
                                            <option value="">Выберите тип</option>
                                            <option value="Строка">Строка</option>
                                            <option value="Число">Число</option>
                                            <option value="Дата">Дата</option>
                                            <option value="Список">Список</option>
                                        </Field>
                                        {errors.attributeType && touched.attributeType ? (<div className="text-danger">{errors.attributeType}</div>) : null}
                                        {
                                            multipleField && (
                                                <a href='#' data-bs-toggle="modal" data-bs-target="#exampleModalCenter">Добавить значения списка</a>
                                            )
                                        }
                                    </td>
                                    <td>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                style={{ borderColor: selectedAttributeType === "" ? "red" : "" }}
                                                type="checkbox"
                                                disabled={selectedAttributeType === ""}
                                                onChange={(e) => setMultipleField(e.target.checked)}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="isCommon"
                                                checked={values.isCommon} // Используем values для получения текущего состояния
                                                onChange={() => setFieldValue('isCommon', !values.isCommon)} // Обновляем через setFieldValue
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="isActive"
                                                checked={values.isActive} // Используем values для получения текущего состояния
                                                onChange={() => setFieldValue('isActive', !values.isActive)} // Обновляем через setFieldValue
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <Field type="number" name="sortOrder" className="form-control form-control-lg" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button type="submit" className="btn btn-success" disabled={isLoadingAttribute}>Создать атрибут</button>
                        {/* {isErrorAttribute && <div className="text-danger">Ошибка при создании атрибута</div>} */}
                        {errorMessage && <div className="text-danger">{errorMessage}</div>}
                    </Form>
                )}
            </Formik>
            {(isLoadingAttribute || isLoadingCreateAttributeValues) && (
                <div className="loader-spinner">
                    <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
            <div className="modal fade" id="exampleModalCenter" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {attributeValues.map((attributeValue, index) => (
                                <div key={index}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={attributeValue.value}
                                        onChange={e => {
                                            const newValues = [...attributeValues];
                                            newValues[index].value = e.target.value;
                                            setAttributeValues(newValues);
                                        }}
                                    />
                                    <button type="button" onClick={() => handleRemoveField(index)}>Удалить</button>
                                </div>
                            ))}
                            <button type="button" onClick={handleAddField}>Добавить значение</button>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Understood</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default CreateAttribute;