import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';
import {Link} from "react-router-dom"; // Подключаем файл стилей

interface GlowingButtonProps {
    text: string;
}

const GlowingButton: React.FC<GlowingButtonProps> = ({ text }) => {
    return (
        <Link to="/create-store" className="glow-on-hover">{text}</Link>
    );
}

GlowingButton.propTypes = {
    text: PropTypes.string.isRequired // Пропс text должен быть строкой и обязателен
};

export default GlowingButton;
