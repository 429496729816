import { Formik, Form } from 'formik';
import { useImageUpload } from 'hooks/images/useImageUpload';
import { ChangeEvent } from 'react';

const ImageUploader: React.FC = () => {
  const { mutate: uploadImage } = useImageUpload();

  const handleSubmit = (values: any) => {
    console.log("handleSubmit");

    if (values.files) {
      const formData = new FormData();
      Array.from(values.files as FileList).forEach((file: File) => {
        formData.append('image', file);
      });
      uploadImage(formData);
    }
  };

  return (
    <Formik
      initialValues={{ files: null }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <input
            name="files"
            type="file"
            multiple
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const files = event.target.files;
              setFieldValue("files", files);  // Устанавливаем файлы в состояние Formik
            }}
          />
          <button type="submit">Upload</button>
        </Form>
      )}
    </Formik>
  );
};

export default ImageUploader;