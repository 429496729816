import { useQuery } from 'react-query';
import axios from 'axios';

export const useFetchAllAttributes = () => {
  const fetchAllAttributes = async (): Promise<any> => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/attribute`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  return useQuery('attributes', fetchAllAttributes);
};
