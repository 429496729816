import { useMutation } from 'react-query';
import axios from 'axios';
import { useAlertContent } from 'context/AlertContext';

export const useUpdateProduct = () => {
    const token = localStorage.getItem("token");
    const { triggerAlert } = useAlertContent();

    return useMutation(({ productId, formData }: { productId: number, formData: FormData }) =>
        axios.put(`${process.env.REACT_APP_API_URL}/products/${productId}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            // Обработка успешного ответа
            // console.log('Продукт успешно обновлен', response.data);
            triggerAlert('Товар успешно обновлён!', 'success');
            return response.data; // Возвращаем данные для дальнейшего использования
        }).catch(error => {
            // Обработка ошибок
            if (axios.isAxiosError(error)) {
                console.error('Произошла ошибка при обновлении продукта:', error.response?.data || error.message);
                // Здесь можно добавить дополнительную логику обработки, например, показать уведомление пользователю
            } else {
                // Неожиданная ошибка
                console.error('Неожиданная ошибка:', error);
            }
            throw error; // Перебрасываем ошибку для обработки в компоненте
        })
    );
};
