import React from 'react';
import { Link } from 'react-router-dom';
import { Category } from 'types/Category';
import Layout from 'components/Layout/Layout';
import { useCategories } from 'hooks/categories/useCategories';
import CategoriesTree from './CategoriesTree';

const CategoryList: React.FC = () => {
    const { data: categories, isLoading: isCategoriesLoading } = useCategories();
    // console.log(categories);
    

    const renderCategoryRows = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                <tr key={category.id}>
                    <td>{category.id}</td>
                    <td>
                        <img src={category.imageURL} alt={category.categoryName} style={{ width: '50px', height: 'auto' }} />
                    </td>
                    <td style={{ paddingLeft: `${level * 20}px` }}>{category.categoryName}</td>
                    <td>
                        <Link to={`/update-category/${category.id}`}>Редактировать</Link>
                    </td>
                </tr>
                {category.children && renderCategoryRows(category.children, level + 1)}
            </React.Fragment>
        ));
    };
    

    if (isCategoriesLoading) return <div>Загрузка...</div>;

    return (
        <Layout>
            <div>

                <Link to="/create-category">Создать новую категорию</Link>
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col">Картинка</th>
                                <th scope="col">Название</th>
                                <th scope="col">Редактировать</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories && renderCategoryRows(categories)}
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    );
};

export default CategoryList;
