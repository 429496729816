import { useQuery } from 'react-query';
import axios from 'axios';
import { Product } from 'types/ProductTypes';

const fetchProductById = async (productId: number): Promise<Product> => {
    const token = localStorage.getItem("token");
    const { data } = await axios.get<Product>(`${process.env.REACT_APP_API_URL}/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return data;
};

export const useProduct = (productId: number | null) => {
    return useQuery<Product, Error>(
        ['product', productId],
        () => fetchProductById(productId!),
        {
            enabled: productId !== null, // Выполнять запрос только если productId не null
        }
    );
};
