import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useAlertContent } from "context/AlertContext";
import { useState } from "react";

const API_URL = `${process.env.REACT_APP_API_URL}/`;

// Функция для отправки запроса на сервер

const updateUserFieldValues = async (
  token: string,
  userId: number,
  data: any
) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/values/${userId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Обработка ошибок, связанных с запросом
      throw new Error("Ошибка при отправке данных на сервер: " + error.message);
    } else {
      // Неожиданная ошибка
      throw new Error("Неизвестная ошибка");
    }
  }
};

export const useUpdateUserFieldValues = (userId: number) => {
  // Добавляем userId как параметр
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { triggerAlert } = useAlertContent(); // Используем useAlert непосредственно внутри хука

  const mutation = useMutation(
    (data: any) => {
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Токен аутентификации не найден");
      }
      return updateUserFieldValues(token, userId, data);
    },

    {
      onMutate: () => {
        setIsLoading(true); // Включаем индикатор загрузки
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["user", userId]);
        // Дополнительные действия после успешной мутации
        triggerAlert("Пользователь успешно обновлён", "success");
      },
    }
  );

  return mutation;
};
