import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';

// export async function fetchOAuthToken(code: string, navigate: NavigateFunction): Promise<void> {
//   try {
//     console.log("000");
//     const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/yandex/token`, { code });
//     console.log(123);
//     console.log(response.data);
//     console.log(456);
//
//     // Проверка, содержит ли ответ данные о пользователе и токен
//     if (response.data.token && response.data.user) {
//       localStorage.setItem('token', response.data.token);
//       localStorage.setItem('userId', response.data.user.id.toString());
//       navigate('/index');
//     } else {
//       throw new Error('Invalid response structure');
//     }
//   } catch (error) {
//     // if (axios.isAxiosError(error) && error.response) {
//     //   console.error('Error fetching OAuth token:', error.response.data);
//     //   // Проверка, содержит ли ответ данные о пользователе и токен даже при ошибке
//     //   if (error.response.data.token && error.response.data.user) {
//     //     localStorage.setItem('token', error.response.data.token);
//     //     localStorage.setItem('userId', error.response.data.user.id.toString());
//     //     navigate('/index');
//     //   } else {
//     //     throw new Error(error.response.data.error_description || 'Error fetching OAuth token');
//     //   }
//     // } else {
//     //   throw error;
//     // }
//   }
// }

export async function fetchOAuthToken(code: string, navigate: NavigateFunction): Promise<void> {
  try {
    console.log("000");
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/yandex/token`, { code });
    console.log(123);
    console.log(response.data);
    console.log(456);

    // Проверка, содержит ли ответ данные о пользователе и токен
    if (response.data.token && response.data.user) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userId', response.data.user.id.toString());
      navigate('/index');
    } else if (response.data.isProvider) {
      // Обработка случая, если пользователь уже зарегистрирован через другого провайдера
      console.log('isProvider:', response.data.isProvider);
      // navigate('/login', { state: { isProvider: response.data.isProvider } });
      navigate('/login', { state: { isProvider: response.data.isProvider, details: response.data } });
    } else {
      throw new Error('Invalid response structure');
    }
  } catch (error) {
    console.error('Error fetching OAuth token:', error);
    navigate('/error');
  }
}