import Layout from "components/Layout/Layout";
import React, { useState } from "react";
import { useGetAllUserFields } from "hooks/userFields/useGetAllUserFields";
import { UserField } from 'types/UserFieldTypes';

import { Link } from "react-router-dom";

const GetAllFieldComponent: React.FC = () => {
    const { data: userFields, isLoading, isError, error, refetch } = useGetAllUserFields();

    const [activeTab, setActiveTab] = useState('tabAll');

    if (isLoading) return <div>Loading...</div>;
    if (isError && error) return <div>An error occurred: {error.message}</div>;



    // Фильтруем пользователей для каждой вкладки
    // Фильтрация пользовательских полей для каждой вкладки
    const commonUserFields = userFields?.filter(userField => userField.roleId === 4) || [];

    const tab1userFields = (userFields?.filter(userField => userField.roleId === 1) || []).concat(commonUserFields);
    const tab2userFields = (userFields?.filter(userField => userField.roleId === 2) || []).concat(commonUserFields);
    const tab3userFields = (userFields?.filter(userField => userField.roleId === 3) || []).concat(commonUserFields);


    // Обработчик клика по вкладкам
    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    return (
        <Layout>
            <div>
                <h1>Список дополнительных полей пользователей</h1>
                <Link to="/create-field-user">Создать новое поле</Link>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'tabAll' ? 'active' : ''}`}
                            onClick={() => handleTabClick('tabAll')}
                        >
                            Все поля
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                            onClick={() => handleTabClick('tab1')}
                        >
                            Покупатели
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
                            onClick={() => handleTabClick('tab2')}
                        >
                            Продавцы
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`}
                            onClick={() => handleTabClick('tab3')}
                        >
                            Администраторы
                        </a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div className={`tab-pane ${activeTab === 'tabAll' ? 'show active' : ''}`}>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Роль</th>
                                        <th scope="col">Значение поля</th>
                                        <th scope="col">Тип поля</th>
                                        <th scope="col">Редактировать</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userFields?.map((field: UserField, index: number) => (
                                        <React.Fragment key={field.id || index}>
                                            <tr>
                                                <th scope="row">{field.id}</th>
                                                <td>{field.roleId}</td>
                                                <td>{field.name}</td>
                                                <td>{field.type}</td>
                                                <td>{field.sortOrder}</td>
                                                <td><Link to={`/update-field-user/${field.id}`}>Редактировать</Link></td>
                                                <td>
                                                    <a href="#"
                                                        className="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                                                    >
                                                        Удалить
                                                    </a>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={`tab-pane ${activeTab === 'tab1' ? 'show active' : ''}`}>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Роль</th>
                                        <th scope="col">Значение поля</th>
                                        <th scope="col">Тип поля</th>
                                        <th scope="col">Редактировать</th>
                                        <th scope="col">Удалить</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tab1userFields.map((field, index) => (
                                        <tr key={field.id || index}>
                                            <th scope="row">{field.id}</th>
                                            <td>{field.roleId}</td>
                                            <td>{field.name}</td>
                                            <td>{field.type}</td>
                                            <td>
                                                <Link to={`/update-field-user/${field.id}`}>Редактировать</Link>
                                            </td>
                                            <td>
                                                <a href="#"
                                                    className="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                                                >
                                                    Удалить
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={`tab-pane ${activeTab === 'tab2' ? 'show active' : ''}`}>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Роль</th>
                                        <th scope="col">Значение поля</th>
                                        <th scope="col">Тип поля</th>
                                        <th scope="col">Редактировать</th>
                                        <th scope="col">Удалить</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tab2userFields.map((field, index) => (
                                        <tr key={field.id || index}>
                                            <th scope="row">{field.id}</th>
                                            <td>{field.roleId}</td>
                                            <td>{field.name}</td>
                                            <td>{field.type}</td>
                                            <td>
                                                <Link to={`/update-field-user/${field.id}`}>Редактировать</Link>
                                            </td>
                                            <td>
                                                <a href="#"
                                                    className="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                                                >
                                                    Удалить
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={`tab-pane ${activeTab === 'tab3' ? 'show active' : ''}`}>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Роль</th>
                                        <th scope="col">Значение поля</th>
                                        <th scope="col">Тип поля</th>
                                        <th scope="col">Редактировать</th>
                                        <th scope="col">Удалить</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tab3userFields.map((field, index) => (
                                        <tr key={field.id || index}>
                                            <th scope="row">{field.id}</th>
                                            <td>{field.roleId}</td>
                                            <td>{field.name}</td>
                                            <td>{field.type}</td>
                                            <td>
                                                <Link to={`/update-field-user/${field.id}`}>Редактировать</Link>
                                            </td>
                                            <td>
                                                <a href="#"
                                                    className="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                                                >
                                                    Удалить
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* Добавьте дополнительные div для других вкладок */}
                </div>
            </div>
        </Layout>
    );
};

export default GetAllFieldComponent;