import { SET_USER_ID, UserActionTypes } from '../actions/userActions';

interface UserState {
  userId: string;
}

const initialState: UserState = {
  userId: localStorage.getItem('userId') || '',
};

export const userReducer = (state = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    default:
      return state;
  }
};
