import React from 'react';
import {useArticles} from "../../hooks/articles/useArticles";
import Layout from "../Layout/Layout";
import {Link} from "react-router-dom";
import {Filling} from "../../types/Filling";
import {Article} from "../../types/Article";

const ArticlesList: React.FC = () => {
    const {data: articles, error, isLoading} = useArticles();
    console.log(articles)

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {(error as Error).message}</div>;
    }

    return (
        <Layout>
            <div>
                <h2>Список статей</h2>
                <Link to="/create-article">Создать новую статью</Link>
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Img</th>
                        <th>ID</th>
                        <th>Название</th>

                        <th scope="col"></th> {/* Новый столбец для действий */}
                    </tr>
                    </thead>
                    <tbody>
                    {articles?.map((article: Article) => (
                        <tr key={article.id}>
                            <td><img className="allFillingImg" src={article.imageUrl} alt={article.name} /></td>
                            <td>{article.id}</td>
                            <td>{article.name}</td>
                            <td><Link to={`/update-article/${article.id}`}>Редактировать</Link></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </Layout>
    );
};

export default ArticlesList;
