import { useQuery } from 'react-query';
import axios from 'axios';
import { Category } from 'types/Category';

// Функция для преобразования списка категорий в иерархическую структуру
function transformCategoriesToTree(categories: Category[]): Category[] {
    const categoryMap: { [key: number]: Category & { children: Category[] } } = {};
  
    // Сначала инициализируем каждую категорию в map
    categories.forEach(category => {
      if (category.id !== undefined) { // Убедитесь, что id определено
        categoryMap[category.id] = { ...category, children: [] };
      }
    });
  
    // Теперь создаем структуру дерева
    const tree: Category[] = [];
    categories.forEach(category => {
      if (category.parentID !== undefined && category.parentID !== null) {
        // Проверяем, существует ли родительская категория в map
        if (categoryMap[category.parentID]) {
          // Проверяем, что id текущей категории определено перед использованием его в качестве ключа
          if (category.id !== undefined) {
            categoryMap[category.parentID].children.push(categoryMap[category.id]);
          }
        }
      } else if (category.id !== undefined) { // Категории без родителей добавляем в корень дерева
        tree.push(categoryMap[category.id]);
      }
    });
  
    return tree;
  }
  

// Хук для получения всех категорий
export const useCategories = () => {
    return useQuery('categories', async () => {
        const { data } = await axios.get<Category[]>(`${process.env.REACT_APP_API_URL}/categories/all`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return transformCategoriesToTree(data);
    });
};