import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

interface Role {
    id: number;
    name: string;
}

export const useFetchRoles = (): UseQueryResult<Role[], Error> => {
  return useQuery<Role[], Error>('roles', async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get<Role[]>(`${process.env.REACT_APP_API_URL}/roles`, config);
    return data;
  });
};
