
import React, { ChangeEvent, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useCreateCategory } from 'hooks/categories/useCreateCategory';
import { Category } from 'types/Category';
import { ReactComponent as CrossIcon } from 'img/svg/cross-svgrepo-com.svg';
import Layout from 'components/Layout/Layout';
import { useCategories } from 'hooks/categories/useCategories';
import {Link, useNavigate} from 'react-router-dom';
import {generateSlug} from "../../utils/generateSlug";


interface CategoryFormValues {
    categoryName: string;
    slug: string;
    seoTitle: string;
    seoDescription: string;
    isCommon: boolean;
    isActive: boolean;
    parentID?: number;
    imageURL?: string;
    description?: string;
    imageFile?: File | null; // Добавляем поле для файла
}

const initialValues: CategoryFormValues = {
    categoryName: '',
    slug: '',
    seoTitle: '',
    seoDescription: '',
    isCommon: false,
    isActive: true,
    parentID: undefined,
    imageURL: '',
    description: '',
    imageFile: null, // Инициализируем как null
};



const CreateCategoryForm: React.FC = () => {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId') || '';

    const { data: categories, isLoading: isCategoriesLoading } = useCategories();
    // Функция для рекурсивного создания элементов option с отступами для дерева категорий
    // console.log("categories");
    // console.dir(categories);

    const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                <option value={category.id}>
                    {'-'.repeat(level)} {category.categoryName}
                </option>
                {category.children && renderCategoryOptions(category.children, level + 1)}
            </React.Fragment>
        ));
    };


    const { mutate: createCategory, isLoading, error } = useCreateCategory();
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleSubmit = (values: CategoryFormValues) => {
        const formData = new FormData();
        const categoryData = {
            userId: userId,
            categoryName: values.categoryName,
            slug: values.slug,
            seoTitle: values.seoTitle,
            seoDescription: values.seoDescription,
            isCommon: values.isCommon,
            isActive: values.isActive,
            parentID: values.parentID,
            imageURL: values.imageURL,
            description: values.description,
        };
        formData.append('category', JSON.stringify(categoryData));

        if (values.imageFile) {
            formData.append('image', values.imageFile);
        }

        console.dir(categoryData);
        for (let [key, value] of formData.entries()) {
            console.log("key, value");
            console.log(key, value);
            console.log("key, value2");
        }

        createCategory(formData, {
            onSuccess: (categoryId) => {
                navigate(`/update-category/${categoryId}`);
            },
        });
    };

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
        setPreviewImage(file ? URL.createObjectURL(file) : null);
    };

    const handleRemoveImage = () => {
        setPreviewImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Очищаем значение input
        }
    };


    return (
        <Layout>
            <div>
                <h2>Создать категорию</h2>
                <Link to="/show-category">Все категории</Link>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {({ setFieldValue, isSubmitting, handleChange }) => (
                        <>
                            <Form>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td className="col-4">Название категории</td>
                                            <td className="col-8">
                                                <Field
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    name="categoryName"
                                                    placeholder="Название"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleChange(e);
                                                        const {value} = e.target;
                                                        setFieldValue('categoryName', value);
                                                        setFieldValue('slug', generateSlug(value));
                                                    }}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4">Slug</td>
                                            <td className="col-8">
                                                <Field
                                                    className={`form-control form-control-lg`}
                                                    type="text"
                                                    name="slug"
                                                    placeholder="Slug"
                                                />
                                                <ErrorMessage name="slug" component="div" className="error"/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4">Seo Title</td>
                                            <td className="col-8">
                                                <Field
                                                    className={`form-control form-control-lg`}
                                                    type="text"
                                                    name="seoTitle"
                                                    placeholder="seoTitle"
                                                />
                                                <ErrorMessage name="slug" component="div" className="error"/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4">Seo Description</td>
                                            <td className="col-8">
                                                <Field
                                                    className={`form-control form-control-lg`}
                                                    type="text"
                                                    name="seoDescription"
                                                    placeholder="seoDescription"
                                                />
                                                <ErrorMessage name="slug" component="div" className="error"/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="col-4">Общая категория</td>
                                            <td className="col-8">
                                                <Field
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="isCommon"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4">Активная</td>
                                            <td className="col-8">
                                                <Field
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="isActive"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4">ID родительской категории</td>
                                            <td className="col-8">
                                                <Field as="select" name="parentID" className="form-control form-control-lg">
                                                    <option value="">Выберите категорию</option>
                                                    {categories && renderCategoryOptions(categories)}
                                                </Field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4">URL изображения</td>
                                            <td className="col-8">
                                                {/* <input
                                                    id="file"
                                                    name="imageFile"
                                                    type="file"
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue("imageFile", event.currentTarget.files ? event.currentTarget.files[0] : null);
                                                    }}
                                                    className="form-control"
                                                /> */}
                                                <div>

                                                    {previewImage && (
                                                        <div className="d-flex">
                                                            <img src={previewImage} alt="Preview" style={{ width: '100px', height: '100px' }} />
                                                            <a onClick={handleRemoveImage}>
                                                                <CrossIcon className="cross" />
                                                            </a>
                                                        </div>
                                                    )}
                                                    <input
                                                        id="file"
                                                        name="imageFile"
                                                        type="file"
                                                        ref={fileInputRef} // Присваиваем ссылку элементу
                                                        onChange={(event) => {
                                                            handleImageChange(event);
                                                            setFieldValue("imageFile", event.currentTarget.files ? event.currentTarget.files[0] : null);
                                                        }}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="col-4">Описание</td>
                                            <td className="col-8">
                                                <Field
                                                    className="form-control form-control-lg"
                                                    as="textarea"
                                                    name="description"
                                                    placeholder="Описание"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button
                                    type="submit"
                                    className="btn btn-primary">
                                    Создать
                                </button>
                            </Form>

                            {error && <div>Произошла ошибка при создании категории: {error.toString()}</div>}
                        </>
                    )}
                </Formik>
            </div >
        </Layout>
    );
};

export default CreateCategoryForm;