// src/hooks/useFetchAttribute.ts
import { useQuery } from 'react-query';
import axios from 'axios';
import { Attribute } from "types/ProductTypes"; // Импорт интерфейса Attribute

export const useGetAttributeId = (id: string) => {
  return useQuery<Attribute, Error>(['attribute', id], async () => {
    const { data } = await axios.get<Attribute>(`${process.env.REACT_APP_API_URL}/attribute/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return data;
  });
};
