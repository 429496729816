import { useMutation } from "react-query";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom"; // Для редиректа
import { Attribute } from "types/ProductTypes";
import { useAlertContent } from "context/AlertContext";

const createAttribute = async (attribute: Attribute): Promise<number> => {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("No token found");
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/attribute`,
    attribute,
    config
  );
  return response.data.id; // Возвращаем ID созданного пользователя
};

export const useCreateAttribute = () => {
  const navigate = useNavigate(); // Хук для навигации
  const { triggerAlert } = useAlertContent();

  return useMutation<number, AxiosError, Attribute>(createAttribute, {
    onSuccess: (id) => {
      triggerAlert("Атрибут успешно создан!", "success");
      navigate(`/update-attribute/${id}`);
    },
  });
};
