import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { User } from 'types/User';


export const useFetchUsers = (): UseQueryResult<User[], Error> => {
  return useQuery<User[], Error>('users', async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get<User[]>(`${process.env.REACT_APP_API_URL}/users`, config);
    return data;
  });
};
