import { useMutation } from 'react-query';
import axios from 'axios';
import {Article} from "../../types/Article";

const createArticle = async (formData: FormData) => {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/articles`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

export const useSubmitArticle = () => {
    return useMutation(createArticle);
};