import AlertMessage from "components/AlertMessage";
import Layout from "components/Layout/Layout";
import { useGetAllRoles } from "hooks/role/useGetAllRoles";
import useAlert from "hooks/useAlert";
import { useUpdateUserField } from "hooks/userFields/useUpdateUserField";
import { useUserField } from "hooks/userFields/useUserField";
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { UserField } from "types/UserFieldTypes";


const UpdateUserFieldComponent: React.FC = () => {
    const { id } = useParams<{ id: string }>();


    // Хуки для получения данных
    const { data, isLoading, error } = useUserField(id);
    console.log('data');
    console.dir(data);
    const { data: rolesData, isLoading: isLoadingRoles, error: errorRoles } = useGetAllRoles();

    // Хук для обновления данных
    const { mutate: updateUserField, isLoading: isUpdating, isError: isUpdateError } = useUpdateUserField();

    // Локальные состояния
    const [selectedRoleId, setSelectedRoleId] = useState<string>('');
    const [selectedFieldType, setSelectedFieldType] = useState<string>('');
    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(true); // Новое состояние для is_active
    const [isRequired, setIsRequired] = useState(false); // Новое состояние для is_required
    const [sortOrder, setSortOrder] = useState('');

    const [selectInputs, setSelectInputs] = useState(['']);
    const { alertData, showAlert, triggerAlert, resetAlert } = useAlert();

    // Инициализация состояний при получении данных
    useEffect(() => {
        if (data) {
            setSelectedFieldType(data.type);
            setSelectedRoleId(data.roleId.toString());
            setName(data.name);
            setIsActive(data.isActive);
            setIsRequired(data.isRequired);
            setSortOrder(data.sortOrder ? data.sortOrder.toString() : '0');
            if (data.type === 'Список' && data.typeValue) {
                setSelectInputs(data.typeValue.split(', '));
            } else {
                setSelectInputs(['']);
            }
        }
    }, [data]);

    // Обработчики событий
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
    const handleSortOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => setSortOrder(event.target.value);
    const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedFieldType(event.target.value);
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedRoleId(event.target.value);
    // Обработчик изменений для isActive
    const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    };

    // Обработчик изменений для isRequired
    const handleIsRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsRequired(event.target.checked);
    };

    const handleAddInput = () => {
        setSelectInputs([...selectInputs, '']);
    };

    if (!id) return <div>Invalid ID</div>;



    // Функция для обновления состояния дополнительных полей
    const handleInputChangeAddSelect = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSelectInputs = [...selectInputs];
        newSelectInputs[index] = e.target.value;
        setSelectInputs(newSelectInputs);
    };

    // const handleUpdate = () => {
    //     const useFormData: UserField = {
    //         id: parseInt(id),
    //         name,
    //         type: selectedFieldType,
    //         typeValue: '',
    //         isActive: isActive,   // Добавление is_active в useFormData
    //         isRequired: isRequired, // Добавление is_required в useFormData
    //         roleId: parseInt(selectedRoleId),
    //         sortOrder: parseInt(sortOrder),
    //     };

    //     console.dir("useFormData");
    //     console.dir(useFormData);

    //     updateUserField({ id, data: useFormData });
    // };

    // Обновление обработчика отправки формы
    // const handleUpdate = () => {
    //     const useFormData: UserField = {
    //         id: parseInt(id),
    //         name,
    //         type: selectedFieldType,
    //         typeValue: '',
    //         isActive: isActive,   // Добавление is_active в useFormData
    //         isRequired: isRequired, // Добавление is_required в useFormData
    //         roleId: parseInt(selectedRoleId),
    //         sortOrder: parseInt(sortOrder),
    //     };

    //     const updatedFormData: UserField = {
    //         ...useFormData,
    //         typeValue: selectedFieldType === 'Список' ? selectInputs.filter(input => input.trim() !== '').join(', ') : ''
    //     };

    //     console.log("Обновленные данные формы:", updatedFormData);
    //     updateUserField({ id, data: updatedFormData });
    // };

    const handleUpdate = () => {
        // Создание начальных данных формы
        const formData: UserField = {
            id: parseInt(id),
            name,
            type: selectedFieldType,
            isActive,   // isActive уже является boolean, нет необходимости использовать дополнительное присваивание
            isRequired, // Аналогично для isRequired
            roleId: parseInt(selectedRoleId),
            sortOrder: parseInt(sortOrder),
            typeValue: '' // Инициализируем пустой строкой
        };

        // Если тип поля - 'Список', обновляем typeValue
        if (selectedFieldType === 'Список') {
            formData.typeValue = selectInputs.filter(input => input.trim() !== '').join(', ');
        }

        console.log("Обновленные данные формы:", formData);
        updateUserField({ id, data: formData });
        triggerAlert('Успешно обновлён!', 'success');
    };


    // Рендеринг и обработка состояний
    if (isLoading || isLoadingRoles || isUpdating) return <div>Loading...</div>;
    if (error || errorRoles || isUpdateError) return <div>Error occurred</div>;

    if (!data || !rolesData) return <div>Data is not available</div>;

    const fieldTypes = ['Строка', 'Число', 'Дата', 'Список'];

    return (
        <Layout>
            <div>
                <h1>Список дополнительных полей пользователей</h1>
                <div className="table-responsive">
                    <div className="all-field-user">
                        <Link to="/show-field-user">Список всех полей пользователей</Link>
                    </div>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col">Название</th>
                                <th scope="col">Тип поля</th>
                                <th scope="col">Роль</th>
                                <th scope="col">Порядок сортировки</th>
                                <th scope="col">Активно</th>
                                <th scope="col">Обязательно</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row">{data.id}</td>
                                <td>
                                    <div className="mb-3">
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            name="key"
                                            value={name || (data ? data.name : '')}
                                            onChange={handleNameChange}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mb-3">
                                        <select
                                            className="form-select"
                                            name="fieldTypeSelect"
                                            value={selectedFieldType}
                                            onChange={handleTypeChange}
                                        >
                                            {fieldTypes.map(type => (
                                                <option key={type} value={type}>
                                                    {type}
                                                </option>
                                            ))}
                                        </select>
                                        {selectedFieldType === 'Список' && (
                                            <div className='input-select position-absolute'>
                                                {selectInputs.map((input, index) => (
                                                    <input
                                                        key={index}
                                                        className="form-control form-control-lg select"
                                                        type="text"
                                                        placeholder={`Значение списка ${index + 1}`}
                                                        value={input}
                                                        onChange={handleInputChangeAddSelect(index)}
                                                    />
                                                ))}
                                                <svg onClick={handleAddInput} width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4 12H20M12 4V20" stroke="#545e69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <div className="mb-3">
                                        <select
                                            className="form-select"
                                            name="roleSelect"
                                            value={selectedRoleId}
                                            onChange={handleChange}
                                        >
                                            {rolesData && rolesData.map(role => (
                                                <option key={role.id} value={role.id}>
                                                    {role.name} {/* Или другое свойство, описывающее роль */}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div className="mb-3">
                                        <input
                                            className="form-control form-control-lg"
                                            type="number"
                                            name="sortOrder"
                                            value={sortOrder}
                                            onChange={handleSortOrderChange}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mb-3 form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="isActiveSwitch"
                                            checked={isActive}
                                            onChange={handleIsActiveChange}
                                        />
                                        <label className="form-check-label" htmlFor="isActiveSwitch">Активно</label>
                                    </div>
                                </td>
                                <td>
                                    <div className="mb-3 form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="isRequiredSwitch"
                                            checked={isRequired}
                                            onChange={handleIsRequiredChange}
                                        />
                                        <label className="form-check-label" htmlFor="isRequiredSwitch">Обязательно</label>
                                    </div>
                                </td>
                                <td>
                                    <div className="mb-3">
                                        <button onClick={handleUpdate} className="btn btn-primary">
                                            Обновить
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <AlertMessage
                message={alertData.message}
                type={alertData.type}
                showAlert={showAlert}
                onClose={resetAlert}
            /> */}
        </Layout>
    );
};

export default UpdateUserFieldComponent;

