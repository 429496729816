// import React from 'react';
// import { Navigate } from 'react-router-dom';

// interface ProtectedRouteProps {
//   children: JSX.Element;
// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
//   const token = localStorage.getItem('token');

//   if (!token) {
//     // Если токена нет, редирект на страницу входа
//     return <Navigate to="/login" />;
//   }

//   return children;
// };

// export default ProtectedRoute;


// import { Outlet, Navigate } from 'react-router-dom';

// const ProtectedRoute: React.FC = () => {
//   const token = localStorage.getItem('token');

//   if (!token) {
//     // Если нет токена, редирект на страницу входа
//     return <Navigate to="/login" />;
//   }

//   // Если токен есть, отображаем дочерние маршруты
//   return <Outlet />;
// };

// export default ProtectedRoute;

import React, { useEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  exp: number;
  iat: number;
  // Другие поля в вашем токене
}

const ProtectedRoute: React.FC = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        const decodedToken = jwtDecode<DecodedToken>(token);
        const isTokenExpired = decodedToken.exp * 1000 < new Date().getTime();

        if (isTokenExpired) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 60000);
    checkTokenExpiration();

    return () => clearInterval(intervalId);
  }, [token, navigate]);

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
