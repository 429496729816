import React, { useState } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const backupDatabase = async (): Promise<void> => {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/backup-database`, null, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const BackupDatabase: React.FC = () => {
    const [progress, setProgress] = useState<number>(0);

    const mutation = useMutation(backupDatabase, {
        onMutate: () => {
            setProgress(0);
        },
        onSuccess: () => {
            setProgress(100);
            toast.success("Database backup created successfully! ");
        },
        onError: () => {
            toast.error("Failed to create the database backup.");
        }
    });

    const handleBackup = () => {
        mutation.mutate();
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prevProgress + 1;
            });
        }, 1800); // Adjust timing as necessary
    };

    return (
        <div>
            <button onClick={handleBackup} disabled={mutation.isLoading}>Восстановить Backup</button>
            <div style={{ width: 200, height: 200 }}>
                <CircularProgressbar value={progress} text={`${progress}%`} />
            </div>
            <ToastContainer />
        </div>
    );
};
