import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

import { UserField } from 'types/UserFieldTypes';


export const useGetAllUserFields = (): UseQueryResult<UserField[], Error> => {
  return useQuery<UserField[], Error>('userFields', async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const { data } = await axios.get<UserField[]>(`${process.env.REACT_APP_API_URL}/userfields`, config);
    return data;
  }, {
    // Здесь можно добавить дополнительные настройки, например, staleTime, cacheTime и т.д.
  });
};
