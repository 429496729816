import React from 'react';
import { useFetchInfo } from 'hooks/Info/useFetchInfo';
import { IField } from 'types/Info';
import { Link } from 'react-router-dom';
import { useDeleteInfo } from 'hooks/Info/useDeleteInfo';
import Layout from 'components/Layout/Layout';

const UserFieldAdder: React.FC = () => {


    const { data: infoData, isLoading, isError, error, refetch } = useFetchInfo();

    const { mutate: deleteInfo } = useDeleteInfo();

    const handleDelete = (id: number) => {
        deleteInfo(id, {
            onSuccess: () => {
                // После успешного удаления, вызываем refetch для обновления данных
                refetch();
            },
            onError: (error) => {
                // Обработка ошибки при удалении
                console.error('Ошибка при удалении:', error);
            }
        });
    };

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error?.message}</div>;
    return (
        <Layout>
            <Link to="/create-info">Создать новое поле</Link>
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Название поля</th>
                            <th scope="col">Значение поля</th>
                            <th scope="col">Роль</th>
                            <th scope="col">Пользователь</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {infoData?.map((field: IField, index: number) => (
                            <React.Fragment key={field.id || index}>
                                <tr>
                                    <th scope="row">{field.id}</th>
                                    <td>{field.key}</td>
                                    <td>{field.value}</td>
                                    <td>{field.role.name}</td>
                                    <td>{field.userInfo.email}</td>
                                    <td><Link to={`/update-info/${field.id}`}>Редактировать</Link></td>
                                    <td>
                                        <a href="#"
                                            className="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                                            onClick={(e) => {
                                                e.preventDefault(); // Предотвратить стандартное поведение ссылки
                                                handleDelete(field.id as number);
                                            }}>
                                            Удалить
                                        </a>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </Layout>
    );
};

export default UserFieldAdder;