import React from 'react';
import {RestoreDatabase} from './RestoreDatabase';
import {BackupDatabase} from './BackupDatabase';
import Layout from "../Layout/Layout";

const Database: React.FC = () => {
    return (
        <Layout>
            <div>
                <h1>Database Management</h1>
                <RestoreDatabase/>
                <BackupDatabase/>
            </div>
        </Layout>
    );
};

export default Database;
