import React, { useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useExchangeSilentAuthToken} from "../../hooks/vkid/useExchangeSilentAuthToken";
import {User} from "../../types/User";

interface ExchangeResponse {
    user: User;
    token: string;
    isProvider?: string;
}

const RedirectHandler: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { mutate, data, error } = useExchangeSilentAuthToken();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const payload = params.get('payload');
        const state = params.get('state');

        if (payload) {
            try {
                const decodedPayload = JSON.parse(decodeURIComponent(payload));
                console.log('State:', state);
                mutate({ uuid: decodedPayload.uuid, token: decodedPayload.token });
            } catch (error) {
                console.error('Error parsing payload:', error);
            }
        } else {
            console.error('No payload found in URL parameters');
            navigate('/error');
        }
    }, [navigate, mutate]);

    useEffect(() => {
        if (data) {
            console.log('Exchange successful:', data);
            // const responseData: ExchangeResponse = data.data;
            // localStorage.setItem('token', responseData.token);
            // localStorage.setItem('userId', responseData.user.id.toString());
            // navigate('/index');
            const responseData: ExchangeResponse = data.data;

            if (responseData.isProvider) {
                // Действие, если есть responseData.isProvider
                console.log('isProvider:', responseData.isProvider);
                // navigate('/login', { state: { isProvider: responseData.isProvider } });
                navigate('/login', { state: { isProvider: responseData.isProvider, details: responseData.isProvider } });
                // Ваше действие здесь
            } else if (responseData.token) {
                // Действие, если есть responseData.token
                console.log('token:', responseData.token);
                localStorage.setItem('token', responseData.token);
                localStorage.setItem('userId', responseData.user.id.toString());
                navigate('/index');
            } else {
                // Действие, если ни одно из условий не выполнено
                console.log('Neither isProvider nor token found in response.');
                // Ваше действие здесь
            }
        }
        if (error) {
            console.error('Exchange failed:', error);
            navigate('/error');
        }
    }, [data, error, navigate]);

    return (
        <div>Loading...</div>
    );
};

export default RedirectHandler;