import React, { useEffect, useState } from 'react';
import { useGetAttributeId } from 'hooks/products/useGetAttributeId';
import { Category } from 'types/Category';
import { Link, useParams } from 'react-router-dom';
import Layout from 'components/Layout/Layout';
import { Field, FieldArray, Form, Formik } from "formik";
import { useCategories } from 'hooks/categories/useCategories';
import { useUpdateAttribute } from 'hooks/attributes/useUpdateAttribute';

const UpdateAttributeComponent: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { data: attribute, isLoading, error } = useGetAttributeId(id || '');
    console.log("attribute");
    console.dir(attribute);

    const { data: categories } = useCategories();

    const { mutate: updateAttribute, isLoading: isUpdating } = useUpdateAttribute();


    // Состояние для управления видимостью блока с таблицей
    const [isChecked, setIsChecked] = useState<boolean>(true);

    useEffect(() => {
        if (attribute && Array.isArray(attribute.attributeValues) && attribute.attributeValues.length > 0) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }, [attribute]);

    // Функция для изменения состояния в зависимости от значения чекбокса
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };



    const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                <option value={category.id}>
                    {'-'.repeat(level)} {category.categoryName}
                </option>
                {category.children && renderCategoryOptions(category.children, level + 1)}
            </React.Fragment>
        ));
    };



    if (isLoading) return <div>Загрузка...</div>;
    if (error) return <div>Ошибка: {error.message}</div>;

    // Инициализация начальных значений формы
    const initialValues = {
        attributeName: attribute?.attributeName || '',
        attributeType: attribute?.attributeType || '',
        sortOrder: attribute?.sortOrder || 0,
        isCommon: attribute?.isCommon || true,
        // userId: userId,
        isActive: attribute?.isActive || true,
        creationDate: attribute?.creationDate || '',
        changeDate: attribute?.changeDate || '',
        categoryIds: attribute?.categoryIds,
        attributeValues: attribute?.attributeValues?.map(av => ({
            id: av.id,
            value: av.value,
            attributeId: av.attributeId
        })) || [],
    };


    return (
        <Layout>
            <div>
                <h2>Детали Атрибута</h2>
                <Link to="/all-attribute">Все атрибуты</Link>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        // Здесь обработка отправки формы
                        // console.log(values);

                        // Преобразование значений формы в формат, ожидаемый сервером
                        const updateData = {
                            attributeData: {
                                id: attribute?.id,
                                attributeName: values.attributeName,
                                attributeType: values.attributeType,
                                sortOrder: values.sortOrder,
                                isCommon: values.isCommon,
                                isActive: values.isActive,
                                creationDate: values.creationDate,
                                changeDate: values.changeDate,
                            },
                            attributeValues: values.attributeValues,
                            categoryIds: values.categoryIds || [],
                        };

                        console.log("updateData");
                        console.log(updateData.attributeValues);

                        updateAttribute(updateData, {
                            onSuccess: () => {
                                // Обновление даты изменения на текущую дату и время
                                const newChangeDate = new Date().toISOString(); // Получаем текущую дату в формате ISO
                                actions.setFieldValue('changeDate', newChangeDate);
                                // Здесь можно добавить дополнительные действия после успешного обновления
                            },
                            onError: () => {
                                actions.setSubmitting(false); // В случае ошибки также отключить состояние отправки формы
                                // Здесь можно обработать ошибку, например, показать сообщение об ошибке
                            },
                        });

                        actions.setSubmitting(false);
                    }}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Дата создания</td>
                                        <td>
                                            {new Intl.DateTimeFormat('ru-RU', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: false, // 24-часовой формат времени
                                                timeZone: 'Europe/Moscow' // Убедитесь, что timeZone соответствует вашим требованиям
                                            }).format(new Date(values.creationDate))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Дата изминения</td>
                                        <td>
                                            {new Intl.DateTimeFormat('ru-RU', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: false, // 24-часовой формат времени
                                                timeZone: 'Europe/Moscow' // Убедитесь, что timeZone соответствует вашим требованиям
                                            }).format(new Date(values.changeDate))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Активный</td>
                                        <td>
                                            <span className="form-check form-switch">
                                                <Field
                                                    name="isActive"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="isActive"
                                                />
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Общий</td>
                                        <td>
                                            <span className="form-check form-switch">
                                                <Field
                                                    name="isCommon"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="isCommon"
                                                />
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Имя атрибута</td>
                                        <td><Field className="form-control form-control-lg" name="attributeName" /></td>
                                    </tr>

                                    <tr>
                                        <td>Тип атрибута</td>
                                        <td>
                                            <div className="multiple-td">
                                                <div>
                                                    Множественное
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <Field as="select"
                                                    name="attributeType"
                                                    className="form-control form-control-lg"
                                                >
                                                    <option value="">Выберите тип</option>
                                                    <option value="Строка">Строка</option>
                                                    <option value="Число">Число</option>
                                                    <option value="Дата">Дата</option>
                                                    <option value="Список">Список</option>
                                                </Field>
                                            </div>

                                            {isChecked && (
                                                <div>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Значение</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                name="attributeValues"
                                                                render={arrayHelpers => (
                                                                    <>

                                                                        {values.attributeValues.map((attributeValue, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <Field name={`attributeValues.${index}.value`} className="form-control" />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.push({ value: '', attributeId: attribute?.id })}
                                                                                >
                                                                                    +
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />

                                                        </tbody>

                                                    </table>
                                                </div>
                                            )}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Порядок сортировки</td>
                                        <td><Field className="form-control form-control-lg" name="sortOrder" type="number" /></td>
                                    </tr>
                                    <tr>
                                        <td className="col-4">Категория товара</td>
                                        <td className="col-8">
                                            <Field as="select" name="categoryIds" multiple className="form-control form-control-lg">
                                                <option value="" disabled>Выберите категории</option>
                                                {categories && renderCategoryOptions(categories)}
                                            </Field>
                                        </td>
                                    </tr>
                                    {/* Дополнительные поля */}
                                </tbody>
                            </table>



                            <button type="submit">Обновить</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Layout>
    );
};

export default UpdateAttributeComponent;