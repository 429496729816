import React, { ReactNode } from 'react';
import styles from './MainLayout.module.css';

interface MainLayoutProps {
    children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    return (
        <div className={styles.container}>
            <main>{children}</main>
        </div>
    );
};

export default MainLayout;
