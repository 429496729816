// AlertContext.tsx
// import { AlertType } from 'hooks/useAlert';
// import React, { createContext, useContext, useState, ReactNode } from 'react';

// interface AlertContextType {
//   alertData: { message: string; type: AlertType };
//   triggerAlert: (message: string, type: AlertType) => void;
//   resetAlert: () => void;
// }

// const AlertContext = createContext<AlertContextType | undefined>(undefined);

// interface AlertProviderProps {
//   children: ReactNode;
// }

// export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
//   const [alertData, setAlertData] = useState<{ message: string; type: AlertType }>({ message: '', type: 'info' });

//   const triggerAlert = (message: string, type: AlertType) => {
//     console.log("Triggering alert with message:", message, "and type:", type);
//     setAlertData({ message, type });
//   };

//   const resetAlert = () => {
//     console.log("Resetting alert");
//     setAlertData({ message: '', type: 'info' });
//   };

//   return (
//     <AlertContext.Provider value={{ alertData, triggerAlert, resetAlert }}>
//       {children}
//     </AlertContext.Provider>
//   );
// };

// export const useAlert = () => {
//   const context = useContext(AlertContext);
//   if (context === undefined) {
//     throw new Error('useAlert must be used within an AlertProvider');
//   }
//   return context;
// };
import React, { createContext, useContext, useState, ReactNode } from 'react';

export type AlertType = 'success' | 'danger' | 'warning' | 'info';

interface AlertContextType {
  triggerAlert: (message: string, type: AlertType) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlertContent = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alertData, setAlertData] = useState<{ message: string; type: AlertType } | null>(null);

  const triggerAlert = (message: string, type: AlertType) => {
    setAlertData({ message, type });

    // Автоматически скрыть алерт через 3 секунды
    setTimeout(() => {
      setAlertData(null);
    }, 4000);
  };

  return (
    <AlertContext.Provider value={{ triggerAlert }}>
      {children}
      {alertData && (
        // <div className={`alert alert-${alertData.type} mb-0 text-center custom-alert fade-in-out`} role="alert">
        <div className={`alert alert-${alertData.type} mb-0 text-center custom-alert slide-in-out`} role="alert">
          {alertData.message}
        </div>
      )}
    </AlertContext.Provider>
  );
};