import React, { useEffect } from 'react';
import axios from 'axios';

// Функция getUserInfo
const getUserInfo = async (accessToken: string) => {
  try {
    const response = await axios.get(`https://api.vk.com/method/users.get`, {
      params: {
        v: '5.131',
        access_token: accessToken
      }
    });
    console.log(response.data); // Вывод данных
  } catch (error) {
    console.error('Ошибка при выполнении запроса: ', error);
  }
};

const VKAuthTest: React.FC = () => {
  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1));
    // const accessToken = params.get('access_token');
    const accessToken = "vk1.a.XW_t7RvmJhcfx8N9GhhPZEAmSo1Qmz-5uGu-eMPcrAkpli5YthtpFdz-FxWD73KLXEjqVMS-NK28d5Un83v_2zeA3mbNi3_NRTgNdGlnDJ76aQ6NtP6nXB4DkurudDxllZmvPVZka1LnuoElHzQE9paLxHcivjTpVtJAcIUyVCrkKfD5V12JVGV9O4OzKlIOhJ4LuUMTHza6Gk-m9CHzUg";

    if (accessToken) {
      // Вызов getUserInfo с токеном доступа
      getUserInfo(accessToken);
    }
  }, []);

  return <div>Авторизация...</div>;
};

export default VKAuthTest;