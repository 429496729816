// import React from 'react';
// import { Category } from 'types/Category';

// type CategoriesTreeProps = {
//     categories: Category[];
// };

// const CategoriesTree: React.FC<CategoriesTreeProps> = ({ categories }) => {
//     // функция для рекурсивного отображения категорий
//     const renderCategories = (categories: Category[]): React.ReactNode => {
//         return (

//             <ul> {/* Пример применения класса */}
//                 {categories.map(category => (
//                     <li key={category.id} >
//                         <span>
//                             <a href="#">{category.categoryName}</a>
//                         </span>
//                         {category.children && category.children.length > 0 && renderCategories(category.children)}
//                     </li>
//                 ))}
//             </ul>
//         );
//     };

//     return (
//         <div className='multi-menu'>
//             <div id="multi-derevo">
//                 {renderCategories(categories)}
//             </div>
//         </div>
//     );
// };

// export default CategoriesTree;

// import React, { useEffect, useState } from 'react';
// import { Category } from 'types/Category';

// type CategoriesTreeProps = {
//     categories: Category[];
// };

// const CategoriesTree: React.FC<CategoriesTreeProps> = ({ categories }) => {
//     const [activeCategoryId, setActiveCategoryId] = useState<string | null>(null);
//     const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

//     // Проверяем, что id не undefined перед преобразованием в строку
//     const safelyGetCategoryId = (categoryId: number | undefined): string => categoryId?.toString() ?? 'unknown';

//     const toggleActiveCategory = (categoryId: string) => {
//         console.log(`Текущий активный categoryId: ${activeCategoryId}. Переключаем на: ${categoryId}`);
//         setActiveCategoryId(activeCategoryId === categoryId ? null : categoryId);
//     };

//     const toggleCategoryExpansion = (categoryId: string) => {
//         setExpandedCategories(prevState => {
//             const newState = prevState.includes(categoryId) ? prevState.filter(id => id !== categoryId) : [...prevState, categoryId];
//             console.log(`Изменение expandedCategories:`, prevState, '->', newState);
//             return newState;
//         });
//     };

//     useEffect(() => {
//         console.log(`Активный categoryId изменен на: ${activeCategoryId}`);
//     }, [activeCategoryId]);

//     useEffect(() => {
//         console.log(`Список раскрытых категорий:`, expandedCategories);
//     }, [expandedCategories]);



//     const renderCategories = (categories: Category[]): React.ReactNode => {
//         return (
//             <ul>
//                 {categories.map(category => {
//                     const categoryId = safelyGetCategoryId(category.id);
//                     return (
//                         <li key={categoryId}>
//                             <span onClick={() => toggleActiveCategory(categoryId)} style={{ cursor: 'pointer' }}>
//                                 <a href="#" onClick={(e) => { e.preventDefault(); toggleCategoryExpansion(categoryId); }}>
//                                     {category.categoryName}
//                                     {category.children && category.children.length > 0 && (
//                                         <em className={`marker ${expandedCategories.includes(categoryId) ? 'open' : ''}`}></em>
//                                     )}
//                                 </a>
//                             </span>
//                             {expandedCategories.includes(categoryId) && category.children && category.children.length > 0 && renderCategories(category.children)}
//                         </li>
//                     );
//                 })}
//             </ul>
//         );
//     };

//     return (
//         <div className='multi-menu'>
//             <div id="multi-derevo">
//                 {/* <h4><a href="#">Меню</a></h4> */}
//                 {renderCategories(categories)}
//             </div>
//         </div>
//     );
// };

// export default CategoriesTree;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Category } from 'types/Category';

type CategoriesTreeProps = {
    categories: Category[];
    expandAll?: boolean; // Добавляем пропс для управления поведением раскрытия
};

const CategoriesTree: React.FC<CategoriesTreeProps> = ({ categories, expandAll = false }) => {
    const [activeCategoryId, setActiveCategoryId] = useState<string | null>(null);

    // Функция для сбора всех id категорий
    const collectAllCategoryIds = (categories: Category[]): string[] => {
        let ids: string[] = [];
        categories.forEach(category => {
            const categoryId = category.id?.toString() ?? 'unknown';
            ids.push(categoryId);
            if (category.children && category.children.length > 0) {
                ids = ids.concat(collectAllCategoryIds(category.children));
            }
        });
        return ids;
    };

    // Инициализируем expandedCategories на основе пропса expandAll
    const [expandedCategories, setExpandedCategories] = useState<string[]>(
        expandAll ? collectAllCategoryIds(categories) : []
    );

    const safelyGetCategoryId = (categoryId: number | undefined): string => categoryId?.toString() ?? 'unknown';

    const toggleActiveCategory = (categoryId: string) => {
        setActiveCategoryId(activeCategoryId === categoryId ? null : categoryId);
    };

    const toggleCategoryExpansion = (categoryId: string) => {
        setExpandedCategories(prevState => {
            const newState = prevState.includes(categoryId) ? prevState.filter(id => id !== categoryId) : [...prevState, categoryId];
            return newState;
        });
    };

    // Эффект для инициализации раскрытия всех категорий, если expandAll изменится
    useEffect(() => {
        if (expandAll) {
            setExpandedCategories(collectAllCategoryIds(categories));
        } else {
            setExpandedCategories([]);
        }
    }, [expandAll, categories]);

    const renderCategories = (categories: Category[]): React.ReactNode => {
        return (
            <ul>
                {categories.map(category => {
                    const categoryId = safelyGetCategoryId(category.id);
                    return (
                        <li key={categoryId}>
                            <span onClick={() => toggleActiveCategory(categoryId)} style={{ cursor: 'pointer' }}>
                                <Link to={`/update-category/${categoryId}`}>
                                    {category.categoryName}
                                    {category.children && category.children.length > 0 && (
                                        <em className={`marker ${expandedCategories.includes(categoryId) ? 'open' : ''}`}></em>
                                    )}
                                </Link>
                            </span>
                            {expandedCategories.includes(categoryId) && category.children && category.children.length > 0 && renderCategories(category.children)}
                        </li>

                        // <li key={categoryId}>
                        //     <span onClick={() => toggleActiveCategory(categoryId)} style={{ cursor: 'pointer' }}>
                        //         <a href={`/update-category/${categoryId}`}>
                        //             {category.categoryName}
                        //         </a>
                        //         {category.children && category.children.length > 0 && (
                        //             <em
                        //                 className={`marker ${expandedCategories.includes(categoryId) ? 'open' : ''}`}
                        //                 onClick={(e) => {
                        //                     e.stopPropagation(); // Предотвращаем всплытие события к <a>
                        //                     e.preventDefault(); // Предотвращаем любое дефолтное поведение (на всякий случай)
                        //                     toggleCategoryExpansion(categoryId); // Раскрываем или скрываем дочерние элементы
                        //                 }}
                        //             ></em>
                        //         )}
                        //     </span>
                        //     {expandedCategories.includes(categoryId) && category.children && category.children.length > 0 && renderCategories(category.children)}
                        // </li>


                        // <li key={categoryId}>
                        //     <Link to={`/update-category/${categoryId}`}>
                        //         {category.categoryName}
                        //     </Link>
                        //     {category.children && category.children.length > 0 && (
                        //         <>
                        //             <button onClick={(e) => { e.stopPropagation(); toggleCategoryExpansion(categoryId); }}>
                        //                 Раскрыть
                        //             </button>
                        //             <em className={`marker ${expandedCategories.includes(categoryId) ? 'open' : ''}`}></em>
                        //             {expandedCategories.includes(categoryId) && renderCategories(category.children)}
                        //         </>
                        //     )}
                        // </li>


                    );
                })}
            </ul>
        );
    };

    return (
        <div className='multi-menu'>
            <div id="multi-derevo">
                {renderCategories(categories)}
            </div>
        </div>
    );
};

export default CategoriesTree;
