import React, {useState, ChangeEvent, FormEvent, useEffect} from 'react';
import axios from 'axios';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {User} from 'types/User';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import './Login.scss';
import CustomVkIdButton from "../VkIdLogin/CustomVkIdButton";
import Yandex from "../SVG/Yandex/Yandex";
import Ok from "../SVG/Ok/Ok";
import MailIcon from "../SVG/Mail/MailIcon";
import CenteredCircle from "../fragment/CenteredCircle/CenteredCircle";
import RedirectHandlerTelegram from "../TelegramAuth/RedirectHandlerTelegram";
import Telegram from "../SVG/Telegram/Telegram";
import ProviderModal from "../fragment/ProviderModal/ProviderModal";


interface LoginResponse {
    token: string;
    userId: number;
}

const Login: React.FC = () => {
    const botName = process.env.REACT_APP_BOT_NAME;
    const location = useLocation();
    const [isProvider, setIsProvider] = useState<string | null>(null);
    const [emailProvider, setEmailProvider] = useState<string | null>(null);
    const [phoneProvider, setPhoneProvider] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');

    const clientId = process.env.REACT_APP_YANDEX_CLIENT_ID;
    // const clientIdOk = process.env.REACT_APP_ID;
    const clientIdOk = process.env.REACT_APP_CLIENT_ID_OK;
//   const redirectUri = encodeURIComponent('http://localhost:3000/redirect-handler-yandex');
    const redirectUri = encodeURIComponent(process.env.REACT_APP_URL + '/redirect-handler-yandex');
    const authUrl = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
    // Вечный access_token:-n-itxwnNIoJ1LbaVlRFwBMbBjpJuDG48hLBuVYRX431IBqrnsuKduQuKZWh0EGndPQquWPnfuqXjY8vaGww3
    // Session_secret_key:24010eaa804d31363f6feb99fb3ef8b8
    const redirectUriOk = encodeURIComponent(process.env.REACT_APP_URL + '/redirect-handler-ok');
    const responseType = 'token';
    // Формирование строки scope
    const scope = [
        'VALUABLE_ACCESS',
        'LONG_ACCESS_TOKEN',
        'PHOTO_CONTENT',
        'GROUP_CONTENT',
        'VIDEO_CONTENT',
        'APP_INVITE',
        'GET_EMAIL'
    ].join(';');
    // Формирование ссылки
    const authUrlOk = `https://connect.ok.ru/oauth/authorize?client_id=${clientIdOk}&scope=${scope}&response_type=${responseType}&redirect_uri=${redirectUriOk}`;

    const redirectUriMail = encodeURIComponent(process.env.REACT_APP_URL + '/redirect-handler-mail');
    const clientIdMail = process.env.REACT_APP_MAIL_CLIENT_ID;
    const responseTypeMail = 'code';
    const authUrlMail1 = `https://connect.mail.ru/oauth/authorize?client_id=${clientIdMail}&response_type=${responseTypeMail}&redirect_uri=${redirectUriMail}`;
    const authUrlMail = `https://oauth.mail.ru/login?client_id=${clientIdMail}&response_type=${responseTypeMail}&scope=userinfo&redirect_uri=${redirectUriMail}&state=some_state`;

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            // const response = await axios.post<LoginResponse>('http://sb-api.tortam.ru:8080/api/v1/auth/authenticate', {
            const response = await axios.post<LoginResponse>(`${process.env.REACT_APP_API_URL}/auth/authenticate`, {
                email,
                password
            });
            console.log("response.data");

            console.dir(response.data, {depth: null});
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('userId', response.data.userId.toString());
            console.log("userId: " + localStorage.getItem('userId'));

            const updatedUserId = localStorage.getItem('userId') || '';
            console.log("updatedUserId: " + updatedUserId);
            try {
                const config = {
                    headers: {Authorization: `Bearer ${response.data.token}`},
                };

                const {data} = await axios.get<User>(`${process.env.REACT_APP_API_URL}/users/${updatedUserId}`, config);

                console.log("data");
                console.log(data);


                if (data?.is_registered) {
                    navigate('/index'); // Редирект, если пользователь активен
                    localStorage.setItem('email', data.email);
                } else {
                    navigate('/register'); // Редирект, если пользователь неактивен
                }
            } catch (error) {
                console.error("Ошибка при запросе данных пользователя:", error);
                // Обработка ошибки запроса
            }
            // Остальная логика обработки успешного ответа
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                switch (error.response?.status) {
                    case 404:
                        setError("Пользователь не найден");
                        break;
                    case 401:
                        setError("Неверный пароль");
                        break;
                    default:
                        setError(`Ошибка входа: ${error.response?.status} - ${error.response?.statusText}`);
                }
            } else {
                setError('Ошибка входа: ' + error.message);
            }
            console.error('Error fetching token:', error);
        }
    };

    // useEffect(() => {
    //     loadYandexAutofill()
    //         .then(() => {
    //             console.log('Yandex Autofill script loaded successfully');
    //             // Инициализация SDK после загрузки
    //             // Например: window.ya.suggest.init({...});
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }, []);

    // https://chat.openai.com/share/d108a594-e145-4643-9592-7605eb6fa8f8

    useEffect(() => {
        if (location.state && location.state.details) {
            const { isProvider, emailDetails, phoneDetails } = location.state.details;
            setIsProvider(isProvider);
            if (emailDetails) {
                setEmailProvider(emailDetails.providerEmail);
            }
            if (phoneDetails) {
                setPhoneProvider(phoneDetails.providerPhone);
            }
            setIsModalOpen(true);
        }
    }, [location.state]);

    const providersMap: { [key: string]: JSX.Element } = {
        vk: <CustomVkIdButton />,
        ok: <a href={authUrlOk}><Ok /></a>,
        yandex: <a href={authUrl}><Yandex /></a>,
        mail: <a href={authUrlMail}><MailIcon /></a>,
        telegram: <a href={`https://t.me/${botName}?start=start`} target="_blank"><Telegram /></a>
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <MainLayout>
            {isProvider && (
                <ProviderModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    emailProvider={emailProvider}
                    phoneProvider={phoneProvider}
                    providersMap={providersMap}
                />
            )}
            <div className="">
                <main className="d-flex w-100">
                    <div className="container d-flex flex-column">
                        <div className="row vh-100">
                            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                                <div className="d-table-cell align-middle">
                                    <div className="text-center mt-4">
                                        <h1 className="h2">Добро пожаловать!</h1>
                                        {/*<p className="lead">*/}
                                        {/*    Войдите в свой аккаунт или зарегистрируйтесь, чтобы продолжить.*/}
                                        {/*</p>*/}
                                    </div>

                                    <div className="card">
                                        <div className="card-body">
                                            <div className="auth-social-buttons-container">
                                                <div className="auth-social-header">Зарегистрируйтесь или войдите в систему с помощью</div>
                                                <div className="auth-social-buttons">
                                                    <CustomVkIdButton/>
                                                    <a href={authUrl}><Yandex/></a>
                                                    <a href={authUrlOk}><Ok /></a>
                                                    <a href={authUrlMail}><MailIcon /></a>
                                                    <a href={`https://t.me/${botName}?start=start`} target="_blank"><Telegram /></a>
                                                </div>
                                            </div>
                                            <CenteredCircle text="Или" />
                                            <div className="m-sm-3">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="email">Email</label>
                                                        <input
                                                            id="email"
                                                            className="form-control form-control-lg"
                                                            type="email"
                                                            name="email"
                                                            placeholder="Введите ваш email"
                                                            value={email}
                                                            onChange={handleEmailChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="password">Пароль</label>
                                                        <input
                                                            id="password"
                                                            className="form-control form-control-lg"
                                                            type="password"
                                                            name="password"
                                                            placeholder="Введите ваш пароль"
                                                            value={password}
                                                            onChange={handlePasswordChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-check align-items-center">
                                                        <input
                                                            id="customControlInline"
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            value="remember-me"
                                                            name="remember-me"
                                                        />
                                                        <label className="form-check-label text-small"
                                                               htmlFor="customControlInline">
                                                            Запомнить меня
                                                        </label>
                                                    </div>
                                                    <div className="container-button text-center mt-3">
                                                        <div className="auth-classic-links">
                                                            <button type="submit" className="btn btn-lg btn-primary">Войти</button>
                                                            <Link to="/reg">Зарегистрироваться</Link>
                                                        </div>
                                                    </div>
                                                </form>
                                                {error && <div className="alert alert-danger mt-3">{error}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="text-center mt-4">*/}
                                    {/*    <p>У вас нет аккаунта? <Link to="/reg">Зарегистрироваться</Link></p>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </MainLayout>
    );
};

export default Login;