// import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { jwtDecode } from "jwt-decode";
// import { User } from "../types/User";

// const useUser = (userId: string) => {
//   const [user, setUser] = useState<User | null>(null);
//   const [isLoading, setIsLoading] = useState<boolean>(false);
//   const [error, setError] = useState<Error | null>(null);
//   const navigate = useNavigate();
//   if (!localStorage.getItem("token")) {
//     navigate("/login"); // Редирект на страницу входа, если нет токена
//   }

//   useEffect(() => {
//     if (!userId) return;

//     let intervalId: NodeJS.Timer;


//     const checkTokenExpiration = () => {
//       const token = localStorage.getItem("token");
      
//       if (token) {
//         const decodedToken = jwtDecode<any>(token);
//         const isTokenExpired = decodedToken.exp * 1000 < new Date().getTime();

//         if (isTokenExpired) {
//           localStorage.removeItem("token"); // Удаление истекшего токена
//           navigate("/login"); // Перенаправление на страницу входа
//           clearInterval(intervalId); // Очистка интервала
//           return;
//         }

//         // Токен действителен, продолжаем запрашивать данные пользователя
//         fetchUser();
//       } else {
//         navigate("/login"); // Если токена нет, перенаправляем на страницу входа
//       }
//     };

//     checkTokenExpiration(); // Первоначальная проверка токена

//     // Установка интервала
//     const token = localStorage.getItem("token");
//     if (token) {
//       const decodedToken = jwtDecode<any>(token);
//       const issuedAt = decodedToken.iat;
//       const expiration = decodedToken.exp;
//       const tokenLifetimeInMilliseconds = (expiration - issuedAt) * 1000;
//       console.log("tokenLifetimeInMilliseconds: " + tokenLifetimeInMilliseconds);
      

//       intervalId = setInterval(checkTokenExpiration, tokenLifetimeInMilliseconds);
//     }

//     // Очистка интервала при размонтировании компонента
//     return () => clearInterval(intervalId);
//   }, [userId, navigate]);

//   const fetchUser = async () => {
//     // console.log("checkTokenExpiration: " + userId);
//     setIsLoading(true);
//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.get<User>(
//         `${process.env.REACT_APP_API_URL}/users/value/${userId}`,
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       // console.log("response.data: 444: " + response.data)
//       setUser(response.data);
//     } catch (err) {
//       setError(err as Error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return {
//     user,
//     setUser,
//     isLoading,
//     error,
//     isActive: user?.is_active || false,
//   };
// };

// export default useUser;


import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useQuery, useQueryClient } from "react-query";
import { User } from "../types/User"; // Убедитесь, что путь к типу User корректен

const fetchUser = async (userId: string) => {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("No token found");

  const response = await axios.get<User>(`${process.env.REACT_APP_API_URL}/users/value/${userId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

const useUser = (userId: string) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: user, isLoading, error, refetch } = useQuery<User, Error>(
    ['user', userId],
    () => fetchUser(userId),
    {
      enabled: !!userId && !!localStorage.getItem("token"),
      onError: (error) => {
        if (error.message === "No token found") {
          navigate("/login");
        }
      },
      onSettled: () => {
        const token = localStorage.getItem("token");
        if (token) {
          const decodedToken = jwtDecode<any>(token);
          const isTokenExpired = decodedToken.exp * 1000 < new Date().getTime();

          if (isTokenExpired) {
            localStorage.removeItem("token");
            navigate("/login");
          }
        }
      },
    }
  );

  const updateUser = (newData: User) => { // Теперь указываем тип для newData
    queryClient.setQueryData<User>(['user', userId], newData);
  };

  return { user, isLoading, error, updateUser, refetch };
};

export default useUser;
