import { useState } from 'react';

export type AlertType = 'success' | 'danger' | 'warning' | 'info';

const useAlert = () => {
    const [alertData, setAlertData] = useState<{ message: string; type: AlertType }>({ message: '', type: 'info' });
    const [showAlert, setShowAlert] = useState(false);

    const triggerAlert = (message: string, type: AlertType = 'info', timeout = 200000) => {
        setAlertData({ message, type });
        setShowAlert(true);

        setTimeout(() => {
            setShowAlert(false);
        }, timeout);
    };

    const resetAlert = () => {
        setShowAlert(false);
        setAlertData({ message: '', type: 'info' });
    };
    // console.log("Current showAlert state:", showAlert, "with message:", alertData.message);

    return { alertData, showAlert, triggerAlert, resetAlert };
};

export default useAlert;