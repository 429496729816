import React, { useState } from 'react';
import axios from 'axios';

const GenerateSitemap: React.FC = () => {
    const [maxUrls, setMaxUrls] = useState<number>(1000);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const handleGenerateSitemap = async () => {
        setLoading(true);
        setMessage('');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/sitemap/generate`, {
                params: { maxUrls }
            });
            setMessage(response.data);
        } catch (error) {
            setMessage('Error generating sitemap');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <input
                type="number"
                value={maxUrls}
                onChange={(e) => setMaxUrls(Number(e.target.value))}
                placeholder="Enter max URLs per file"
            />
            <button onClick={handleGenerateSitemap} disabled={loading}>
                {loading ? 'Generating...' : 'Generate Sitemap'}
            </button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default GenerateSitemap;