import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Layout from "components/Layout/Layout";  // Подключите ваш Layout компонент
import { storeStore } from "../../stores/StoreStore";
import {Watch} from "react-loader-spinner";
import {Link} from "react-router-dom";  // Подключите ваш storeStore

const StoresList: React.FC = observer(() => {
    useEffect(() => {
        console.log('Fetching stores...');
        storeStore.fetchStoreByUserId();
    }, []);

    if (storeStore.loading) {
        return (
            <div className="loader-spinner">
                <Watch
                    visible={true}
                    height="80"
                    width="80"
                    radius="48"
                    color="#4fa94d"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        );
    }
    // if (storeStore.error) return <p>Ошибка: {storeStore.error}</p>;
    // if (!storeStore.hasStore) return <p>Магазины не найдены.</p>;

    return (
        <Layout>
            <div>
                <h1>Магазины пользователя</h1>
                <ul>
                    {storeStore.stores.map((store, index) => (
                        <li key={index}>
                            <Link to={`/update-store/${store.id}`}>{store.storeName}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </Layout>
    );
});

export default StoresList;
