import { useMutation } from 'react-query';
import axios from 'axios';

export const useImageUpload = () => {
  const uploadImage = (formData: FormData) => {
    const token = localStorage.getItem("token");
    return axios.post(`${process.env.REACT_APP_API_URL}/images/upload`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  };

  return useMutation(uploadImage);
};
