import React, { useEffect } from 'react';
import axios from 'axios';


const TelegramLogin: React.FC = () => {
    useEffect(() => {
        (window as any).onTelegramAuth = (user: any) => {
            handleTelegramAuth(user);
        };
    }, []);

    const handleTelegramAuth = async (user: any) => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/telegram-auth`, user, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Auth successful', response.data);
        } catch (error) {
            console.error('Auth failed', error);
        }
    };

    return (
        <div>
            <script async src="https://telegram.org/js/telegram-widget.js?22"
                    data-telegram-login="tortam_bot"
                    data-size="large"
                    data-userpic="false"
                    data-auth-url="https://admin.tortam.ru/redirect-handler-telegram"
                    data-request-access="write"
                    data-onauth="onTelegramAuth"></script>
        </div>
    );
};

export default TelegramLogin;
