import { useQuery } from "react-query";
import axios from "axios";
import { UserField } from "types/UserFieldTypes";

const fetchUserField = async (id: string): Promise<UserField> => {
  const token = localStorage.getItem("token");
  
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { data } = await axios.get<UserField>(
    `${process.env.REACT_APP_API_URL}/userfields/${id}`,
    config
  );

  return data;
};

export const useUserField = (id?: string) => {
  return useQuery<UserField, Error>(["userField", id], () => {
    if (!id) throw new Error("ID is not provided");
    return fetchUserField(id);
  });
};
