import axios from "axios";
import { useMutation } from 'react-query';
import { UserField } from "types/UserFieldTypes";

// Функция для отправки запроса на обновление
const updateUserField = async ({ id, data }: { id: string, data: UserField }) => {
  const token = localStorage.getItem("token");

  return axios.put(`${process.env.REACT_APP_API_URL}/userfields/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const useUpdateUserField = () => {
  return useMutation(updateUserField);
};
