import {User} from "../../types/User";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useExchangeSilentAuthToken} from "../../hooks/vkid/useExchangeSilentAuthToken";
import axios from "axios";

interface ExchangeResponse {
    user: User;
    token: string;
}

const RedirectHandlerOk: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const hash = window.location.hash.substr(1); // Извлекаем параметры из URL после #
        const params = new URLSearchParams(hash);
        const accessToken = params.get('access_token');
        const state = params.get('state');

        console.log("accessToken")
        console.log(accessToken)
        console.log(`${process.env.REACT_APP_API_URL}/auth/ok`)
        if (accessToken) {
            axios.get(`${process.env.REACT_APP_API_URL}/auth/ok`, { params: { accessToken, state } })
                .then(response => {
                    if (response.data.isProvider) {
                        // Действие, если есть response.data.isProvider
                        console.log('isProvider:', response.data.isProvider);
                        // navigate('/login');
                        // navigate('/login', { state: { isProvider: response.data.isProvider } });
                        navigate('/login', { state: { isProvider: response.data.isProvider, details: response.data } });
                        // Ваше действие здесь
                    } else if (response.data.token) {
                        // Действие, если есть response.data.token
                        console.log('token:', response.data.token);
                        const responseData: ExchangeResponse = response.data;
                        localStorage.setItem('token', responseData.token);
                        localStorage.setItem('userId', responseData.user.id.toString());
                        navigate('/index');
                    } else {
                        // Действие, если ни одно из условий не выполнено
                        console.log('Neither isProvider nor token found in response.');
                        // Ваше действие здесь
                    }
                })
                .catch(error => {
                    console.error('Error during authentication', error);
                })
                .finally(() => {
                    // navigate('/'); // Перенаправление на главную страницу или другую
                });
        } else {
            console.error('Access token is missing');
            // navigate('/'); // Перенаправление на главную страницу или другую при отсутствии токена
        }
    }, [navigate]);

    return (
        <div>Loading...</div>
    );
};

export default RedirectHandlerOk;