import {observer} from "mobx-react";
import React, {useEffect} from "react";
import Layout from "../Layout/Layout";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {storeStore} from "../../stores/StoreStore";
import {StoreData} from "../../types/StoreData";
import {FormRow} from "../fragment/FormRow";
import {userStore} from "../../stores/UserStore";

const CreateStore: React.FC = observer(() => {
    const userId = localStorage.getItem("userId");
    useEffect(() => {
        userStore.fetchUserByEmail();
    }, []);
    return (
        <Layout>
            <div>
                <h1>Создать магазин</h1>
                <Formik
                    initialValues={{
                        storeName: '',
                        slug: '',
                        description: '',
                        userId: userId ? parseInt(userId) : null,
                        isActive: true,
                        creation_date: new Date(),
                        change_date: new Date(),
                        products: []
                    }}
                    onSubmit={async (values, {setSubmitting}) => {
                        const storeData: StoreData = {
                            ...values,
                            // id не передается, так как предполагается, что он будет генерироваться на сервере
                        };
                        console.log(storeData);
                        await storeStore.createStore(storeData);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting}) => (
                        <Form>
                            <table className="table">
                                <tbody>
                                    <FormRow label="Название магазина" type="text" name="storeName"
                                             placeholder="Название магазина"/>
                                    {/*<FormRow label="Slug магазина" type="text" name="slug" placeholder="Slug магазина"/>*/}
                                    <FormRow label="Описание магазина" type="text" name="description"
                                             placeholder="Описание магазина" as="textarea"/>
                                </tbody>
                            </table>
                            <button type="submit" disabled={isSubmitting}>
                                Создать магазин
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Layout>
    );
});
export default CreateStore;