import React, { useContext, useState, ReactNode } from 'react';

type ModalContextType = {
    showModal: (message: string, attributes: string[], onConfirm: () => void, onCancel: () => void) => void;
};

const ModalContext = React.createContext<ModalContextType | undefined>(undefined);

const useModal = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};

const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [attributes, setAttributes] = useState<string[]>([]);
    const [onConfirm, setOnConfirm] = useState<() => void>(() => { });
    const [onCancel, setOnCancel] = useState<() => void>(() => { });

    const showModal = (message: string, attributes: string[], onConfirm: () => void, onCancel: () => void) => {
        setMessage(message);
        setAttributes(attributes);
        setOnConfirm(() => onConfirm);
        setOnCancel(() => onCancel);
        setIsVisible(true);
    };

    const handleConfirm = () => {
        onConfirm();
        setIsVisible(false);
    };

    const handleCancel = () => {
        onCancel();
        setIsVisible(false);
    };

    return (
        <ModalContext.Provider value={{ showModal }}>
            {children}
            {isVisible && (
                <div className="modal-backdrop">
                    <div className="custom-modal">
                        <p className="h3 text-danger">{message}</p>
                        <ol>
                            {attributes.map((attr, index) => (
                                <li key={index}>{attr}</li>
                            ))}
                        </ol>
                        <div className="d-flex button-custom-modal">
                            <button className="btn btn-success" onClick={handleConfirm}>Подтвердить</button>
                            <button className="btn btn-danger" onClick={handleCancel}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}
        </ModalContext.Provider>
    );
};

const ConfirmModal: React.FC<{ message: string; attributes: string[]; onConfirm: () => void; onCancel: () => void }> = ({
    message,
    attributes,
    onConfirm,
    onCancel,
}) => {
    const { showModal } = useModal();
    showModal(message, attributes, onConfirm, onCancel);
    return null;
};

export { ModalProvider, ConfirmModal, useModal };
