import { useQuery } from "react-query";
import axios from "axios";

export const useCheckPhone = (phone: string, isPhoneFieldBlurred: boolean) => {
  const token = localStorage.getItem("token");

  return useQuery(
    ["checkPhone", phone],
    async () => {
      const { data } = await axios.get<Boolean>(
        `${process.env.REACT_APP_API_URL}/users/check-phone`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { phone },
        }
      );
      console.log("useCheckPhone");
      console.log(data);
      console.log("ENDuseCheckPhone");

      return data;
    },
    {
      enabled: isPhoneFieldBlurred && phone.trim() !== "", // Запускается только если поле было в фокусе и номер телефона не пустой
    }
  );
};
