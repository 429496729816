import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';


const CreatePost: React.FC = () => {
  const [post, setPost] = useState({ title: '', content: '' });
  const [errorInfo, setErrorInfo] = useState(''); // Дополнительное состояние для хранения информации об ошибке

  const createPost = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorInfo(''); // Очистить предыдущую информацию об ошибке перед новым запросом
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/posts`, post);
      alert('Post created successfully');
      setPost({ title: '', content: '' }); // Clear the form
    } catch (error) {
        const err = error as AxiosError;
        console.error('There was an error creating the post:', err);
        setErrorInfo(err.message); // Установить сообщение об ошибке
        alert('Error creating post: ' + err.message); // Показать сообщение об ошибке
      }
  };

  return (
    <div>
      <h1>Create Post</h1>
      {errorInfo && <p>Error: {errorInfo}</p>} {/* Отображение сообщения об ошибке, если оно есть */}
      <form onSubmit={createPost}>
        <div>
          <label htmlFor="title">Title:</label>
          <input
            id="title"
            value={post.title}
            onChange={(e) => setPost({ ...post, title: e.target.value })}
            required
          />
        </div>
        <div>
          <label htmlFor="content">Content:</label>
          <textarea
            id="content"
            value={post.content}
            onChange={(e) => setPost({ ...post, content: e.target.value })}
            required
          ></textarea>
        </div>
        <button type="submit">Create Post</button>
      </form>
    </div>
  );
};

export default CreatePost;
