// import React from 'react';
// import { Field, useFormikContext } from 'formik';
// import { Attribute } from 'types/ProductTypes';


// interface ProductAttribute {
//   attributeId: number;
//   attributeName: string;
//   attributeValue: string;
// }

// interface AttributeFieldsProps {
//   attributes: Attribute[];
//   initialProductAttributes?: ProductAttribute[];
// }

// const AttributeFields: React.FC<AttributeFieldsProps> = ({ attributes, initialProductAttributes }) => {
//     // const { setFieldValue } = useFormikContext();
//     const { setFieldValue, values } = useFormikContext();


//     const filteredAttributes = React.useMemo(() => {
//         if (!initialProductAttributes || initialProductAttributes.length === 0) return attributes;
//         // Исключаем атрибуты, присутствующие в initialProductAttributes
//         return attributes.filter(attribute => !initialProductAttributes.some(pa => pa.attributeId === attribute.id));
//     }, [attributes, initialProductAttributes]);

//     React.useEffect(() => {
//         filteredAttributes.forEach((attribute, index) => {
//             setFieldValue(`attributeValues[${index}].attributeId`, attribute.id);
//         });
//     }, [filteredAttributes, setFieldValue]);

//     console.log("values");
//     console.log(values);


//     return (
//         <>
//             {filteredAttributes.map((attribute, index) => (
//                 <tr key={attribute.id}>
//                     <td className="col-4">{attribute.attributeName}</td>
//                     <td className="col-8">
//                         <Field
//                             className="form-control form-control-lg"
//                             type="text"
//                             name={`attributeValues[${index}].value`}
//                             placeholder={`Введите ${attribute.attributeName}`}
//                         />
//                     </td>
//                 </tr>
//             ))}
//         </>
//     );
// };

// export default AttributeFields;


import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Attribute } from 'types/ProductTypes';


interface ProductAttribute {
    attributeId: number;
    attributeName: string;
    attributeValue: string;
}

interface AttributeFieldsProps {
    attributes: Attribute[];
    initialProductAttributes?: ProductAttribute[];
}

const AttributeFields: React.FC<AttributeFieldsProps> = ({ attributes, initialProductAttributes }) => {
    const { setFieldValue, values } = useFormikContext<any>();

    // Создаем массив идентификаторов из initialProductAttributes
    const initialAttributeIds = React.useMemo(() => {
        return initialProductAttributes?.map(attr => attr.attributeId) || [];
    }, [initialProductAttributes]);

    const filteredAttributes = React.useMemo(() => {
        // Преобразуем все categoryIds в строки для совместимости
        const categoryIds = values.categoryIds.map(String);

        // Фильтруем атрибуты, исключая те, которые уже есть в initialProductAttributes
        return attributes.filter(attribute =>
            (!attribute.categoryIds || attribute.categoryIds.length === 0 || attribute.categoryIds.some(categoryId => categoryIds.includes(String(categoryId)))) &&
            !initialAttributeIds.includes(attribute.id!)
            // Исключаем атрибуты, уже присутствующие в initialProductAttributes
        );
    }, [attributes, values.categoryIds, initialAttributeIds]);

    React.useEffect(() => {
        // console.log('values useEffect');
        // console.log(values);
        
        // Устанавливаем значения для attributeValues, основываясь на отфильтрованных атрибутах
        filteredAttributes.forEach((attribute, index) => {
            setFieldValue(`attributeValues[${index}].attributeId`, attribute.id);
        });
    }, [filteredAttributes, setFieldValue]);

    // React.useEffect(() => {
    //     if (values.attributeValues) { // Предполагаем, что isSubmitted - это состояние, которое становится true после отправки
    //         // Очищаем значения для attributeValues, которые были заполнены
    //         const clearedAttributeValues = values.attributeValues.map((attributeValue: { attributeId: number, value?: string }) => ({
    //             attributeId: attributeValue.attributeId,
    //             value: '', // Очищаем значение
    //         }));
    
    //         // Обновляем состояние формы с очищенными значениями
    //         setFieldValue('attributeValues', clearedAttributeValues);
    //     }
    // }, [/*filteredAttributes,*/ setFieldValue]);

    React.useEffect(() => {
        // if (values.attributeValues) {
        //     const clearedAttributeValues = values.attributeValues.map((attributeValue: { attributeId: number, value?: string }) => ({
        //         attributeId: attributeValue.attributeId,
        //         value: '' // Очищаем значение
        //     }));
            
        //     // Обновляем состояние формы с очищенными значениями
        //     setFieldValue('attributeValues', clearedAttributeValues);
        // }

        // console.log('filteredAttributes');
        // console.log(filteredAttributes);
        // setFieldValue('attributeValues', filteredAttributes);
        

    }, [setFieldValue]);
    

    return (
        <>
            {filteredAttributes.map((attribute, index) => (
                <tr key={attribute.id}>
                    <td className="col-4">{attribute.attributeName}</td>
                    <td className="col-8">
                        <Field
                            className="form-control form-control-lg"
                            type="text"
                            name={`attributeValues[${index}].value`}
                            // name={`attributeValues.${attribute.id}.value`}
                            placeholder={`Введите ${attribute.attributeName}`}
                        />
                    </td>
                </tr>
            ))}
        </>
    );
};

export default AttributeFields;