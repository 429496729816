// import React, { useState, useEffect } from 'react';
// import { useSpring, animated } from 'react-spring';

// type Stage = 'start' | 'prepare' | 'exercise' | 'relax' | 'end';

// export const ExerciseTimer: React.FC = () => {
//     const [stage, setStage] = useState<Stage>('start');
//     const [countdown, setCountdown] = useState<number>(0);
//     const [totalTime, setTotalTime] = useState<number>(10); // Общее время для текущего подхода
//     const [approach, setApproach] = useState<number>(1); // Текущий подход
//     const [phase, setPhase] = useState<'exercise' | 'relax'>('exercise'); // Текущая фаза: упражнение или отдых

//     // Анимация для countdown
//     const props = useSpring({ number: countdown, from: { number: countdown + 1 } });

//     // Инициализируем или сбрасываем таймер
//     useEffect(() => {
//         if (stage === 'start') {
//             setCountdown(3); // Подготовка
//         } else if (stage === 'prepare' && countdown === 0) {
//             setPhase('exercise'); // Переход к упражнению
//             setCountdown(approach === 1 ? 5 : approach === 2 ? 2 : 1); // Установка длительности упражнения
//         }
//     }, [stage, countdown, approach]);

//     // Обработка таймера
//     useEffect(() => {
//         let interval: NodeJS.Timeout | null = null;
//         if (stage !== 'start' && stage !== 'end') {
//             interval = setInterval(() => {
//                 setCountdown(prevCountdown => prevCountdown - 1);
//             }, 1000);
//         }

//         return () => {
//             if (interval) {
//                 clearInterval(interval);
//             }
//         };
//     }, [stage]);

//     // Логика переключения фаз и подходов
//     useEffect(() => {
//         if (countdown === 0) {
//             if (stage === 'prepare') {
//                 setStage('exercise');
//                 return;
//             }

//             if (totalTime <= 0) {
//                 if (approach < 3) {
//                     setApproach(approach + 1);
//                     setTotalTime(10);
//                     setStage('prepare');
//                 } else {
//                     setStage('end');
//                 }
//                 return;
//             }

//             if (phase === 'exercise') {
//                 setPhase('relax');
//                 // setCountdown(5); // Время на расслабление всегда 5 секунд
//             } else {
//                 setTotalTime(totalTime - (approach === 1 ? 10 : approach === 2 ? 4 : 2)); // Уменьшаем общее время
//                 setPhase('exercise');
//                 setCountdown(approach === 1 ? 5 : approach === 2 ? 2 : 1); // Устанавливаем время для следующего упражнения
//             }
//         }
//     }, [countdown, phase, approach, totalTime]);

//     const startExercise = () => {
//         setStage('prepare');
//     };

//     if (stage === 'end') {
//         return (
//             <div className="container-work">
//                 <div>Упражнение завершено!</div>
//             </div>
//         );
//     }

//     return (
//         <div className="container-work">
//             {stage === 'start' && <button onClick={startExercise}>Начать</button>}
//             {stage === 'prepare' &&
//                 <div>
//                     <div>Приготовьтесь. Осталось секунд:</div>
//                     <div key={countdown} className="countdown">{countdown}</div>
//                 </div>
//             }
//             {stage === 'exercise' && phase === 'exercise' &&
//                 <div>
//                     <div>Напрягитесь. Осталось секунд:</div>
//                     <div key={countdown} className="countdown">{countdown}</div>
//                 </div>
//             }
//             {stage === 'exercise' && phase === 'relax' &&
//                 <div>
//                     <div>Расслабьтесь. Осталось секунд:</div>
//                     <div key={countdown} className="countdown">{countdown}</div>
//                 </div>
//             }
//         </div>
//     );
// };


// import React, { useState, useEffect } from 'react';
// import { useTransition, animated } from 'react-spring';

// type Stage = 'start' | 'prepare' | 'exercise' | 'end';

// export const ExerciseProstate: React.FC = () => {
//     const [stage, setStage] = useState<Stage>('start');
//     const [countdown, setCountdown] = useState<number>(0);
//     const [message, setMessage] = useState<string>(''); // Сообщение для отображения
//     const [totalTime, setTotalTime] = useState<number>(60);
//     const [approach, setApproach] = useState<number>(1);
//     const [exerciseInterval, setExerciseInterval] = useState<number>(5);

//     // const props = useSpring({ number: countdown, from: { number: countdown + 1 } });

//     useEffect(() => {
//         if (stage === 'start') {
//             setCountdown(3); // Подготовка
//             setMessage('Приготовьтесь.');
//         } else if (stage === 'prepare' && countdown === 0) {
//             setStage('exercise');
//             setCountdown(approach === 1 ? 5 : approach === 2 ? 2 : 1);
//             setMessage('Напрягитесь.');
//         }
//     }, [stage, countdown, approach]);

//     useEffect(() => {
//         let interval: NodeJS.Timeout | null = null;
//         if (stage !== 'start' && stage !== 'end') {
//             interval = setInterval(() => {
//                 setCountdown(prevCountdown => prevCountdown - 1);
//             }, 1000);
//         }

//         return () => {
//             if (interval) {
//                 clearInterval(interval);
//             }
//         };
//     }, [stage]);

//     useEffect(() => {
//         if (countdown === 0) {
//             if (stage === 'exercise') {
//                 if (message.startsWith('Напрягитесь')) {
//                     setCountdown(5); // Время на расслабление
//                     setMessage('Расслабьтесь.');
//                 } else {
//                     setTotalTime(prev => prev - exerciseInterval);

//                     if (totalTime <= 0) {
//                         if (approach < 3) {
//                             const nextInterval = approach === 1 ? 2 : 1;
//                             setApproach(approach + 1);
//                             setExerciseInterval(nextInterval);
//                             setTotalTime(60);
//                         } else {
//                             setStage('end');
//                         }
//                     } else {
//                         setCountdown(approach === 1 ? 5 : approach === 2 ? 2 : 1);
//                         setMessage('Напрягитесь.');
//                     }
//                 }
//             }
//         }
//     }, [countdown, message, approach, totalTime, exerciseInterval]);

//     const startExercise = () => {
//         setStage('prepare');
//     };

//     // Создаем переход для анимации изменений countdown
//     const transitions = useTransition(countdown, {
//         from: { opacity: 0, transform: 'translateY(-20px)' },
//         enter: { opacity: 1, transform: 'translateY(0)' },
//         leave: { opacity: 0, transform: 'translateY(20px)' },
//         config: { duration: 500 }, // Конфигурация анимации, может быть настроена
//     });

//     if (stage === 'end') {
//         return <div>Упражнение завершено!</div>;
//     }

//     return (
//         <div className="container-work">
//             {stage === 'start' && <button onClick={startExercise}>Начать</button>}
//             <div>
//                 <div>{message} Осталось секунд:</div>
//                 {transitions((style, item) => (
//                     <animated.span style={style} className="countdown">
//                         {item}
//                     </animated.span>
//                 ))}
//             </div>
//         </div>
//     );
// };


import React, { useState } from 'react';
import { ExerciseTimer } from './ExerciseTimer';

export const ExerciseProstate: React.FC = () => {
    // Параметры для ExerciseTimer, которые можно настроить по желанию
    const [tensionTime, setTensionTime] = useState<number>(5);
    const [relaxTime, setRelaxTime] = useState<number>(5);
    const [totalTime, setTotalTime] = useState<number>(60);
    const [exerciseFinished, setExerciseFinished] = useState<boolean>(false);
    const [key, setKey] = useState<number>(0);
    const [timerStarted, setTimerStarted] = useState<boolean>(false);

    // Функция для сброса состояний и запуска упражнения с новыми параметрами
    const handleFinish = () => {
        // console.log("handleFinish");

        if (!exerciseFinished) {
            console.log("handleFinish handleFinish " + key);

            if(key == 0) {
                setTensionTime(2);
                setRelaxTime(2);
                setTotalTime(60);
                // setExerciseFinished(true);
                setKey(prevKey => prevKey + 1);
            }else if (key == 1) {
                setTensionTime(1);
                setRelaxTime(1);
                setTotalTime(60);
                setExerciseFinished(true);
                setKey(prevKey => prevKey + 1);
            }
            
        }
    };

     // Функция для запуска таймера по нажатию на кнопку
     const handleStartTimer = () => {
        // Устанавливаем состояние timerStarted в true, чтобы запустить таймер
        setTimerStarted(true);
    };

    return (
        <div className="container-work">
            <div>
                <h2>Упражнение для простаты</h2>
                <button onClick={handleStartTimer}>Начать упражнение</button>
                <ExerciseTimer
                    key={key}
                    tensionTime={tensionTime}
                    relaxTime={relaxTime}
                    totalTime={totalTime}
                    onFinish={handleFinish} // Передаем функцию обратного вызова
                    timerStarted={timerStarted}
                />
            </div>
        </div>
    );
};