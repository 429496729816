import React, {useEffect} from 'react';
import * as VKID from '@vkid/sdk';
import '../../configs/vkid.config';



const VkIdLogin: React.FC = () => {
    useEffect(() => {
        const oneTap = new VKID.OneTap();
        const container = document.getElementById('VkIdSdkOneTap');

        if (container) {
            oneTap.render({
                container: container,
                scheme: VKID.Scheme.LIGHT,
                lang: VKID.Languages.RUS
            });
        }

        return () => {
            // Очистка, если это необходимо
            // if (oneTap) {
            //   oneTap.dispose();
            // }
        };
    }, []);

    return (
        <div id="VkIdSdkOneTap"> {/* Контейнер для кнопки авторизации VK ID */}</div>
    );
};

export default VkIdLogin;