import React, { useState, useEffect } from 'react';
import { useFetchRoles } from 'hooks/useFetchRoles';
import { useFetchUsers } from 'hooks/useFetchUsers';
import Layout from 'components/Layout/Layout';
import axios from 'axios';
import AlertMessage from 'components/AlertMessage';


// Определение типов для ролей и пользователей
interface Role {
    id: number;
    name: string;
}

interface User {
    id: number;
    email: string;
}

const CreateComponent: React.FC = () => {
    const [formData, setFormData] = useState({
        userId: '',
        roleId: '',
        key: '',
        value: ''
    });
    const [alertData, setAlertData] = useState<{ message: string; type: 'success' | 'danger' | 'warning' | 'info' | undefined }>({ message: '', type: undefined });
    const [showAlert, setShowAlert] = useState(false);

    const { data: roles, isLoading: isLoadingRoles, error: rolesError } = useFetchRoles();
    const { data: users, isLoading: isLoadingUsers, error: usersError } = useFetchUsers();


    // Обработчик изменения формы
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    // Функция для отправки данных
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/info`, {
                user: { id: parseInt(formData.userId) },
                role: { id: parseInt(formData.roleId) },
                key: formData.key,
                value: formData.value
            });
            console.log(response.data);
            setAlertData({ message: 'Успешно создан!', type: 'success' });
            setShowAlert(true);

            // Скрыть уведомление через 2 секунды
            setTimeout(() => {
                setShowAlert(false);
                setAlertData({ message: '', type: undefined });
            }, 2000);
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
            setAlertData({ message: 'Ошибка при создании данных', type: 'danger' });
            setShowAlert(true);

            // Скрыть уведомление через 2 секунды
            setTimeout(() => {
                setShowAlert(false);
                setAlertData({ message: '', type: undefined });
            }, 2000);
        }
    };

    if (isLoadingRoles || isLoadingUsers) {
        return <div>Loading...</div>;
    }

    if (rolesError || usersError) {
        const errorMessage = rolesError?.message || usersError?.message || 'Произошла ошибка';
        return <div>Error: {errorMessage}</div>;
    }

    return (
        <Layout>
            <form onSubmit={handleSubmit}>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Название поля</th>
                            <th scope="col">Значение поля</th>
                            <th scope="col">Роль</th>
                            <th scope="col">Пользователь</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>
                                <div className="mb-3">
                                    <input className="form-control form-control-lg" type="text" name="key" value={formData.key} onChange={handleInputChange} />
                                </div>
                            </td>
                            <td>
                                <div className="mb-3">
                                    <input className="form-control form-control-lg" type="text" name="value" value={formData.value} onChange={handleInputChange} />
                                </div>
                            </td>
                            <td>
                                <div className="mb-3">
                                    <select className="form-select" name="roleId" value={formData.roleId} onChange={handleInputChange}>
                                        <option value="">Выберите роль</option>
                                        {roles?.map(role => (
                                            <option key={role.id} value={role.id}>{role.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="mb-3">
                                    <select className="form-select" name="userId" value={formData.userId} onChange={handleInputChange}>
                                        <option value="">Выберите пользователя</option>
                                        {users?.map(user => (
                                            <option key={user.id} value={user.id}>{user.email}</option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="mb-3">
                                    <button className="btn btn-success" type="submit">Обновить</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
            {/* <AlertMessage
                message={alertData.message}
                type={alertData.type || 'info'}
                showAlert={showAlert}
                onClose={() => {
                    setShowAlert(false);
                    setAlertData({ message: '', type: undefined });
                }}
            /> */}
        </Layout>
    );
};

export default CreateComponent;

