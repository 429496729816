import { useQuery } from "react-query";
import axios from "axios";
import {Filling} from "../../types/Filling";

const fetchFillings = async (): Promise<Filling[]> => {
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/fillings`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return data;
};

export const useFetchFillings = () => {
    return useQuery<Filling[], Error>("fillings", fetchFillings);
};
