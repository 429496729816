// hooks/categories/useCreateCategory.ts

import {useMutation} from 'react-query';
import axios from 'axios';
import {Category} from 'types/Category';

export const useCreateCategory = () => {
    return useMutation(async (formData: FormData) => {
        const token = localStorage.getItem('token');
        console.log("token: " + token);

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/categories`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.id; // Предполагается, что ID возвращается в поле data.id
    });
};