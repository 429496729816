import { useMutation } from 'react-query';
import axios from 'axios';
import { AttributeValue } from 'types/ProductTypes'; // Убедитесь, что путь к типам корректный

export const useCreateAttributeValues = () => {
  const token = localStorage.getItem('token'); // Предполагаем, что токен уже сохранен в localStorage

  return useMutation((attributeValues: AttributeValue[]) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/attribute-values`, attributeValues, {
      headers: {
        Authorization: `Bearer ${token}`, // Добавляем токен в заголовки запроса
      },
    });
  });
};
