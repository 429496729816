import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

const RoleSelection: React.FC = () => {
    const navigate = useNavigate(); // Получаем объект истории для навигации

    useEffect(() => {
        // Получение токена из URL
        const urlParams = new URLSearchParams(window.location.search);
        // Получение токена из URL параметров
        const urlToken = urlParams.get('token');
        const urlEmail = urlParams.get('email');

        if (urlEmail !== null) {
            localStorage.setItem('email', urlEmail);
        } else {
            // Обрабатывайте случай, когда email отсутствует в URL
            // Например, установите дефолтное значение или выведите сообщение об ошибке
            console.log('Email не найден в URL');
        }

        // Получение токена из localStorage
        const localStorageToken = localStorage.getItem('token');
        const localStorageEmail = localStorage.getItem('email');

        // Если токен существует в URL параметрах, используем его, 
        // иначе используем токен из localStorage, если он там есть
        const token = urlToken || localStorageToken;

        if (token) {
            // Сохранение токена в localStorage
            localStorage.setItem('token', token);
        } else {
            // Если токена нет, можно перенаправить пользователя обратно на страницу логина
            navigate('/login');
        }
    }, [navigate]);

    const [formData, setFormData] = useState({
        roleId: 1, // Default roleId
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // Add your form submission logic here
    };

    const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedRoleId = Number(e.target.value);
        setFormData({ roleId: selectedRoleId });

        // Определяем, куда перенаправить пользователя на основе выбранной роли
        if (selectedRoleId === 1) {
            navigate('/register/buyer?role=1');
        } else if (selectedRoleId === 2) {
            // navigate('/register/seller?role=2');
            navigate('/register/buyer?role=2');
        }
    };

    return (
        <main className="d-flex w-100">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="text-center mt-4">
                                <h1 className="h2">Присоединяйтесь к нам</h1>
                                <p className="lead">
                                    Как продавец или покупатель!
                                </p>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-3">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label">Роль</label>
                                                <select
                                                    className="form-select form-select-lg"
                                                    name="roleId"
                                                    // value={useFormData.roleId}
                                                    onChange={handleRoleChange}
                                                >
                                                    <option value="">Выберите роль</option>
                                                    <option value="1">Покупатель</option>
                                                    <option value="2">Продавец</option>
                                                </select>
                                            </div>
                                            <div className="text-center mb-3">
                                                У вас уже есть учетная запись? <Link to="/login">Авторизоваться</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default RoleSelection;
