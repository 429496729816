import React from "react";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {Filling} from "../../types/Filling";
import {useCreateFilling} from "../../hooks/fillings/useCreateFilling";
import {FormRow} from "../fragment/FormRow";
import Layout from "../Layout/Layout";
import {Link, useNavigate} from "react-router-dom";
import {useAlertContent} from "../../context/AlertContext";

const initialValues: Filling = {
    id: 0,
    name: "",
    imageUrl: "",
    description: "",
    fillingType: "",
};

const validationSchema = Yup.object({
    name: Yup.string().required("Обязательное поле"),
    description: Yup.string(),
});

const CreateFillingForm: React.FC = () => {
    const navigate = useNavigate();
    const {mutate: createFilling} = useCreateFilling();
    const {triggerAlert} = useAlertContent();

    const handleSubmit = (values: Filling, {setSubmitting}: any) => {
        const imageFile = (document.getElementById("imageFile") as HTMLInputElement).files?.[0];
        createFilling({ filling: values, imageFile }, {
            onSuccess: (data) => {
                triggerAlert('Начинка успешно обновлена!', 'success');
                navigate(`/update-filling/${data.id}`);
            }
        });
        setSubmitting(false);
    };

    return (
        <Layout>
            <h1>Добавить начинку</h1>
            <Link to="/all-filling">Все начинки</Link>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({isSubmitting}) => (
                    <Form>
                        <table className="table">
                            <tbody>
                            <tr>
                                <td className="col-4">Выберите тип</td>
                                <td className="col-8">
                                    <Field as="select" name="fillingType" className="form-control form-control-lg">
                                        <option value="Торты">Торты</option>
                                        <option value="Капкейки">Капкейки</option>
                                    </Field>
                                </td>
                            </tr>

                            <FormRow label="Название" type="text" name="name" placeholder="Название начинки"/>
                            <FormRow label="Описание" type="text" name="description" placeholder="Описание начинки"
                                     as="textarea"/>
                            <tr>
                                <td className="col-4">Изображение</td>
                                <td className="col-8">
                                    <input type="file" id="imageFile" name="imageFile"
                                           className="form-control form-control-lg"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Добавить</button>
                    </Form>
                )}
            </Formik>
        </Layout>
    );
};

export default CreateFillingForm;
