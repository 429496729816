import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import IMask from 'imask';

const SendSmsComponent = () => {
    const [phoneNumber, setPhoneNumber] = useState('+7 '); // Установка значения по умолчанию
    const [message, setMessage] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            const maskOptions = {
                mask: '+7 (000) 000-00-00'
            };
            const mask = IMask(inputRef.current, maskOptions);
            mask.on('accept', () => setPhoneNumber(mask.value));
            return () => mask.destroy();
        }
    }, []);
    

    const sendSms = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/send-sms`, { phoneNumber, message });
            console.log(response.data);
        } catch (error) {
            console.error('Ошибка при отправке SMS', error);
        }
    };

    return (
        <div>
            <input
                ref={inputRef}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Номер телефона"
            />
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Сообщение"></textarea>
            <button onClick={sendSms}>Отправить SMS</button>
        </div>
    );
};

export default SendSmsComponent;