import { useMutation } from 'react-query';
import axios from 'axios';

interface AttributeUpdateRequestType {
    attributeData: {
      attributeName: string;
      attributeType: string;
      sortOrder: number;
      isCommon: boolean;
      isActive: boolean;
      creationDate: string;
      changeDate: string;
    };
    attributeValues: Array<{
      value: string;
      attributeId: number;
    }>;
    categoryIds: number[];
  }
  

export const useUpdateAttribute = () => {
  return useMutation(async (data: AttributeUpdateRequestType) => {
    const token = localStorage.getItem("token");
    return axios.put(`${process.env.REACT_APP_API_URL}/attribute/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  });
};
