import { Field, ErrorMessage } from 'formik';

type FormRowProps = {
    label: string;
    type: string;
    name: string;
    placeholder: string;
    as?: string;
    className?: string;
};

export const FormRow: React.FC<FormRowProps> = ({ label, type, name, placeholder, as = "input", className = "form-control form-control-lg" }) => {
    return (
        <tr>
            <td className="col-4">{label}</td>
            <td className="col-8">
                <Field
                    className={className}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    as={as}
                />
                <ErrorMessage name={name} component="div" className="text-danger"/>
            </td>
        </tr>
    );
};
