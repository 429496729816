// В файле useCategories.ts

import { useQuery } from 'react-query';
import axios from 'axios';

const getCategoryById = async (categoryId: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/categories/${categoryId}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Добавьте токен аутентификации, если необходимо
        },
    });
    return data;
};

export const useCategory = (categoryId: string) => {
    return useQuery(['category', { categoryId }], () => getCategoryById(categoryId), {
        enabled: !!categoryId,
    });    
};
