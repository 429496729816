// Ваш импорт зависимостей
import { useMutation } from "react-query";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom"; // Для редиректа
import { UserField } from "types/UserFieldTypes";

const createUserField = async (userField: UserField): Promise<number> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/userfields`,
    userField,
    config
  );
  return response.data.id; // Возвращаем ID созданного пользователя
};

export const useCreateUserField = () => {
  const navigate = useNavigate(); // Хук для навигации

  return useMutation<number, AxiosError, UserField>(createUserField, {
    onSuccess: (userId) => {
      navigate(`/update-field-user/${userId}`);
    },
  });
};
