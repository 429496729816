import React, {useEffect, useState} from "react";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import {Filling} from "../../types/Filling";
import {useUpdateFilling} from "../../hooks/fillings/useUpdateFilling";
import {FormRow} from "../fragment/FormRow";
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import Layout from "../Layout/Layout";
import {Watch} from "react-loader-spinner";
import {useAlertContent} from "../../context/AlertContext";


const validationSchema = Yup.object({
    name: Yup.string().required("Обязательное поле"),
    description: Yup.string(),
});

const fetchFilling = async (id: number): Promise<Filling> => {
    const token = localStorage.getItem("token");
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/fillings/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return data;
};

const UpdateFillingForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const fillingId = Number(id);
    const navigate = useNavigate();
    const { data: filling, isLoading, error } = useQuery<Filling, Error>(["filling", fillingId], () => fetchFilling(fillingId));
    const { mutate: updateFilling } = useUpdateFilling();
    const [loadingUpdateFilling, setLoadingUpdateFilling] = useState<boolean>(false);
    const [mainImage, setMainImage] = useState<File | null>(null);
    const [mainImageUrl, setMainImageUrl] = useState<string | null>(null);
    const [removedMainImage, setRemovedMainImage] = useState<boolean>(false);

    const {triggerAlert} = useAlertContent();

    useEffect(() => {
        if (filling) {
            setMainImageUrl(filling.imageUrl || "");
        }
    }, [filling]);

    const handleMainImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setMainImage(file); // Сохраняем новый файл
            setMainImageUrl(URL.createObjectURL(file)); // Создаем и сохраняем URL для превью
            setRemovedMainImage(false); // Отменяем удаление изображения
        }
    };

    const handleDeleteImage = () => {
        console.log("handleDeleteImage")
        console.log(mainImageUrl)
        if (mainImageUrl) {
            console.log(handleDeleteImage)
            console.log(mainImageUrl)
            setRemovedMainImage(true);
            setMainImageUrl(null);
            setMainImage(null);
        }
    };

    const renderMainImage = () => {
        if (!mainImageUrl) return null; // Если URL изображения не установлен, не отображаем его

        return (
            <div className="image-preview">
                <img src={mainImageUrl} alt="Main Product Image" style={{ width: '100px', marginRight: '10px' }} />
                <button onClick={handleDeleteImage} style={{ marginLeft: '10px' }}>Удалить</button>
            </div>
        );
    };

    const handleSubmit = (values: Filling, { setSubmitting }: any) => {
        setLoadingUpdateFilling(true);
        const updatedValues = { ...values };
        if (removedMainImage) {
            updatedValues.removedImageUrl = values.imageUrl; // Устанавливаем удаленный URL изображения
            updatedValues.imageUrl = undefined; // Удаляем URL изображения, если оно помечено на удаление
        }
        console.log(mainImageUrl)
        console.log(updatedValues)
        updateFilling({ id: fillingId, filling: updatedValues, imageFile: mainImage || undefined }, {
            onSuccess: () => {
                setLoadingUpdateFilling(false); // Устанавливаем состояние загрузки в false
                triggerAlert('Начинка успешно обновлена!', 'success');
                navigate(`/update-filling/${fillingId}`);
            },
            onError: () => {
                setLoadingUpdateFilling(false); // Устанавливаем состояние загрузки в false
            }
        });
        setSubmitting(false);
    };

    if (isLoading) {
        return (
            <div className="loader-spinner">
                <Watch
                    visible={true}
                    height="80"
                    width="80"
                    radius="48"
                    color="#4fa94d"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <Layout>
            <Link to="/all-filling">Все начинки</Link>
            {/*<Formik initialValues={filling || { id: 0, name: "", imageUrl: "", description: "" }}*/}
            {/*        validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>*/}
            {/*    {({ isSubmitting }) => (*/}
            {/*        <Form>*/}
            {/*            <table className="table">*/}
            {/*                <tbody>*/}
            {/*                <tr>*/}
            {/*                    <td className="col-4">Выберите тип</td>*/}
            {/*                    <td className="col-8">*/}
            {/*                        <Field*/}
            {/*                            as="select"*/}
            {/*                            name="fillingType"*/}
            {/*                            className="form-control form-control-lg"*/}
            {/*                            value={filling?.fillingType || ''}*/}
            {/*                        >*/}
            {/*                            <option value="Торты">Торты</option>*/}
            {/*                            <option value="Капкейки">Капкейки</option>*/}
            {/*                        </Field>*/}
            {/*                    </td>*/}
            {/*                </tr>*/}
            {/*                <FormRow label="Название" type="text" name="name" placeholder="Название начинки" />*/}
            {/*                <FormRow label="Описание" type="text" name="description" placeholder="Описание начинки" as="textarea" />*/}
            {/*                <tr>*/}
            {/*                    <td className="col-4">Основное изображение</td>*/}
            {/*                    <td className="col-8">*/}
            {/*                        {renderMainImage()}*/}
            {/*                        <input*/}
            {/*                            className="form-control form-control-lg"*/}
            {/*                            type="file"*/}
            {/*                            name="mainImage"*/}
            {/*                            onChange={handleMainImageChange}*/}
            {/*                        />*/}
            {/*                        {mainImage && (*/}
            {/*                            <div style={{ marginTop: '10px' }}>*/}
            {/*                                <strong>Выбранное изображение:</strong> {mainImage.name}*/}
            {/*                            </div>*/}
            {/*                        )}*/}
            {/*                    </td>*/}
            {/*                </tr>*/}
            {/*                </tbody>*/}
            {/*            </table>*/}
            {/*            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Обновить</button>*/}
            {/*        </Form>*/}
            {/*    )}*/}
            {/*</Formik>*/}

            <Formik
                initialValues={filling || { id: 0, name: "", imageUrl: "", description: "", fillingType: "Торты" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <table className="table">
                            <tbody>
                            <tr>
                                <td className="col-4">Выберите тип</td>
                                <td className="col-8">
                                    <Field
                                        as="select"
                                        name="fillingType"
                                        className="form-control form-control-lg"
                                        value={values.fillingType}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setFieldValue('fillingType', e.target.value)} // Управление через Formik
                                    >
                                        <option value="Торты">Торты</option>
                                        <option value="Капкейки">Капкейки</option>
                                    </Field>
                                </td>
                            </tr>
                            <FormRow label="Название" type="text" name="name" placeholder="Название начинки" />
                            <FormRow label="Описание" type="text" name="description" placeholder="Описание начинки" as="textarea" />
                            <tr>
                                <td className="col-4">Основное изображение</td>
                                <td className="col-8">
                                    {renderMainImage()}
                                    <input
                                        className="form-control form-control-lg"
                                        type="file"
                                        name="mainImage"
                                        onChange={handleMainImageChange}
                                    />
                                    {mainImage && (
                                        <div style={{ marginTop: '10px' }}>
                                            <strong>Выбранное изображение:</strong> {mainImage.name}
                                        </div>
                                    )}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Обновить</button>
                    </Form>
                )}
            </Formik>


            {(loadingUpdateFilling) && (
                <div className="loader-spinner">
                    <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
        </Layout>
    );
};

export default UpdateFillingForm;